import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonProps } from "@mui/material";
import { Stack } from "@mui/system";
import dayjs from "dayjs";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUserInfo } from "Redux/slices/authSlice";

import {
  CostumDateField,
  CostumTextField,
  CostumNumberEditField,
} from "../Shared/CostumInputs";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import PhoneChangeModal from "./PhoneChangeModal";
import FileUpload from "Components/Generals/FileUpload/FileUpload";
import { useEditUserAsync } from "./hooks";
import CostumDateInput from "Components/Generals/costumInputs/CostumDateInput";
type UserEditProps = {};

interface IUser {
  dateOfBirth?: string;
  firstName: string;
  id: string;
  avatar: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
}
const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: "#CCC",
  color: "#000000de",
  fontWeight: 400,
  lineHeight: 1.4375,
  boxSizing: "border-box",
  alignItems: "left",
  fontSize: "1rem",
  padding: "16.5px 14px",
  background: "none",
  "&:hover": {
    borderColor: "#000000de",
  },
}));

const editSchema = yup
  .object({
    firstName: yup.string().required("მიუთითეთ სახელი"),
    lastName: yup.string().required("მიუთითეთ გვარი"),
  })
  .required();

const UserEdit: React.FC<UserEditProps> = () => {
  const [loading, editUserAsync] = useEditUserAsync();
  const [open, setIsOpen] = useState(false);
  const axiosInstance = useAxiosPrivateInstance();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const dispatch = useDispatch();


  const [offerImage, setOfferImage] = useState<File>();

  const onSubmit = async (data: IUser) => {
    editUserAsync(data)
    console.log(data.dateOfBirth)
  };

  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    if (hasRendered) {
      const { firstName, lastName, dateOfBirth, email, avatarUrl } = userInfo;
      reset({ firstName, lastName, dateOfBirth, email, avatar: avatarUrl });
    } else {
      setHasRendered(true);
    }

  }, [userInfo])

  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm<IUser>({
    resolver: yupResolver(editSchema),
    defaultValues: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      dateOfBirth: userInfo.dateOfBirth,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      avatar: userInfo.avatarUrl
    },
  });
  const numberChangeHandler = () => {
    setIsOpen((state) => !state);
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  console.log(JSON.stringify(watch('avatar')) !== JSON.stringify(userInfo.avatarUrl))
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{
            maxWidth: "500px",
            margin: "40px auto",
            flexDirection: "column",
          }}
          spacing={4}
        >
          <FileUpload name={"avatar"} control={control} accept="image" setFormValue={setValue} handleFile={setOfferImage} clearErrors={clearErrors} />
          <CostumTextField
            name="firstName"
            control={control}
            label={"სახელი"}
            InputLabelProps={{
              shrink: watch("firstName") ? true : false,
            }}
          />
          <CostumTextField
            name="lastName"
            control={control}
            label={"გვარი"}
            InputLabelProps={{
              shrink: watch("lastName") ? true : false,
            }}
          />
          <CostumTextField
            name="email"
            control={control}
            label={"ელ-ფოსტა"}
            InputLabelProps={{
              shrink: watch("firstName") ? true : false,
            }}
          />

          <CostumNumberEditField
            name="phoneNumber"
            control={control}
            label={"მობილურის ნომერი"}
            content={userInfo.phoneNumber}
            handleEdit={numberChangeHandler}
          ></CostumNumberEditField>

          <CostumDateInput
            name="dateOfBirth"
            control={control}
            label={"დაბადების თარიღი"}
            errors={errors}
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={Boolean(!isDirty || loading) && JSON.stringify(watch('avatar')) === JSON.stringify(userInfo.avatarUrl)}
          >
            ცვლილებების შენახვა
          </Button>
        </Stack>
        {<PhoneChangeModal open={open} onHandleClose={closeHandler} />}
      </form>
    </div>
  );
};
export default UserEdit;
