import styled from "@emotion/styled";
import { device } from "Components/common/device";
import { Button } from "@mui/material";

export const Container = styled("div")`
  max-width: 1440px;
  margin: auto;
`;

export const HeroLayout = styled("div")`
  /* background-color:blueviolet; */
  box-sizing: border-box;
  padding: 50px 70px;
  display: flex;
  gap: 50px 32px;
  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @media ${device.laptop} {
    padding: 32px;
  }
`;

export const HeroLeft = styled("div")`
  max-width: 620px;
  width: 100%;
  margin-top: 20px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 40px;
    color: #193a8d;
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    margin-bottom: 46px;
    color: #869ab8;
  }
  @media (max-width: 1300px) {
    max-width: 710px;
  }
  @media ${device.laptop} {
    h1 {
      font-size: 56px;
      line-height: 68px;
    }
  }
  @media ${device.mobile} {
    h1 {
      font-size: 32px;
      line-height: 48px;
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      margin-bottom: 46px;
      color: #869ab8;
    }
  }
`;

export const DownloadBtns = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  margin-bottom: 40px;
  max-width:532px;
  button {
    height: 62px;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    flex: 1;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: white;
    background: #193a8d;
    border-radius: 10px;
    padding: 18.947px 30.3152px;
    white-space: nowrap;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    &:nth-of-type(2){
      svg{
        font-size:18px;
      }
    }

  }
  @media (max-width: 1300px) {
    max-width: 523px;
    /* margin: auto; */
  }
`;

export const HeroRight = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 100%;
    max-width: 537px;
    margin: 0 auto;
  }

  @media (max-width: 1300px) {
    justify-content: center;
  }

  @media ${device.laptop} {
    height: 400px;
    justify-content: center;

    & > div {
      overflow: unset;
      transform: scale(0.7);
    }
  }
  @media ${device.mobile} {
    overflow: hidden;
    height: 300px;
    justify-content: center;
    & > div {
      overflow: unset;
      transform: scale(0.53);
    }
  }
`;
export const SubmitFormMobileBtn = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 29px;
  gap: 16px;
  margin: auto;
  font-family: inherit;
  box-shadow: none;
  svg {
    font-size: 20px;
  }
  @media ${device.mobile} {
    font-size: 16px !important;
    line-height: 20px;
    padding: 18px 24px;
  }
`;

export const DialogTitle = styled("div")`
  font-style: normal;
  color: #0070f4;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.15px;
  line-height: 150%;
  padding: 0px 24px 10px 24px;
  margin-top: 58px;
`;

export const DialogContentText = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #0070f4;
  width: 80%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SubscribtionForm = styled("div")`
  button {
    border-radius: 10px;
    padding: 16px 40px;
    height: 56px;
    font-style: normal;
    box-sizing: border-box;
    outline: none;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.16);
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    font-family: inherit;
    white-space: nowrap;
    width: 100%;
    &:hover {
      color: white;
    }
  }
`;
