import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "Redux/store";

const LoggedOutRoute: FC<{ component: FC }> = (props) => {

    const userInfo = useSelector((state: RootState) => state.auth.userInfo)
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

    if (isLoggedIn == true && userInfo.id.length > 0) {
        console.log("redirect /", isLoggedIn);
        return <Navigate to="/" />
    }

    return <props.component />;
}

export default LoggedOutRoute;