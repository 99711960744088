import { FC, useEffect, useRef, useState } from "react";
import * as Models from "Models";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type BuyPackageResponse = {
    paymentRedirectUrl: string,
}

const Payment: FC<{ newlyOrAlreadyAddedCarId: string | undefined, selectedPackageId: string, isYearlyPaySelected: boolean }> = ({ newlyOrAlreadyAddedCarId, selectedPackageId, isYearlyPaySelected }) => {
    const axiosPrivateInstance = useAxiosPrivateInstance();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [iframeSrcUrl, setIframeSrcUrl] = useState<string>();
    const [saveCard, setSaveCard] = useState<boolean>(true);

    const handleSaveCardChange = () => {
        setSaveCard(!saveCard)
    }

    const BuyPackage = async (carId: string, packageId: string, saveCard: boolean) => {
        axiosPrivateInstance.post<BuyPackageResponse>("Payment/CreatePaymentUrlAsync", {
            packageId,
            carId,
            saveCard,
        })
            .then((response) => {
                console.log(response.data);
                setIframeSrcUrl(response.data.toString());
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error("დაფიქსირდა პრობლემა გადახდის გვერდის გენერირებისას")
                setIframeSrcUrl('');
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true);
        if (newlyOrAlreadyAddedCarId && selectedPackageId) {
            BuyPackage(newlyOrAlreadyAddedCarId, selectedPackageId, saveCard);
        }
        return;
    }, [])

    useEffect(() => {
        setIsLoading(true);
        if (newlyOrAlreadyAddedCarId && selectedPackageId) {
            BuyPackage(newlyOrAlreadyAddedCarId, selectedPackageId, saveCard);
        }
        return;
    }, [saveCard])

    useEffect(() => {
        if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
            const iframeUrl = iframeRef.current.contentWindow.location.href;
            console.log(iframeUrl);
        }
    })

    return (
        <>{newlyOrAlreadyAddedCarId && selectedPackageId ?
            <>
                <Stack sx={{ width: '100%', margin: "20px 0px" }} spacing={2}>
                    <Alert severity="info">
                        {isYearlyPaySelected ? <>
                            <AlertTitle>გსურთ ბარათის დამახსოვრება?</AlertTitle>
                            შეინახეთ ბარათი ჩვენ სისტემაში და გამოიყენეთ იგი სხვადასხვა სერვისებისთვის.<br />
                            ბართის წაშლა შესაძლებელია ნებისმიერ მომენტში.
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={saveCard} onChange={handleSaveCardChange} />} label="ბარათის დამახსოვრება" />
                            </FormGroup>
                        </>
                            :
                            <>
                                <AlertTitle>გაითვალისწინეთ</AlertTitle>
                                თქვენ არჩეული გაქვთ თანხის თვიურად გადახდა.<br />
                                თვიურად გადახდის შემთხვევაში თქვენი ბარათი შეინახება ჩვენ სისტემაში.<br />
                                ბართის წაშლა შესაძლებელია ნებისმიერ მომენტში, მაგრამ მანქანაზე მიბმული პაკეტები გაუქმდება.
                                <FormGroup>
                                    <FormControlLabel disabled control={<Checkbox defaultChecked={saveCard} />} label="ბარათის დამახსოვრება" />
                                </FormGroup>
                            </>
                        }
                    </Alert>
                </Stack>
                {isLoading ?
                    <Box sx={{ width: '100%', height: "720px", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                    :
                    (iframeSrcUrl ?
                        <iframe ref={iframeRef} title="payment" style={{ width: "100%", height: "720px" }} src={iframeSrcUrl}>
                            <p>Your browser does not support iframes.</p>
                        </iframe>
                        :
                        <Stack sx={{ width: '100%', height: "720px", justifyContent: "center" }} spacing={2}>
                            <Alert severity="warning">
                                <AlertTitle>შეტყობინება</AlertTitle>
                                დაფიქსირდა პრობლემა გადახდის გვერდის გენერირებისას
                            </Alert>
                        </Stack>
                    )
                }
            </>
            :
            <Stack sx={{ width: '100%', height: "720px", justifyContent: "center" }} spacing={2}>
                <Alert severity="info">
                    <AlertTitle>შეტყობინება</AlertTitle>
                    გადახდამდე საჭიროა <b style={{ fontWeight: "bold" }}>ავტომობილის დამატება</b> და <b style={{ fontWeight: "bold" }}>პაკეტის არჩევა</b>
                </Alert>
            </Stack>
        }
        </>
    );
}

export default Payment;