import styled from "@emotion/styled";
import { device } from "Components/common/device";

import Background from "Assets/images/Background.svg";

export const Container = styled("div")`
  border-radius: 0px 0px 40px 40px;
  background-size: cover;
  position: relative;
`;
export const Box = styled("div")`
  border-radius: 0px 0px 0px 100px;
  width: 500px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
  background-color: #eaf0fc;
  @media (max-width: 1300px) {
    display:none;
  }
`;



