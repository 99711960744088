import { Container } from "./styles";
import { FC, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";

export const MobileVerification: FC = () => {
  const [reqError, setReqError] = useState(true);
  const { handleSubmit, reset, control } = useForm();
  const onSubmit = (data: any) => console.log(data);
  const theme = useTheme();
  return (
    <Container>
      <h2>ნომრის ვერიფიკაცია</h2>

      <form>
        <Typography sx={{ fontSize: "14px", marginBottom: "35px" }}>
          შენს მიერ მითითებულ ნომერზე გამოგიგზავნეთ ერთჯერადი კოდი
        </Typography>
        <Controller
          name={"textValue"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              variant="standard"
              onChange={onChange}
              value={value}
              label={"შეიყვანე კოდი"}
              type="number"
              sx={{
                fontSize:'20px',
                lineHeight:'24px',
                fontWight:400
              }}
            />
          )}
        />
        {reqError && (
          <Typography
            sx={{
              color: "#F4724A",
              fontWeight: 350,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              gap: "27px",
              marginTop: "40px",
            }}
          >
            <ErrorIcon /> კოდი არ არის სწორი თავიდან სცადე
          </Typography>
        )}
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disableElevation={true}
          sx={{
            borderRadius: theme.button.borderRadius,
            padding: "16px",
            marginTop: "40px",
          }}
        >
          შესვლა
        </Button>
      </form>
    </Container>
  );
};
