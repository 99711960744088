import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@mui/material";
import { FC } from "react";

interface Option {
  label: string;
  value: any;
}

interface Props {
  name: string;
  control: any;
  label: string;
  options: Option[];
  defaultSelected?: any;
}

export const CostumSelectInput:FC<Props> = ({ name, control, label, options, defaultSelected }) => {

  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      control={control}
      name={name}
        defaultValue={defaultSelected}
      render={({ field: { onChange, value } }) => (
        <Select onChange={onChange} value={value}>
          {generateSelectOptions()}
        </Select>
      )}
    />
  );
};

export default CostumSelectInput;