import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Redux/store";
import { logOut } from 'Redux/slices/authSlice';


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Stack } from '@mui/material';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Settings from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import Logout from '@mui/icons-material/Logout';

import silverCoin from "Assets/images/silver_coin.png";
import goldCoin from "Assets/images/gold_coin.png";

function AccountMenu() {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function notificationsLabel(count: number) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: "space-between" }}>
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: "space-between", gap: "10px" }}>
                    <IconButton aria-label={notificationsLabel(0)}>
                        <Badge badgeContent={0} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <span className='notification'>შეტყობინება</span>
                </div>
                <Tooltip title="ანგარიშის პარამეტრები">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 36, height: 36 }} {...userInfo.avatarUrl ? { src: userInfo.avatarUrl } : null} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate("/settings")}>
                    <Avatar {...userInfo.avatarUrl ? { src: userInfo.avatarUrl } : null} /> ჩემი ანგარიში
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/payment-methods")}>
                    <ListItemIcon>
                        <CreditCardIcon fontSize="small" />
                    </ListItemIcon>
                    გადახდის მეთოდები
                </MenuItem>
                <MenuItem onClick={() => navigate("/help-center")}>
                    <ListItemIcon>
                        <HelpIcon fontSize="small" />
                    </ListItemIcon>
                    დახმარება
                </MenuItem>
                <MenuItem onClick={() => dispatch(logOut())}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    გამოსვლა
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

function RightSidebar() {
    const navigate = useNavigate();

    return (
        <Sidebar>
            <AccountMenu />
            <div className='loyal_club_title'>
                <div>ლოიალური კლუბი</div>
                <MoreHorizIcon />
            </div>
            <h1>
                შეთავაზებები და პაკეტები არ მთავრდება
            </h1>
            <button className="read_more" onClick={() => navigate("/loyality")}>
                <div>გაიგე მეტი ლოიალურ პარტნიორებზე და ქულების დაგროვებაზე.</div>
                <ArrowForwardIosIcon />
            </button>
            <div className='coins'>
                <span>მონეტები</span>
                <Stack direction={"row"} spacing={2}>
                    <div>
                        <img src={silverCoin} alt="Silver Coin" />
                        <div>ვერცხლის<br />მონეტა</div>
                    </div>
                    <div>
                        <img src={goldCoin} alt="Silver Coin" />
                        <div>ოქროს<br />მონეტა</div>
                    </div>
                </Stack>
            </div>
        </Sidebar>
    )
}

export default RightSidebar

const Sidebar = styled.div`
    width: 100%;
    max-width: 260px;
    padding-top: 44px;
    padding-left: 20px;

    .notification {
        color: #191919;
        font-size: 14px;
        font-family: "FreeSansBold";
    }

    .loyal_club_title {
        margin-top: 50px;
        display: flex;
        align-items: center;
        color: #0070f4;
        font-size: 14px;
        font-family: "FreeSansBold";

        div {
            padding-bottom: 4px;
            padding-right: 8px;
        }
    }

    h1 {
        padding: 20px 0px 30px;
        color: #191919;
        font-size: 38px;
        font-family: "FreeSansBold";
        font-weight: bold;
        line-height: 1.2;
    }

    .read_more {
        width: 95%;
        display: flex;
        align-items: center;
        color: #191919;
        font-size: 14px;
        font-family: "FreeSansBold";

        div {
            text-align: left;
        }
    }

    .coins {
        margin-top: 50px;
        color: #4c4f54;
        font-size: 14px;
        font-family: "FreeSansBold";

        > div {
            text-align: center;
            line-height: 1.2;
        }

        span {
            display: block;
            padding-bottom: 20px;
        }

        img {
            height: 80px;
        }
    }
    @media(max-width:1320px){
        display:none;
    }
`