import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import * as Models from "Models"
import { motion } from "framer-motion";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { getExpenseCategories } from 'Redux/slices/carDetailsSlice';

import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import ExpenseCategoryItem from "Components/GarageComponents/ExpenseCategoryItem";
import ScrollContainer from 'react-indiana-drag-scroll'
import styled from "@emotion/styled";

import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import { BigBlockStyled } from "../Dashboard/styles";


import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ExpenseItemMobile from 'Components/GarageComponents/ExpenseItemMobile';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ExpensesContainer: FC = () => {
    const navigate = useNavigate();
    const { carId } = useParams();
    const dispatch = useDispatch<any>();
    const expenseCategories = useSelector((state: RootState) => state.car.expenseCategories);
    const [filteredCategoryNames, handlefilteredCategoryNames] = useState<string[]>([]);
    const [expensesMobileSelect, setExpensesMobileSelect] = useState(0);
    const handleExpenseMobileChange = (event: SelectChangeEvent) => {
        setExpensesMobileSelect(event.target.value as unknown as number);
    };

    useEffect(() => {
        dispatch(getExpenseCategories());
    }, [dispatch])

    return (
        <div>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom:'25px', '@media(max-width:600px)':{flexDirection:'column', alignItems:'flex-start', gap:'20px'}}}>
                <ExpensesTitle>ხარჯების კონტროლი</ExpensesTitle>
                <Button variant="contained" size="large" onClick={() => navigate('add-expense')}>ხარჯთაღრიცხვის დამატება</Button>
            </Stack>
            <ExpensesMobileContainer>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">აირჩიეთ ხარჯის კატეგორია</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={expensesMobileSelect as unknown as string}
                        label="აირჩიეთ ხარჯის კატეგორია"
                        onChange={handleExpenseMobileChange}
                    >
                        {expenseCategories && expenseCategories.map((expenseCategory) => {
                            return (
                                <MenuItem value={expenseCategory.id}>{expenseCategory.title}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                {expensesMobileSelect ?
                    <ExpenseItemMobile
                        categoryId={expensesMobileSelect}
                    />
                    :
                    <Typography sx={{margin:'10px 0'}}>გთხოვთ, აირჩიოთ ხარჯის კატეგორია</Typography>
                }
            </ExpensesMobileContainer>
            <ExpensesDesktopContainer>
                <MultipleSelectChip expenseCategories={expenseCategories} handlefilteredCategoryNames={handlefilteredCategoryNames} />
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                >
                    <div className='expensesDesktopContainer'>
                        <ScrollContainer
                            className="scroll-container"
                            hideScrollbars={false}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {expenseCategories &&
                                    filteredCategoryNames && filteredCategoryNames.length > 0 ?
                                    expenseCategories.filter(category => filteredCategoryNames.includes(category.title)).map((item, index, filteredArray) => {
                                        return (
                                            <ExpenseCategoryItem
                                                key={index}
                                                expenceCategory={item}
                                                reverse={(index + 1) % 2 ? true : false}
                                                color={item.color}
                                                nextColor={filteredArray[index + 1] ? filteredArray[index + 1].color : item.color}
                                                firstChild={index == 0}
                                                lastChild={filteredArray.length == index + 1 ? true : false}
                                            />
                                        )
                                    })
                                    :
                                    expenseCategories.map((item, index, currentArray) => {
                                        return (
                                            <ExpenseCategoryItem
                                                key={index}
                                                expenceCategory={item}
                                                reverse={(index + 1) % 2 ? true : false}
                                                color={item.color}
                                                nextColor={currentArray[index + 1] ? currentArray[index + 1].color : item.color}
                                                firstChild={index == 0}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </ScrollContainer>
                    </div>
                </motion.div>
            </ExpensesDesktopContainer>
        </div>
    )
}

const ExpensesTitle = styled.div`
    font-family: "FreeSansBold";
    font-size: 24px;
    color: #191919;
    font-weight: bold;
    line-height: 1.1;
`;

const ExpensesMobileContainer = styled.div`
    display: none;
    @media (max-width: 800px){
        display: block;
    }
`;

const ExpensesDesktopContainer = styled.div`
    @media (max-width: 800px){
        display: none;
    }

    .expensesDesktopContainer {
        width: 100%;
        overflow: hidden;
        cursor: grab;

        @media (max-width: 1200px){
            width: calc(100vw - 140px);
        }
    }
`;

function getStyles(name: string, filteredCategoryNames: readonly string[], theme: Theme) {
    return {
        fontWeight:
            filteredCategoryNames.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelectChip: FC<{ expenseCategories: Models.ExpenseCategoriesResponse[], handlefilteredCategoryNames: (array: string[]) => void }> = ({ expenseCategories, handlefilteredCategoryNames }) => {
    const theme = useTheme();
    const [filteredCategoryNames, setfilteredCategoryNames] = React.useState<string[]>([]);
    const handleChange = (event: SelectChangeEvent<typeof filteredCategoryNames>) => {
        const {
            target: { value },
        } = event;
        setfilteredCategoryNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        handlefilteredCategoryNames(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: "99%" }}>
                <InputLabel id="demo-multiple-chip-label">გაფილტრეთ გამოსაჩენი ხარჯების კატეგორიები</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={filteredCategoryNames}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="გაფილტრეთ გამოსაჩენი ხარჯების კატეგორიები" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {expenseCategories.map((caregory) => (
                        <MenuItem
                            key={caregory.id}
                            value={caregory.title}
                            style={getStyles(caregory.title, filteredCategoryNames, theme)}
                        >
                            {caregory.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default ExpensesContainer;