import dayjs, { Dayjs } from "dayjs";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editUserInfo } from "Redux/slices/authSlice";


interface IEditUserAsyncData {
    dateOfBirth?: string;
    firstName: string;
    id: string;
    avatar?:string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
}

export const useEditUserAsync = ():[boolean, typeof editUserAsync] => {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const axiosPrivateInstance = useAxiosPrivateInstance();

    const editUserAsync = async (data:IEditUserAsyncData) => {
        data.dateOfBirth = dayjs(data.dateOfBirth).toISOString();
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.set(key, value);
        }

        await axiosPrivateInstance
      .post("Account/EditUserProfileAsync", formData)
      .then((res) => {
        dispatch(editUserInfo(data));
        toast.success("ოპერაცია წარმატებულია");
      })
      .catch((err) => console.log("დაფიქსირდა შეცდომა"))
      .finally(() => setLoading(false));
    }

    return [loading, editUserAsync]
}

