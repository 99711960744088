import { FC } from "react";
import Header from 'Components/Header/Header';
import Footer from 'Components/Footer/Footer';
import styled from '@emotion/styled';
import { FirstSection } from "Components/AboutUsComponents";


export const AboutUs: FC = () => {
    return (
        <>
            <Header />
            <Container>
                <FirstSection/>
            </Container>
            <Footer />
        </>
    )
}

export default AboutUs;


const Container = styled.div`

max-width:1440px;
margin:auto;
padding:77px 80px;
box-sizing:border-box;


@media(max-width:1200px){  
    padding:32px;
}
`