import { Stack } from '@mui/material';
import React from 'react'
import { AllPostsLayout, SinglePostStyled,HeadingStyled } from './styles';
import { Link, useNavigate } from 'react-router-dom';

const AllPosts:React.FC = () => {
  return (
    <AllPostsLayout>
      <HeadingStyled>
      <h3>All posts</h3>
      <Link to="#">view all</Link>
      </HeadingStyled>
     
      <Stack direction="column" >
    {[1,2,3,4].map(post=><SinglePost key={post}/>)}
      </Stack>
      </AllPostsLayout>
  )
}

export default AllPosts;


const SinglePost:React.FC = () => {
    
  const navigate = useNavigate()
  
    return <SinglePostStyled onClick={()=>navigate("123")}>
        <div className="post__author">
            <span>by <span className="highlited">John Doe </span>| May 23, 2022 </span>
          </div>
          <h5>8 Figma design systems that you can download for free today.</h5>
      </SinglePostStyled>
}