import React from 'react'
import {ContainerStyled, AuthStyled, PostHeadingStyled, PostImageStyled, PostBlockStyled, PostBlocksStyled} from './styles';
import avatarImage from 'Assets/images/Image.svg';
import PostPhoto from 'Assets/images/photo-of-woman-wearing-eyeglasses-3184405.svg';
import { Avatar } from '@mui/material';
import { Stack } from '@mui/system';

const Post = () => {
  return (
    <ContainerStyled>
        <AuthStyled>
        <Avatar alt="Cindy Baker" src={avatarImage} sx={{width:48, height:48}}/>
        <Stack direction="column">
            <h6>Andrew jonson</h6>
            <span>post on 27th january 2022</span>
        </Stack>
        </AuthStyled>
        <PostHeadingStyled>
            <h2>
            Step-by-step guide to choosing great font pairs
            </h2>
            <span>5 min read</span>
            </PostHeadingStyled>
        <PostImageStyled>
            <img src={PostPhoto} alt=""/>
        </PostImageStyled>
        <PostBlocksStyled>
        <PostBlockStyled>
            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</p>
        </PostBlockStyled>

        <PostBlockStyled>
            <h5>Lorem ipsut, sed do eiusmod.</h5>
            <p>Lorem ipsum dolor sit am dolor sit amet, consectetur adipiscing elim dolor sit amet, consectetur adipiscing elimet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</p>
        </PostBlockStyled>

        <PostBlockStyled>
            <h5>Lorem ipsum dolor r sit ame r sit ame sit amet, consectetur adipiscing elit, sed do eiusmod.</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at in tellus. Sociis natoque penatibus et magnis dis parturient montes. Ridiculus mus mauris vitae ultricies leo. Neque egestas congue quisque egestas diam. Risus in hendrerit gravida rutrum quisque non.</p>
        </PostBlockStyled>
        </PostBlocksStyled>
    </ContainerStyled>
  )
}

export default Post