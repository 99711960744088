import React from "react";
import { Box, Chip, Paper } from "@mui/material";
import styled from "@emotion/styled";
import { OfferPhoto } from "Assets/images";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslate } from "Hooks/useTranslate";

type DiscountChipProps = {
  persent: number;
  color?: string;
};

const DiscountChip: React.FC<DiscountChipProps> = ({ persent, color }) => {
  const { translate } = useTranslate();

  return (
    <DiscountChipStyled>
      <div className="title">{translate("ფასდაკლება")}</div>
      <div className="discountInfo">
        <AutoAwesomeIcon sx={{ color: "#E5C955" }} />
        <span>
          {persent == 100 || persent == 0
            ? `${translate("უფასო")}`
            : `${persent} %`}
        </span>
      </div>
    </DiscountChipStyled>
  );
};
export default DiscountChip;

const DiscountChipStyled = styled.div`
  .title {
    font-weight: bold;
    color: #808080;
    font-size: 12px;
    text-align: center;
  }
  .discountInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 6px;

    span {
      font-weight: bold;
      font-size: 16px;
      margin-left: 4px;
      color: #b6952e;
    }
  }
`;
