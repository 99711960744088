import Footer from 'Components/Footer/Footer'
import Header from 'Components/Header/Header'
import React from 'react'


interface IProps {
    component: JSX.Element
  }

export const MaintContainer:React.FC<IProps> = ({component}) => {
  return (<>
  <Header/>
  {component}
  <Footer/>
  </>

  )
}

export default MaintContainer