import { Box } from '@mui/system';
import { Background, Container, Sidebar } from 'Components/UserPages'
import React, { FC } from 'react'
import styled from "@emotion/styled";
import ExpensesContainer from "Pages/UserPages/Garage/ExpensesContainer";


interface IProps {
  component: JSX.Element;
}

export const CarContainer: FC<IProps> = ({ component }) => {
  return (
    <Background>
      <Wrapper>
        <div className='row'>
          <Sidebar hidePackageUpgrade={true} collapseAdditionalMenu={true} />
          <Container>
            {component}
          </Container>
        </div>
        <Container>
          <ExpensesContainer />
        </Container>
      </Wrapper>
    </Background>
  )
}

export default CarContainer;

const Wrapper = styled.div`
    max-width: 1600px;
    background-color: rgb(255, 255, 255, 50%);
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    margin: 0px auto;
    box-sizing:border-box;
    border-radius: 42px;

    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
    }
`