import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import useAxiosPrivateInstance from 'Hooks/useAxiosPrivateInstance';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setUserInfo } from 'Redux/slices/authSlice';
import { useAppSelector } from 'Redux/store';
import * as yup from "yup";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



type ChangePasswordProps = {

};

interface IChangePsw {
  currentPassword: string;
  newPassword: string;
  confirmPsw: string;
}


const editSchema = yup
  .object({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required('მიუთითეთ პაროლი'),
    confirmPsw: yup.string().required().oneOf([yup.ref('newPassword')], 'პაროლი არ ემთხვევა'),

  })
  .required();


const ChangePassword: React.FC<ChangePasswordProps> = () => {

  const [loading, setLoading] = useState(false)
  const axiosInstance = useAxiosPrivateInstance();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const dispatch = useDispatch();




  const onSubmit = (data: IChangePsw) => {
    const { currentPassword, newPassword } = data

    const changePswAsync = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.post('Account/ChangePasswordAsync', { currentPassword, newPassword })
        if (response.data.succeeded) {
          dispatch(setUserInfo(data));
          toast.success('ოპერაცია წარმატებულია')
          reset({ currentPassword: "", newPassword: "", confirmPsw: "" })
        } else {
          toast.error(response.data.errors[0].description)
        }


      } catch (error) {
        toast.error(JSON.stringify(error))
      } finally {
        setLoading(false)
      }
    }

    changePswAsync()

  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IChangePsw>({
    resolver: yupResolver(editSchema),

  });


  return <div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ maxWidth: '300px', margin: '40px auto', flexDirection: 'column' }} spacing={4}>
        <CostumTextField name="currentPassword" control={control} label={'ძველი პაროლი'} />
        <CostumTextField name="newPassword" control={control} label={'ახალი პაროლი'} />
        <CostumTextField name="confirmPsw" control={control} label={'გაიმეორეთ პაროლი'} />

        <Button type="submit" variant="contained" size="large" disabled={Boolean(!isDirty || loading)}>ცვლილებების შენახვა</Button>
      </Stack>
    </form>
  </div>
}
export default ChangePassword;

interface ICostumTextField {
  name: string;
  label: string;
  control: any;
}

const CostumTextField: React.FC<ICostumTextField> = ({
  name,
  label,
  control,

}) => {

  const [showPassword, setShowPassword] = useState(false)
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl >
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            {...field}
            error={!!error}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(state => !state)}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }

          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};