import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "./slices/authSlice";
import langReducer from "./slices/i18nSlice";
import translationsReducer from "./slices/translationsSlice";
import carDetailsReducer from "./slices/carDetailsSlice";
import creditCardReducer from "./slices/creditCardSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lang: langReducer,
    translations: translationsReducer,
    car: carDetailsReducer,
    creditCard: creditCardReducer,
  },
  devTools: process.env.REACT_APP_ENV !== "production",
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
