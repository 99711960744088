import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        height: '100vh',
        color: '#0070f4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{img:{width:'100px'}}}><img src="https://static.vecteezy.com/system/resources/previews/012/863/216/original/car-broken-illustration-on-a-background-premium-quality-symbols-icons-for-concept-and-graphic-design-vector.jpg" alt=""/></Box>
      <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '200px', borderBottom: '2px dotted #0070f4', marginBottom: '1em' }}>
        <Box style={{ fontSize: '60px', fontWeight: 'bolder', lineHeight: '60px', marginRight: '0.5em' }}>4</Box>
        <Box style={{ fontSize: '60px', fontWeight: 'bolder', lineHeight: '60px', marginRight: '0.5em' }}>0</Box>
        <Box style={{ fontSize: '60px', fontWeight: 'bolder', lineHeight: '60px' }}>4</Box>
      </Box>
      <Typography style={{ textAlign: 'center' }}>
        Sorry, the page you're looking for doesn't seem to be available on our cars website.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/')}
        style={{ marginTop: '1em' }}
      >
        Go to Homepage
      </Button>
    </Box>
  );
};

export default ErrorPage;


