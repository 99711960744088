import React, { FC, useState, useEffect, useCallback } from 'react'
import { useParams } from "react-router-dom";

import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import * as Models from "Models"

import { Container, GarageContent } from './styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddNewCar from "./AddNewCar";
import SelectedCarForPayment from './SelectedCarForPayment';
import SelectPackage from "./SelectPackage";
import Payment from "./Payment";

const steps = ['ავტომობილის დამატება', 'პაკეტის არჩევა', 'გადახდის ეტაპი'];

const UpdateGarage: React.FC = () => {
  //const axiosPrivateInstance = useAxiosPrivateInstance();

  // ამ ინფორმაციას ვიყენებთ ბოლო ბიჯზე რექუესთის გასაკეთებლად [START]
  const [activateNextButton, setActivateNextButton] = useState<boolean>(false);
  const [isNewlyAddedUserCar, setIsNewlyAddedUserCar] = useState<boolean>(false);
  const [newlyAddedUserCar, setNewlyAddedUserCar] = useState<Models.AddCarResponse>();
  const [selectedPackageId, setSelectedPackageId] = useState<string>("");
  const [isYearlyPaySelected, setIsYearlyPaySelected] = useState<boolean>(false);
  const { alreadyAddedCarId } = useParams();
  // ამ ინფორმაციას ვიყენებთ ბოლო ბიჯზე რექუესთის გასაკეთებლად [END]

  const handleNewlyAddedCar = (response: any) => {
    console.log("handleNewlyAddedCar", response);
    setIsNewlyAddedUserCar(true);
    setNewlyAddedUserCar(response);
    handleComplete();
  };

  const handlePackageIdSelect = (packageId: string) => {
    setSelectedPackageId(packageId);
    handleComplete();
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    //handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <GarageContent>
      <h4>გარაჟი</h4>
      <Container>
        <Box sx={{ width: '100%' }}>
          <Stepper nonLinear activeStep={activeStep} >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]} sx={{height:'100%'}}>
                <StepButton color="inherit" onClick={handleStep(index)} sx={{'@media(max-width:600px)':{'span':{flexDirection:'column', height:'100%'}}}}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  {activeStep + 1 == 1 ? (<>
                    {isNewlyAddedUserCar ? isNewlyAddedUserCar && <SelectedCarForPayment carId={newlyAddedUserCar!.uniqueId} setActivateNextButton={setActivateNextButton} /> :
                      alreadyAddedCarId ? <SelectedCarForPayment carId={alreadyAddedCarId} setActivateNextButton={setActivateNextButton} /> :
                        <AddNewCar onNewlyAddedCar={handleNewlyAddedCar} />
                    }
                  </>) : null}
                  {activeStep + 1 == 2 ? (<>
                    <SelectPackage onPackageIdSelect={handlePackageIdSelect} selectedPackageId={selectedPackageId} onYearlyPayChange={setIsYearlyPaySelected} isYearlyPaySelected={isYearlyPaySelected} />
                  </>) : null}
                  {activeStep + 1 == 3 ? (<>
                    <Payment newlyOrAlreadyAddedCarId={newlyAddedUserCar ? newlyAddedUserCar.uniqueId : alreadyAddedCarId} selectedPackageId={selectedPackageId} isYearlyPaySelected={isYearlyPaySelected} />
                  </>) : null}
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    variant="outlined"
                    size="large"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    უკან
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep + 1 != 3 ?
                    <Button variant="contained" size="large" onClick={handleNext} sx={{ mr: 1 }} disabled={!isNewlyAddedUserCar && !activateNextButton}>
                      გაგრძელება
                    </Button>
                    : null}
                  {/* {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Complete Step'}
                      </Button>
                    ))} */}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Container>
    </GarageContent>
  )
}

export default UpdateGarage