import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";

import { Container, InputContainer, LeftContainer, RightContainer } from "./styles";
import { useTranslate } from "Hooks/useTranslate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "../../../Hooks/useLogin";
import * as Models from "Models";
import * as Schemas from "Schemas";
import CostumTextInput from "Components/Generals/costumInputs/CostumTextInput";
import CostumMaskedInput from "Components/Generals/costumInputs/CostumMaskedInput";
import HeroImage from "Assets/images/carserviceimage.jpg";

type LoginFields = {
  phoneNumber: string;
  password: string;
};

export const Login: FC = () => {
  const { translate } = useTranslate();
  const { loading, serverErrors, loginAsync } = useLogin();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFields>({
    resolver: yupResolver(Schemas.LoginSchema),
  });

  const onSubmit = (data: LoginFields) => {
    data.phoneNumber = data.phoneNumber.replace(/-/gi, "");
    loginAsync(data);
  };
  return (
    <>
      <Header />
      <Container>
        <RightContainer>
          <h2>Welcome back. We exist to <br /> make life easier.</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CostumMaskedInput label={translate("შეიყვანეთ ნომერი")} name={"phoneNumber"} control={control} mask={"[5]00-00-00-00"} />
            <CostumTextInput label={translate("პაროლი")} name={"password"} control={control} type="password" />
            <Link to="/resetPassword" className="forget-password">
              {translate("პაროლის აღდგენა")}
            </Link>
            <input type="submit" className="submit-btn" disabled={!!loading} value={translate("შესვლა")} />
            <button className="register-btn" disabled={!!loading} onClick={(e)=>{e.preventDefault(); navigate('/sign-up')}}>{translate("რეგისტრაცია")}</button>
            <div className="signUp">
              <span>Don’t you have an account? </span>
              <Link to="/sign-up">{translate("დარეგისტრირდი")}</Link>
            </div>
          </form>
        </RightContainer>
      </Container>
     {/*  <Footer /> */}
    </>
  );
};

export default Login;
