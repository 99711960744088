import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useEffect, useState } from 'react';
import { axiosInstance } from 'Axios/axios';
import styled from "@emotion/styled";


interface IFaq {
  id: number;
  sortIndex: number;
  question: number;
  answer: number;
}
const HelpCenter: React.FC = () => {
  const [data, setData] = useState<IFaq[] | null>(null)
  useEffect(() => {
    axiosInstance.get('FAQ/GetAllAsync')
      .then(res => setData(res.data))
  }, [])
  return (
    <div>
      {data?.map((el, index) => <AccordionWrapper key={index}>
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h6 className="faq__question">{el.question}</h6>
          </AccordionSummary>
          <AccordionDetails>
            <p className="faq__answer">
              {el.answer}
            </p>
          </AccordionDetails>
        </Accordion>
      </AccordionWrapper>)}
    </div>
  )
}

export default HelpCenter;

export const AccordionWrapper = styled("div")`
  margin: 0px auto 20px;
  width:100%;

  .faq__question {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #161c2d;
    margin-right: 10px;
  }

  .faq__answer {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #6f6f7a;
  }
  .MuiPaper-root {
    border-radius: 8px !important;
  }
  .MuiAccordionSummary-root {
    margin: 0 !important;
  }
  .MuiAccordionSummary-gutters {
    padding: 0 24px;
  }

  .MuiAccordionSummary-content .Mui-expanded {
    margin: 18px 0;
  }

  .MuiAccordionDetails-root {
    padding: 4px 24px 18px 24px;
  }
`;