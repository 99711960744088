import { Box, Grid, Stack, Typography } from '@mui/material';
import { serviceImg } from 'Assets/images';
import React from 'react';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import Button from '@mui/material/Button/Button';
import styled from '@emotion/styled';
import { device } from 'Components/common/device';


type ServicesProps = {
    
};

const Services:React.FC<ServicesProps> = () => {
    
    return <Grid container spacing={7}>
        {[1,2,3,4].map(el=><Grid item xs={12} md={6} key={el}><SingleService/></Grid>)}
    </Grid>
}
export default Services;


const SingleService = () => {
    return <Stack direction={"row"} alignItems="center" spacing={4}>
    <ServiceImage src={serviceImg} alt="" />
    <Stack sx={{gap:'16px'}}>
        <ServiceTitle
       
            
            
        
        >საწვავის გამოძახება</ServiceTitle>
        <CallButton 
        variant="contained"
        color="success"
 
        >
            <PhonelinkRingIcon />
            დარეკვა
        </CallButton>
    </Stack>
</Stack>
}

const mobile = '(max-width:600px)';

const ServiceImage = styled.img`
    
    width:145px;
    height:145px;
    @media ${mobile} {
        width:70px;
    height:70px;
    }

`

const ServiceTitle = styled(Typography)`
    font-weight: 400;
font-size: 18px;
line-height: 21px;
    color:#193A8D;
    
    @media ${mobile}{
        font-size:12px;
        line-height:14px;
    }

`

const CallButton = styled(Button)`
    
    border-radius:10px;
    padding:14px 33px;
    display:flex;
    gap:20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    width:fit-content;

    svg {
        font-size:22px;
    }
    @media ${mobile}{
        padding:12px 20px;
        font-size:14px;
        svg {
            font-size:21px;
        }
    }
`