import { Box, Typography } from '@mui/material';
import { mainLogoLarge } from 'Assets/images';
import React from 'react';


export const PaymentReturn:React.FC = () => {
    return <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', gap:'10px', padding:'10px', height:'100vh', boxSizing:'border-box', zIndex:'1231243545345345345412', backgroundColor:'white', position:'absolute', width:'100vw'}}>
        <img src={mainLogoLarge} alt=""/>
        <Typography textAlign={"center"} sx={{color:'green'}}>ოპერაცია შესრულებულია.</Typography>
    </Box>

}

