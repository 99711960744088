import React, { useEffect, useState } from 'react'
import { PricesDesktop, PricesMobile } from 'Components/PricesComponents';
import { Box } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { axiosInstance } from 'Axios/axios';
import { toast } from 'react-toastify';
import * as Models from 'Models';


const PricingPlan:React.FC = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const [packagesWithOffers, setPackagesWithOffers] = useState<Models.PackageModel[]>([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {

      setLoading(true)
      axiosInstance.get('Public/Packages/GetPackagesAsync')
      .then(res=>setPackagesWithOffers(res.data))
      .catch(err=>toast.error(err.message))
      .finally(()=>setLoading(false))

  }, [])
  return (<Box sx={{width:'100%', overflow:'hidden'}}>
  {matches ? <PricesDesktop data={packagesWithOffers} loading={loading} />
                :
                <PricesMobile data={packagesWithOffers} loading={loading}/>}
</Box>
    // <Container sx={{overflowX:'auto'}}>
      
    // </Container>
  )
}

export default PricingPlan;



