import { Join, Post, WhatToReadNext } from "Components/SingleNewsComponents";
import React from "react";

export const SingleNews = () => {
  return (
    <>
      <Post />
      <WhatToReadNext />
      <Join />
    </>
  );
};

export default SingleNews;
