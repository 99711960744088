import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';

type CostumDateInputProps = {
    name: string;
    control: any;
    label: string;
    errors:any;
}
let date = new Date();
let formattedCurrentDate = dayjs(date).format('DD-MM-YYYY');


export const CostumDateInput: React.FC<CostumDateInputProps> = ({ name, control, label, errors }) => {



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field : {onChange , value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={label}
              value={value}
              onChange={(date) => onChange(date)}
              renderInput={(params) => <TextField {...params} error={!!errors[name]} helperText={errors[name]?.message}/>}
            />
          </LocalizationProvider>
        )}
      />
    </LocalizationProvider>
  );
};

export default CostumDateInput;