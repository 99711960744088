import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export const Container = styled(Paper)`
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 24px;
    width: 100%;
    max-width: 1224px;
    box-sizing: border-box;
    padding: 32px;
    
    .container__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;

        &.second {
            margin-top: 56px;
        }
    }

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #0070f4;
    }
`;

export const PaymentMethodsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
`;

export const SingleCardStyled = styled(Paper)`
    overflow-x: auto;
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom right, #8c98ff, #5262e7);
    position:relative;
    
    .cardButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        background-image: linear-gradient(to bottom right, #00FFFF70, #00FF00);
        gap:10px;
        opacity:0;
        &:hover{
            opacity:1;
        }
        svg{
            cursor:pointer;
        }
    }

    .cardNumber {
        font-size: 20px;
        font-weight: bold;
        color: white;
        user-select: none;
        margin-top:auto;
        margin-bottom:20px;
        text-align:left;
        width:100%;
    }

    .cardExpirationDateAndType {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        color: white;
        user-select: none;
        img {
            height: 18px;
        }
    }
    @media (max-width:600px){
       
    }
`;

export const AddCard = styled(Paper)`
    overflow-x: auto;
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom right, #ccd0ff, #948eff);
    color: white;

    /* * {
        margin: 4px 0px;
    } */

    div {
        color: inherit;
        font-weight: bold;
    }
`;
