import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { getCarById } from 'Redux/slices/carDetailsSlice';
import {
  Dialog,
  Switch,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import CostumDateInput from "Components/Generals/costumInputs/CostumDateInput";
import CostumTextInput from "Components/Generals/costumInputs/CostumTextInput";
import dayjs from "dayjs";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { Box, Paper, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CostumSelectInput from "Components/Generals/costumInputs/CostumSelectInput";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { useAddExpense } from "./hooks";
import { useAppSelector } from "Redux/store";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import * as Modedls from 'Models';

let date = new Date();

type CostControlFormModalProps = {};

// detail types

interface IDetail {
  key: string;
  value: string | boolean;
  type: number;
}

// dialogue title

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

// dialog

const CostControlFormModal: React.FC<CostControlFormModalProps> = () => {
  const navigate = useNavigate();
  const { carId } = useParams();
  const onClose = () => {
    navigate(`/garage/car-details/${carId}`);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userId = useAppSelector((state) => state.auth.userInfo.id);
  const expenseCategories = useSelector((state: RootState) => state.car.expenseCategories);
  return (
    <Dialog open={true} fullScreen={fullScreen} onClose={onClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        ხარჯის დამატება
      </BootstrapDialogTitle>
      <Box sx={{ padding: "20px" }}>
      {(userId && expenseCategories.length!==0) ?  <Form userId={userId} expenseCategories={expenseCategories} /> : <CircularProgress />}
      </Box>
    </Dialog>
  );
};
export default CostControlFormModal;

// form

interface IForm { 
  userId:string;
  expenseCategories:Modedls.ExpenseCategoriesResponse[]
}

interface IFormInput {
  amount: number;
  date: any;
  categoryId: number;

}

enum DetailValueTypes {
  TEXT,
  BOOL,
}

const schema = yup
  .object({
    amount: yup.string().required(),
  })
  .required();

const Form: FC<IForm> = ({userId, expenseCategories}) => {
  const dispatch = useDispatch<any>();
  const [loading, addExpenseAsync] = useAddExpense();
  const [details, setDetails] = useState<IDetail[]>([]);
  const { carId } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 0,
      date: date,
      categoryId: expenseCategories[0].id,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    let formattedCurrentDate = dayjs(data.date).toISOString();
    const updatedData = {
      ...data,
      userId,
      carId,
      data: details,
      date: formattedCurrentDate,
    };
    addExpenseAsync(updatedData)
      .then((response) => {
        navigate(`/garage/car-details/${carId}`);
        dispatch(getCarById(carId!));
      });
    console.log(updatedData);
  };

  const onDetailChange = (e: any, index: number, keyName: "value" | "key") => {
    const newValue =
      e.target.type == "text" ? e.target.value : e.target.checked;
    setDetails((state) => {
      const updatedState = [...state];
      updatedState[index][keyName] = newValue;
      return updatedState;
    });
  };
  console.log(details);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <CostumSelectInput
            name={"categoryId"}
            control={control}
            label={"კატეგორია"}
            options={expenseCategories.map((el) => ({
              value: el.id,
              label: el.title,
            }))}
            defaultSelected={expenseCategories[1].id}
          />
          <CostumTextInput
            label={"ხარჯი"}
            name={"amount"}
            control={control}
            errors={errors}
            placeholder="ხარჯი"
            type="number"
          />
          <CostumTextInput
            label={"კომპანია"}
            name={"company"}
            control={control}
            errors={errors}
            placeholder="კომპანია"
            type="string"
          />
          <CostumDateInput
            name={"date"}
            control={control}
            label={"თარიღი"}
            errors={errors}
          />
          <Typography>ხარჯის დეტალები:</Typography>

          <Stack spacing={2}>
            {details.length === 0 ? (
              <Typography fontSize={12}>
                *დეტალები არ არის დამატებული
              </Typography>
            ) : (
              details?.map(({ key, value, type }, index) => (
                <Paper
                  elevation={2}
                  p={1}
                  key={index}
                  component={Stack}
                  gap={"10px"}
                  alignItems={"center"}
                  justifyContent="space-between"
                  direction={"row"}
                >
                  <TextField
                    size="small"
                    value={key}
                    label={"სათაური"}
                    fullWidth
                    onChange={(e) => onDetailChange(e, index, "key")}
                  />
                  {type === DetailValueTypes.TEXT ? (
                    <TextField
                      size="small"
                      value={value}
                      label={"ტექსტი"}
                      fullWidth
                      onChange={(e) => onDetailChange(e, index, "value")}
                    />
                  ) : (
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={value}
                            onChange={(e) => onDetailChange(e, index, "value")}
                          />
                        }
                        label={"მონიშვნა"}
                      />
                    </FormControl>
                  )}
                  <DeleteIcon
                    onClick={() =>
                      setDetails((state) =>
                        state.filter((item, ind) => index !== ind)
                      )
                    }
                    sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
                  />
                </Paper>
              ))
            )}
            <AddDetail
              addDetailHandler={(e) => setDetails((state) => [...state!, e])}
            />
          </Stack>
          <Button variant="contained" type="submit" disabled={!!loading}>
            შენახვა
          </Button>
        </Stack>
      </form>
    </>
  );
};

// add detail inputs to form

interface IAddDetailInputs {
  key: string;
  value: string | boolean;
  type: number;
}

interface IAddDetail {
  addDetailHandler: (e: IAddDetailInputs) => void;
}

const AddDetail: FC<IAddDetail> = ({ addDetailHandler }) => {
  const [isCheckbox, setIsCheckbox] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(600));
  const DetailTypes = [
    {
      key: "",
      value: "",
      type: DetailValueTypes.TEXT,
    },
    {
      key: "",
      value: false,
      type: DetailValueTypes.BOOL,
    },
  ];

  const onAdd = () => {
    const detailSchema = DetailTypes[Number(!isCheckbox)];
    addDetailHandler(detailSchema);
  };

  return (
    <Paper
      sx={{
        margin: "20px 0",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: !mobile ? "row" : "column",
      }}
    >
      <Button onClick={onAdd}>{"დაამატე დეტალის ველი"}</Button>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{ fontWeight: isCheckbox ? "400" : "bold" }}>
          მოსანიშნი
        </Typography>
        <CostumSwitch
          inputProps={{ "aria-label": "ant design" }}
          onChange={(e) => setIsCheckbox((state) => !state)}
          checked={isCheckbox}
        />
        <Typography sx={{ fontWeight: !isCheckbox ? "400" : "bold" }}>
          ტექსტური
        </Typography>
      </Stack>
    </Paper>
  );
};

// costum switch styles

const CostumSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: "border-box",
    backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
  },
}));
