import styled from "@emotion/styled";

export const ContainerStyled = styled.div`
    max-width:1440px;
    padding:70px;
    margin:auto;
`

export const HeadingStyled = styled.h2`
text-align:left;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 48px;
letter-spacing: -2px;
color: #000000;
margin-bottom:64px;
`

export const PostsStyled = styled.div`

display:flex;
align-items:center;
justify-content:flex-start;
gap:32px;
border-bottom: 1px solid #6D6E7630;
@media(max-width:940px){
    flex-direction:column;
}
`


export const SinglePostStyled = styled.div`
flex:1;
img{
    width:100%;
    margin-bottom:32px;
    
}

.post__author{
        font-size:14px;
     line-height:20px;
     margin-bottom:16px;
     .highlited{
         color:#0070f4
     }
    }
    h4{
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -1px;
        color: #232536;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #232536;
        opacity: 0.7;
        margin-bottom:64px;
    }
`

