import React from 'react'
import {ContainerStyled, HeadingStyled, PostsStyled, SinglePostStyled} from './styles';
import postPhoto from 'Assets/images/photo-of-woman-looking-at-man-3183173.svg';

const WhatToReadNext:React.FC = () => {
  return (
    <ContainerStyled>
      <HeadingStyled>What to read next</HeadingStyled>
      <PostsStyled>
        <SinglePostStyled>
      <img src={postPhoto} alt=""/>
      <div className="post__author">
            <span>by <span className="highlited">John Doe </span>| May 23, 2022 </span>
          </div> 
          <h4>A UX Case Study Creating a Studious Environment for Students: </h4>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
          </SinglePostStyled>

          <SinglePostStyled>
      <img src={postPhoto} alt=""/>
      <div className="post__author">
            <span>by <span className="highlited">John Doe </span>| May 23, 2022 </span>
          </div> 
          <h4>A UX Case Study Creating a Studious Environment for Students: </h4>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
          </SinglePostStyled>

          <SinglePostStyled>
      <img src={postPhoto} alt=""/>
      <div className="post__author">
            <span>by <span className="highlited">John Doe </span>| May 23, 2022 </span>
          </div> 
          <h4>A UX Case Study Creating a Studious Environment for Students: </h4>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
          </SinglePostStyled>
          </PostsStyled>
      </ContainerStyled>
  )
}

export default WhatToReadNext

