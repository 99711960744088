import styled from "@emotion/styled";
import { device } from "Components/common/device";

export const Container = styled("div")`
  width: 100%;
  margin: auto;
  display: flex;
  padding-top: 10px;
  border-radius: 0px 0px 20px 20px;
  justify-content: center;
`;

export const HeaderLayout = styled("header")`
  max-width: 1440px;
  background-color: transparent;
  width: 100%;
  padding: 24px 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.laptop} {
    padding: 24px 32px;
  }
`;
export const HeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .burgerIcon {
    cursor: pointer;
    margin-right: 36px;
    height: 25px;
    @media (min-width: 1383px) {
      display: none;
    }
  }
  @media ${device.laptop} {
    justify-content: center;
    width: 100%;
    div {
      width: fit-content;
    }
    .burgerIcon {
      position: absolute;
      left: 20px;
    }
  }
`;
export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const NavStyled = styled("nav")`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 18px;

  a {
    padding: 12px;
    color: #191919;
    text-decoration: none;
    letter-spacing: 0.02em;
    font-weight: 700;
  }
  a.active {
    color: #191919;
    font-weight: 700;
  }

  a.disabled {
    opacity: 0.5;
    /* cursor:not-allowed; */
  }
  @media (max-width: 1382px) {
    display: none;
  }
`;

export const Auth = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .button_signup {
    color: white;
    background: #191919;
    font-size: medium;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    text-transform: none;
    white-space: nowrap;
  }
  .button_signup:hover {
    color: white;
    background: #333333;
    border-radius: 10px;
  }
  .button_signin {
    color: white;
    background: #0070f4;
    border-radius: 10px;
    padding: 20px 30px 20px 30px;
    text-transform: none;
    white-space: nowrap;
  }
  @media ${device.laptop} {
    .button_signup {
      font-size: small;
      padding: 15px 30px 15px 30px;
    }
    .button_signin {
      font-size: small;
      padding: 15px 30px 15px 30px;
    }
  }
  a {
    text-decoration: none;
  }
  button {
    padding: 16px 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    box-shadow: none;
  }
`;

export const Language = styled.div`
  display: flex;
  gap: 8px;
  margin: 0px 18px;
  color: #191919;
  cursor: pointer;
  svg {
    font-size: 28px;
  }

`;

// Drawer content

export const DrawerContentStyled = styled.div`
  .disabled {
    opacity: 0.5;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    box-sizing: border-box;
    padding: 28px 16px;
    padding-top: 60px;
  }

  ul li {
    display: flex;
    align-items: center;
    gap: 14px;
    box-sizing: border-box;
    svg {
      color: #191919;
      font-size: 27px;
    }
  }

  ul li a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #191919;
    min-width: 122px;
    text-transform: capitalize;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 34px;
    padding: 15px 23px;
    border-radius: 12px;
    width: 100%;
    min-width: 180px;
    &.active {
      background-color: #eef4ff;
    }
    &:hover {
      background-color: #eef4ff;
    }

    &.notActive {
      background-color: transparent;
    }
  }
`;
