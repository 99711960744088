import React, { useState } from 'react';

import styled from "@emotion/styled";

import Stack from '@mui/material/Stack';

import silverCoin from "Assets/images/silver_coin.png";
import goldCoin from "Assets/images/gold_coin.png";

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';


function Loyality() {
    const [coinType, setCoinType] = useState<number>();

    const [state, setState] = React.useState({
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const { one, two, three, four, five, six, seven } = state;
    const error = [one, two, three, four, five, six, seven].filter((v) => v).length !== 5;

    return (
        <LoyalityWrapper>
            <h3>მონეტები</h3>
            <div style={{ margin: "50px 0px", fontSize: "22px" }}>
                გთხოვთ, აირჩიოთ მონეტა
            </div>
            <div className='coins'>
                <Stack direction={"row"} spacing={2}>
                    <button
                        className={coinType == 0 ? "active" : ""}
                        onClick={() => setCoinType(0)}
                    >
                        <img src={silverCoin} alt="Silver Coin" />
                        <div>ვერცხლის<br />მონეტა</div>
                    </button>
                    <button
                        className={coinType == 1 ? "active" : ""}
                        onClick={() => setCoinType(1)}
                    >
                        <img src={goldCoin} alt="Silver Coin" />
                        <div>ოქროს<br />მონეტა</div>
                    </button>
                </Stack>
            </div>
            <div style={{ margin: "50px 0px", fontSize: "22px" }}>
                გთხოვთ, აირჩიოთ შეთავაზებების 5 კატეგორია
            </div>
            <Stack direction="row" spacing={12} sx={{'@media(max-width:700px)':{flexDirection:'column'}, gap:'30px'}}>
                <div>
                    <FormControl
                        required
                        error={error}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormLabel component="legend">აირჩიეთ კატეგორიები</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={one} onChange={handleChange} name="one" />
                                }
                                label="მანქანა"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={two} onChange={handleChange} name="two" />
                                }
                                label="აფთიაქი და სილმაზე"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={three} onChange={handleChange} name="three" />
                                }
                                label="ტექნიკა"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={four} onChange={handleChange} name="four" />
                                }
                                label="სახლი და ეზო"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={five} onChange={handleChange} name="five" />
                                }
                                label="სურსათი"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={six} onChange={handleChange} name="six" />
                                }
                                label="რესტორანი / კვება"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={seven} onChange={handleChange} name="seven" />
                                }
                                label="Antoine Llorca"
                            />
                        </FormGroup>
                        <FormHelperText>{error ? "კატეგორიების რაოდენობა არ უნდა იყოს ნაკლები ან მეტი 5-ზე" : "ახლა შეგიძლიათ აირჩიო კომპანიები"}</FormHelperText>
                    </FormControl>
                </div>
                <div style={{marginLeft:0}}>
                    {!error &&
                        <FormControl
                            required
                            error={error}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormLabel component="legend">აირჩიეთ კომპანიები</FormLabel>
                            <FormGroup>
                                <div style={{ padding: "20px 0px", color: "red" }}>კომპანიების სია ცარიელია</div>
                            </FormGroup>
                        </FormControl>
                    }
                </div>
            </Stack>
            <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="large"
                disabled>
                პაკეტის შეძენა
            </Button>
        </LoyalityWrapper>
    )
}

export default Loyality;

export const LoyalityWrapper = styled("div")`
    .coins {
        button {
            div {
                color: #4c4f54;
                font-size: 16px;
                font-family: "FreeSansBold";
                text-align: center;
                line-height: 1.2;
                transition: all 0.3s ease;
            }

            img {
                height: 120px;
                border: 4px solid transparent;
                border-radius: 100%;
                padding: 10px;
                transition: all 0.3s ease;
                @media (max-width:800px){
       
                height: 80px;
        
    }
            }

            &.active {
                img{
                    border: 4px solid #0070f4;
                }
                div {
                    color: #0070f4;
                }
            }
        }
    }
   
`;