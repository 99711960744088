import { axiosInstance } from "Axios/axios";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Models from "Models";
import { useNavigate } from "react-router-dom";

export const useSignUp = () => {
  const navigate = useNavigate();
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const signUpAsync = (signupdata: Models.SignUpModel) => {
    setLoading(true);
    delete signupdata.confirmPsw;
    axiosInstance
      .post<Models.SignUpResModel>(`Account/RegisterUserAsync`, signupdata)
      .then((res) => {
        if (res.data.succeeded) {
          toast.success("გილოცავთ! თქვენ წარმატებით დარეგისტრირდით");
          navigate("/login");
        } else {
          const array: string[] = [];
          res.data.errors.map((er: any) => array.push(er.description));
          toast.error("oops something went wrong")
          setServerErrors(array);
        }
      })
      .catch((err) => {
        console.log(err.response);

        setServerErrors([err.response.data.title]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { signUpAsync, loading, serverErrors };
};
