import * as Models from "Models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/store";
import { axiosInstance } from "Axios/axios";

const tempBearer =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiNTU1MTEyMjMzIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiYWRtaW4iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjhkNTc1YjUyLTI2MjctNDM1MC1hM2ViLWRlZDg4YmY5YWVmMSIsImV4cCI6MTY3MjE1MzYyNywiaXNzIjoiaHR0cDovL2xvY2FsaG9zdCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3QifQ.kLDN8L3ehry_faqzXr_lubWieltwcdjQMwj8Ax7rs4w";

export const useTranslate = () => {
    const language = useSelector((state: RootState) => state.lang.language);

    const translations: Models.TranslationModel[] = JSON.parse(
        localStorage.getItem("translations") as string
    );

    const translate = (text: string) => {
        let index = -1;
        if (translations != null) {
            index = translations.findIndex((t) => t.geo === text);
        }
        if (index != -1) {
            switch (language) {
                case "EN":
                    return translations[index].eng;
                default:
                    return translations[index].geo;
            }
        }

        // if everithing above not happened
        // if (process.env.REACT_APP_ENV == "development") {
        //     axiosInstance
        //         .post(
        //             "Translation/AddAsync",
        //             {
        //                 geo: text,
        //                 eng: "",
        //             },
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${tempBearer}`,
        //                 },
        //             }
        //         )
        //         .then((response) => {
        //             console.log(text + " Added to DB", response.data);
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
        return text;
    };

    return { translate };
};
