import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import { Stack, Switch, SwitchProps, Typography } from '@mui/material';
import {
    PlanContainer,
    Price,
    Included,
    PlanBtn,
    Carousel,
    Slide,
  } from "./styles";
  import Slider from "react-slick";
  import { Box } from "@mui/system";
import { lariIcon, lariIconWhite } from 'Assets/images';
import Plan, { plansData } from './Plan';


const CreatePlanRow = () => {

    const [annual, setAnnual] = useState<boolean>(false);



    return <StyledTableRow>
    <StyledTableCell component="th" scope="row" className="name"     sx={{
       
        display:'flex'
      }}>
    <Box 

    >
    <Stack
          sx={{
            margin: "auto",
            gap: "10px",
            display:'flex',
            flexDirection:'row',
            // height:'fit-content',
            alignItems:'center',
            width:'280px',
            padding:'0 16px',
            
          }}
          
          
        >
          <Typography sx={{ fontSize: 15, lineHeight:'23px', display:'block' }}>Monthly</Typography>
          <IOSSwitch
            sx={{ m: 1 }}
            value={!annual}
            onChange={() =>
              setAnnual((state) => {
                console.log(state);
                return !state;
              })
            }
          />
          <Typography sx={{ fontSize: 15, lineHeight:'23px', display:'block' }}>Yearly</Typography>
        </Stack>
        </Box>

    </StyledTableCell>

    {plansData.map((plan) => (
          <StyledTableCell align="right" key={plan.id}>  <Plan {...plan} annual={!annual} /></StyledTableCell>
          ))}
  </StyledTableRow>
  
  }


  export default CreatePlanRow;

  // table row

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "&.name": {
     
      border:'none'
    },
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
      textAlign: "left",
      border:'none'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
    //   backgroundColor: "#F8FBFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // switch

  export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 85,
    height: 44,
    padding: 0,
    transform: "scale(1)",
    "@media(max-width:600px)": {
      transform: "scale(0.6)",
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 5,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(42px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#0A68FF",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 34,
      height: 34,
    },
    "& .MuiSwitch-track": {
      borderRadius: 42 / 2,
      backgroundColor: "#0A68FF",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

