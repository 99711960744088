import axios, { AxiosError } from "axios";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { axiosInstance } from "Axios/axios";
import { TokenModel } from "Models";
import { logOut } from "../Redux/slices/authSlice";

const baseURL = process.env.REACT_APP_BASE_URL;

const useAxiosPrivateInstance = (isLoadingRequired: boolean = true) => {
    const dispatch = useDispatch();

    const axiosPrivateRef = useRef(
        axios.create({
            baseURL: baseURL,
        })
    );

    useEffect(() => {
        const requestInterceptor =
            axiosPrivateRef.current.interceptors.request.use(
                function (config) {
                    // Do something before request is sent
                    // Set promiseInProgress for loadint component to true

                    // throw new axios.Cancel("req canceled")

                    if (isLoadingRequired) {
                        document.body.classList.add("showLoading");
                    }

                    if (!config.headers!["Authorization"]) {
                        config.headers![
                            "Authorization"
                        ] = `Bearer ${localStorage.getItem("bearerToken")}`;
                    }

                    return config;
                },
                function (error) {
                    // Do something with request error
                    return Promise.reject(error);
                }
            );

        const responseInterceptor =
            axiosPrivateRef.current.interceptors.response.use(
                (response) => {
                    if (isLoadingRequired) {
                        document.body.classList.remove("showLoading");
                    }
                    return response;
                },
                async (error: AxiosError) => {
                    const prevReqConfig = error.config;

                    if (isLoadingRequired) {
                        document.body.classList.remove("showLoading");
                    }

                    if (
                        error.response?.status === 401 &&
                        !prevReqConfig.headers!["marked"]
                    ) {
                        prevReqConfig.headers!["marked"] = "marked";

                        let newAccessToken = "";
                        let newRefreshToken = "";

                        await axiosInstance
                            .post<TokenModel>(`Account/refreshToken`, {
                                refreshToken:
                                    localStorage.getItem("refreshToken"),
                            })
                            .then((response) => {
                                newAccessToken = response.data.bearerToken;
                                newRefreshToken = response.data.refreshToken;
                            })
                            .catch(() => {
                                dispatch(logOut());
                            });

                        if (newAccessToken && newRefreshToken) {
                            prevReqConfig.headers![
                                "Authorization"
                            ] = `Bearer ${newAccessToken}`;
                            localStorage.setItem("bearerToken", newAccessToken);
                            localStorage.setItem(
                                "refreshToken",
                                newRefreshToken
                            );
                            return axiosInstance(prevReqConfig);
                        }
                    }

                    // Any status codes that falls outside the range of 2xx cause this function to trigger
                    // Do something with response error
                    return Promise.reject(error);
                }
            );

        return () => {
            axiosPrivateRef.current.interceptors.response.eject(
                requestInterceptor
            );
            axiosPrivateRef.current.interceptors.request.eject(
                responseInterceptor
            );
        };
    }, []);

    return axiosPrivateRef.current;
};

export default useAxiosPrivateInstance;
