import {
  Box,
  Button
} from "@mui/material";
import React from "react";
import { FeaturedPostLayout, HeadingStyled, ContentStyled} from "./styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import postImg from 'Assets/images/white-concrete-building-1838640.svg';


const FeaturedPost: React.FC = () => {
  return ( <FeaturedPostLayout>
        
        <HeadingStyled>NEWS</HeadingStyled>
        <ContentStyled>
          <img src={postImg} alt="" />
          <div className="featuredPost__author">
            <span>by <span className="highlited">John Doe </span>| May 23, 2022 </span>
          </div>
          <h4 className="post__heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</h4>
           <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>            
                  <Button 
            variant="contained"

          >
             Read More <KeyboardArrowRightIcon /> 
           </Button> 
        </ContentStyled>
      </FeaturedPostLayout>
  );
};

export default FeaturedPost;

