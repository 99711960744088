import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface IInitialState {
    loading:boolean,
    translations:Itranslation[],
    error:string;
    searchValue:string;
}

interface Itranslation{   
        id:number;
        geo:string;
        eng:string;   
}

const initialState: IInitialState = {
    loading:false,
    translations:[],
    error:'',
    searchValue:''
    
};

export const getTranslationsAsyncThunk = createAsyncThunk(
    "app/getTranslationsAsync",
    async () => {
        return axiosInstance
            .get("Translation/GetAllAsync", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "bearerToken"
                    )}`,
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
        // return Promise.resolve(localTranslations);
    }
);

export const translationsSlice = createSlice({
    name: "translations",
    initialState,
    reducers: {
        addTranslation:(state, action:PayloadAction<Itranslation>)=>{
            state.translations.unshift(action.payload)

        },
        editTranslation:(state, action:PayloadAction<Itranslation>)=>{
            const foundIndex = state.translations.findIndex(
                (x: any) => x.id == action.payload.id
            );
            state.translations[foundIndex] = action.payload
        },
        deleteTranslation:(state, action:PayloadAction<number>)=>{
            state.translations = state.translations.filter(item=> item.id !== action.payload)
        },
        setSearchValue:(state, action:PayloadAction<string>)=>{
            state.searchValue = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTranslationsAsyncThunk.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTranslationsAsyncThunk.fulfilled, (state, action) => {
            state.loading = false;
            state.translations = action.payload
        });
        builder.addCase(getTranslationsAsyncThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.toString();

        });
    },
});



export const { addTranslation, editTranslation, deleteTranslation, setSearchValue } = translationsSlice.actions;
export default translationsSlice.reducer;
