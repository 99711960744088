import { FC } from "react";
import Header from 'Components/Header/Header'
import Footer from 'Components/Footer/Footer'
import { OrderedListStyled } from "./styles";

export const TermsAndConditions: FC = () => {
    return (
        <>
            <Header />
            <div className="container">
                <OrderedListStyled>
                    <h1>წესები/პირობები</h1>
                    <p>ნებისმიერი სამართლებრივი ურთიერთობა, რომელიც აქ არ არის აღნიშნული, რეგულირდება საქართველოს კანონმდებლობის შესაბამისად.</p>
                    <p>მოცემული წესები და პირობები წარმოადგენს პლატფორმის/ვებ–გვერდის/აპლიკაციების მომხმარებელსა და შპს „ერთი კლუბი საქართველოს“ ს/კ 405503520  შორის დადებულ შეთანხმებას და განსაზღვრავს თქვენსა და კომპანიას შორის ურთიერთობის ძირითად საკითხებს.</p>
                    <ol>
                        <li>კლუბი პლატფორმის/ვებ–გვერდის/აპლიკაციების საშუალებით გთავაზობთ სხვადასხვა საჭირო ონლაინ ინსტრუმენტებს, ფასდაკლებებს, ექსკლუზიურ ფასდაკლებებს, უფასო სერვისებს და სხვა  მომსახურებებს.</li>
                        <li>ჩვენთან წარმოდგენილი ფასდაკლებები/სერვისები/საქონელი წარმოდგენილია მესამე მხარის მიერ, რომელიც თვითონაა პასუხისმგებელი შესაბამისი ფასით მომსახურების/საქონლის მიწოდებაზე.</li>
                        <li>კლუბის საწევროს გადახდა გულისხმობს, შესაბამის პაკეტში შემავალი კომპანიების ფასდაკლებებით/სერვისებით სარგებლობას.</li>
                        <li>თუ თქვენ საწევროს იხდით ყოველთვიურად, საწევროს გადახდის შეჩერება დაუშვებელია 12 თვის განმავლობაში.</li>
                        <li>ნებისმიერი გაურკვევლობის ან საწევროს გადახდასთან დაკავშირებულ საკითხებზე მოგვწერეთ ელექტრონულ ფოსტაზე info@autocircle.ge</li>
                        <li>პლატფორმით სარგებლობისთვის, აუცილებელია დაეთანხმოთ აღნიშნულ წესებსა და პირობებს. კლუბი უფლებას იტოვებს, ნებისმიერ დროს ცალმხრივად, ამავე ვებგვერდზე ინფორმაციის მიწოდებით, შეცვალოს პირობები, წესები, პაკეტში შემავალი მომსახურებები, ნებისმიერ დროს.</li>
                        <li>პლატფორმაზე შეყვანილი პერსონალური მონაცემები დაცულია, თუმცა კლუბი პასუხს არ აგებს მონაცემებზე არასანქცირებულ წვდომაზე, რომელიც არღვევს საქართველოს კანონმდებლობას და მსგავსი ფაქტის შემთხვევაში მიმართავს შესაბამის სახელმწიფო ორგანოებს.</li>
                        <li>პლატფორმაზე/ვებ–გვერდზე/აპლიკაციებში გამოყენებული დიზაინი/ტექნოლოგიები წარმოადგენს კლუბის საკუთრებას და დაცულია საქართველოს კანონმდებლობით.</li>
                        <li>პლატფორმაზე/ვებ–გვერდზე/აპლიკაციებში გამოყენებული ნებისმიერი ინფორმაციის სხვაგან გამოყენებისას, აუცილებელია კლუბის წინასწარი თანხმობა.</li>
                        <li>უფასო სერვისების, ფასდაკლებების გამოყენება, შეგიძლიათ მხოლოდ პლატფორმაზე წარმოდგენილ კომპანიებში. მომსახურების ხარისხზე პასუხისმგებელია შემსრულებელი კომპანია.</li>
                        <li>რეგისტრირებული მომხმარებლის ბაზიდან წაშლა, შესაძლებელია მხოლოდ საწევროს გადახდის გაუქმების შემთხვევაში თუ ამას თავად მომხმარებელი მოითხოვს ელექტრონულ ფოსტაზე მიწერის გზით info@autocircle.ge</li>
                        <li>დარეგისტრირებული მონაცემები დაცულია საქართველოს კანონმდებლობის შესაბამისად.</li>
                        <li>რეგისტრაციის გზით მომხმარებელი თანახმაა, რომ პირად პროფილში მიიღოს ნებისმიერი შეტყობინება, ასევე თანახმაა კლუბის მარკეტინგული აქტივობების შესახებ ინფორმაცია მიიღოს რეგისტრირებულ მობილურის ნომერზე, მოკლეტექსტური შეტყობინების ან სატელეფონო ზარის საშუალებით.</li>
                        <li>ნებისმიერი არასანქცირებული/უკანონო წვდომა პლატფორმაზე/ვებ–გვერდზე/აპლიკაციებში, რომელიც გამოიწვევს მომხარებლის დაზარალებას, უნდა ეცნობოს შესაბამის სახელმწიფო დაწესებულებას. კლუბი არ არის პასუხისმგებელი სხვა პირის უკანონო ქმედებებზე.</li>
                    </ol>
                </OrderedListStyled>
            </div>
            <Footer />
        </>
    )
}

export default TermsAndConditions;