import React, { FC, useMemo, useState, useRef } from "react";
import { IMaskInput } from "react-imask";
import { ReactElement } from 'react-imask/dist/mixin';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Edit from "@mui/icons-material/Edit";
import { Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface ICostumTextField {
  name: string;
  label: string;
  control: any;
  InputLabelProps?: Partial<InputLabelProps> | undefined;
  size?: "small" | "medium" | undefined;
}
const CostumPasswordField: React.FC<ICostumTextField> = ({
  name,
  label,
  control,
}) => {
  console.log();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            {...field}
            error={!!error}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((state) => !state)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

interface ICostumNumberEditField {
  name: string;
  label: string;
  control: any;
  handleEdit: any;
  content?: string;
}
const CostumNumberEditField: React.FC<ICostumNumberEditField> = ({
  name,
  label,
  control,
  handleEdit,
  content,
}) => {
  return (
    <FormControl variant="outlined">
      <InputLabel>{content}</InputLabel>
      <OutlinedInput
        type="number"
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleEdit} edge="end">
              <Edit />
            </IconButton>
          </InputAdornment>
        }
        label={label}
        disabled={true}
      />
    </FormControl>
  );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CostumMobileNumberMask = React.forwardRef<ReactElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="[5]00-00-00-00"
        definitions={{
          "#": /[5]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

interface State {
  newPhoneNumber: string;
  numberformat: string;
}

const CostumMobileNumberField: React.FC<ICostumNumberField> = ({
  name,
  label,
  control,
  InputLabelProps,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
            label={label}
            {...field}
            name="newPhoneNumber"
            InputProps={{
              inputComponent: CostumMobileNumberMask as any,
            }}
            InputLabelProps={InputLabelProps}
            variant="outlined"
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

interface ICostumNumberField {
  name: string;
  label: string;
  control: any;
  InputLabelProps?: Partial<InputLabelProps> | undefined;
}
const CostumNumberField: React.FC<ICostumNumberField> = ({
  name,
  label,
  control,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
            label={label}
            {...field}
            variant="outlined"
            error={!!error}
            type="number"
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

const CostumTextField: React.FC<ICostumTextField> = ({
  name,
  label,
  control,
  InputLabelProps,
  size,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
          autoComplete="off"
            label={label}
            {...field}
            variant="outlined"
            error={!!error}
            InputLabelProps={InputLabelProps}
            size={size}
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

interface ICostumCheckbox {
  label: string;
  name: string;
  control: any;
}

const CostumCheckbox: React.FC<ICostumCheckbox> = ({
  label,
  name,
  control,
}) => {
  return (
    <Box component={'section'} sx={{display:'flex', alignItems:'center'}}>
     
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ...rest } }) => (
          <Checkbox {...rest} checked={value}/>
        )}
      />
       <Box component={"label"} sx={{lineHeight:'18px'}}>{label}</Box>
    </Box>
  );
};

interface ICostumDateField {
  name: string;
  control: any;
  label: string;
}

const CostumDateField: FC<ICostumDateField> = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      defaultValue={null}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={label}
            renderInput={(params) => (
              <TextField {...params} error={!!error ? true : false} />
            )}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  );
};

const optionsd = [
  {
    label: "ქალი",
    value: 0,
  },
  {
    label: "კაცი",
    value: 1,
  },
  {
    label: "სხვა",
    value: 2,
  },
];

interface ICostumSelect {
  name: string;
  control: any;
  label: string;
}

const CostumSelect: FC<ICostumSelect> = ({ name, control, label }) => {
  const generateSelectOptions = () => {
    return optionsd.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <Select onChange={onChange} value={value}>
            {generateSelectOptions()}
          </Select>
        </FormControl>
      )}
    />
  );
};

const options = [
  {
    label: "ქალი",
    value: 0,
  },
  {
    label: "კაცი",
    value: 1,
  },
  {
    label: "სხვა",
    value: 2,
  },
];

const CostumRadioInputs: React.FC<any> = ({ name, control, label }) => {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
        key={singleOption.value}
      />
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormLabel id={label}>{label}</FormLabel>
          <RadioGroup value={value} onChange={onChange} id={label}>
            {generateRadioOptions()}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export {
  CostumTextField,
  CostumCheckbox,
  CostumDateField,
  CostumRadioInputs,
  CostumSelect,
  CostumPasswordField,
  CostumNumberEditField,
  CostumNumberField,
  CostumMobileNumberField,
};
