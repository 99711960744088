import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface AuthState {
    isLoggedIn: boolean;
    userInfo: Models.UserModel;
    loadingUser: boolean;
    errorLoadingUser: unknown;
}

const initialState: AuthState = {
    isLoggedIn: false,
    userInfo: {
        id: "",
        personalNumber: 0,
        phoneNumber: 0,
        firstName: "",
        lastName: "",
        email: "",
        avatarUrl: "",
        role: "",
        packages: [],
        extraOffers: [],
        dateOfBirth: "",
        dateOfCreate: "",
    },
    loadingUser: false,
    errorLoadingUser: null,
};

export const getUser = createAsyncThunk("user/getUser", async () => {
    return axiosInstance
        .get<Models.UserModel>("Account/GetUserAsync", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
            },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logIn: (state, action: PayloadAction<Models.LoginResponse>) => {
            localStorage.setItem("bearerToken", action.payload.bearerToken);
            localStorage.setItem("refreshToken", action.payload.refreshToken);
            state.isLoggedIn = true;
        },
        logOut: (state) => {
            localStorage.removeItem("bearerToken");
            localStorage.removeItem("refreshToken");
            state.isLoggedIn = false;
            state.userInfo = initialState.userInfo;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        editUserInfo: (state, action) => {
            state.userInfo = { ...state.userInfo, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.loadingUser = true;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loadingUser = false;
            state.userInfo = action.payload;
            state.errorLoadingUser = "";
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.loadingUser = false;
            state.userInfo = initialState.userInfo;
            state.errorLoadingUser = action.error;
        });
        builder.addCase(logOut, (state) => {
            state.loadingUser = false;
            state.userInfo = initialState.userInfo;
            state.errorLoadingUser = "";
        });
    },
});

export const { logIn, logOut, setUserInfo, editUserInfo } = authSlice.actions;
export default authSlice.reducer;
