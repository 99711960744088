import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import * as Models from "Models";

import { Container, GarageContent, CarItem } from "./styles";

import GeorgianFlag from "Assets/images/georgianflag.png";
import GarageCarItemIcon from "Assets/images/CarTopViewHorizontal.png";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import StarIcon from "@mui/icons-material/Star";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import UpdateIcon from '@mui/icons-material/Update';

const Garage: FC = () => {
  const axiosPrivateInstance = useAxiosPrivateInstance();

  const [userGarage, setUserGarage] = useState<Models.GetUserGarageResponse[]>();

  const navigate = useNavigate();
  useEffect(() => {
    axiosPrivateInstance
      .get<Models.GetUserGarageResponse[]>("Garage/GetUserGarageAsync")
      .then((response) => setUserGarage(response.data))
      .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის მწარმოებლების წამოღებისას"));
  }, []);

  return (
    <GarageContent>
      <div className="headerTitleAddNewCar">
        <h4>გარაჟი</h4>
        <Link to={`add-car`}>
          <Button variant="contained" size="large" startIcon={<DirectionsCarIcon />}>
            მანქანის დამატება
          </Button>
        </Link>
      </div>
      <Container>
        {userGarage ? (
          <div className="carsList">
            {userGarage.length == 0 ? (
              <Stack sx={{ alignItems: "center", width: "100%", boxSizing: "border-box", padding: "20px" }} spacing={3}>
                <DirectionsCarIcon sx={{ color: "#869AB8", fontSize: "40px" }} />
                <Typography sx={{ fontSize: "14px", lineHeight: "26px", color: "#869AB8", textAlign: "center", width: "100%" }}>
                  დაამატე ავტომობილი
                </Typography>
              </Stack>
            ) : (
              userGarage.map((car) => {
                return (
                  <CarItem key={car.uniqueId} className={car.package.uniqueId == null ? "disabled" : ""}>
                    {car.package.uniqueId != null ? (
                      <Link key={car.uniqueId} to={`car-details/${car.uniqueId}`}>
                        <div className="flexer">
                          <div className="imgDiv">
                            <img src={GarageCarItemIcon} alt={`${car.make} ${car.model}`} />
                          </div>
                          <div >
                            <div className="carPlate">
                              <div className="carPlateLeft">
                                <img className="flagDiv" src={GeorgianFlag} alt="flag" />
                                <span className="carPlateCountry">GE</span>
                              </div>
                              <div className="carPlateNumber">{car.licensePlate.toUpperCase()}</div>
                              <div className="carPlateRight"></div>
                            </div>
                            <div>მწარმოებელი: {car.make}</div>
                            <div style={{ padding: "15px 0px" }}>მოდელი: {car.model}</div>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <div className="packageChip">
                                <StarIcon />
                                {car.package.title}
                              </div>
                              <div>
                                <Link to={`/garage/add-car/${car.uniqueId}`}>
                                  <Button variant="contained" style={{ minWidth: "auto" }}>
                                    <UpdateIcon style={{ fontSize: "26px", margin: "0px" }} />
                                  </Button>
                                </Link>
                              </div>
                            </Stack>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <Link key={car.uniqueId} to={`car-details/${car.uniqueId}`}>
                        <div className="flexer">
                          <div className="imgDiv">
                            <img src={GarageCarItemIcon} alt={`${car.make} ${car.model}`} />
                          </div>
                          <div >
                            <div className="carPlate">
                              <div className="carPlateLeft">
                                <img className="flagDiv" src={GeorgianFlag} alt="flag" />
                                <span className="carPlateCountry">GE</span>
                              </div>
                              <div className="carPlateNumber">{car.licensePlate.toUpperCase()}</div>
                              <div className="carPlateRight"></div>
                            </div>
                            <div>მწარმოებელი: {car.make}</div>
                            <div style={{ padding: "15px 0px" }}>მოდელი: {car.model}</div>
                            {/* <div className="packageChip">
                            <StarIcon />
                            {car.package.title}
                          </div> */}
                            {car.package.uniqueId == null ? (
                              <Link to={`/garage/add-car/${car.uniqueId}`}>
                                <Button variant="contained" className="buttonBuy" size="small" startIcon={<ArrowCircleRightIcon />}>
                                  განაგრძე პაკეტის შეძენა
                                </Button>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </Link>
                    )}
                  </CarItem>
                );
              })
            )}
          </div>
        ) : (
          <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "300px" }}>
              <CircularProgress />
            </div>
          </>
        )}
      </Container>
    </GarageContent>
  );
};

export default Garage;
