import styled from "@emotion/styled";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";

export const Container = styled.div`

  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  margin: auto;
  margin-bottom: 55px;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #0070f4;
    margin-top: 36px;
    margin-bottom: 50px;
  }

  form {
    max-width: 332px;
    padding: 20px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing:border-box;
    .forget-password {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #0070f4;
      text-decoration: none;
    }
    .submit-btn {
      padding: 19.5px 24px;
      background: #193a8d;
      border-radius: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
      margin-bottom: 20px;
      &:disabled {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.9;
      }
    }
    .signUp {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #869ab8;
      a {
        color: #0070f4;
      }
    }
  }
  .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .server__errors{
    border:1px solid red;
    background:#f5426030;
    padding:10px;
    padding-left:30px;
    li{
      list-style:disc;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: #10002e;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;

    background: #ffffff;
    border: 1px solid #d5d4dc;
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.2px;
    color: #8b849b;
  }
`;

export const TermsField = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  input{
    transform: scale(1.2);
    cursor:pointer;
  }
  div {
    display: flex;
    align-items: center;
    gap:32px;
  }

  span {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #161c2d;
  }
`;

// Modal

export const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 16px;
    padding-top: 50px;
  }
  .close-icon {
    background: #79747e;
    color: white;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 50%;
    cursor:pointer;
  }
`;

export const DialogBody = styled.div`
display:flex;
align-items:center;
flex-direction:column;
justify-content:center;
  svg {
    margin: 35px 20px;
    font-size: 60px;
    color:#0070f4;
    
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #293961;
    margin-bottom:27px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #293961;
    margin-bottom:100px;
  }

  button {
    border: none;
    outline: none;
    background: #0070f4;
    border-radius: 4px;
    color: white;
    padding: 13px 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor:pointer;
  }
`;
