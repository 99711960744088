import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SingleGuarantee from 'Components/ProfileComponents/GarageComponents/SingleGuarantie'
import React from 'react'
import { Container } from './styles'


const Guarantees: React.FC = () => {
  return (
    <div>
      <h3>გარანტია ძრავის ზეთზე</h3>
      <br /><br />
      <p>
        ძრავის ზეთის გარანტიის მისაღებად შეცვალეთ ზეთი პარტნიორ კომპანიაში
      </p>
    </div>
  )
}

export default Guarantees

/* <div className="offers__heading">
        <h4>Guarantees</h4>
      </div> 
      <Stack spacing={7}>
        {[1].map(el=><SingleGuarantee key={el}/>)}
      </Stack>*/