import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { useState } from "react"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteExpense } from "Redux/slices/carDetailsSlice";

export const useDeleteExpense = ():[boolean, (id: string) => Promise<void>] => {

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const axiosPrivateInstancec = useAxiosPrivateInstance();

    const deleteExpenseAsync = async (id:string) => {
        setLoading(true)
        axiosPrivateInstancec.delete(`Expense/DeleteByIdAsync/${id}`)
        .then(res=>{
            toast.success('ხარჯი წაშლილია')
            dispatch(deleteExpense({expenseId:id}))
        })
        .catch((err)=>{
            toast.error(err.message)
        })
        .finally(()=>{
            setLoading(false)
        })
    }

    return [loading, deleteExpenseAsync]

}