type RolesTypes = {
    readonly Admin: string;
    readonly Partner: string;
    readonly Blogger: string;
    readonly User: string;
};

const Roles: RolesTypes = {
    Admin: "admin",
    Partner: "partner",
    Blogger: "blogger",
    User: "user",
};

export default Roles;
