import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { InputLabelProps } from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';
import {Controller} from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { ReactElement } from 'react-imask/dist/mixin';


type CostumMaskedInputProps = {
    name: string;
    label: string;
    control: any;
    InputLabelProps?: Partial<InputLabelProps> | undefined;
    mask:string;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CostumMaskedInput:React.FC<CostumMaskedInputProps> = ({
    name,
    label,
    control,
    InputLabelProps,
    mask
  }) => {
    
    const CostumMobileNumberMask = useMemo(()=>{
      return React.forwardRef<ReactElement, CustomProps>(
      function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        
        return (
          <IMaskInput
            {...other}
            mask={mask}
            inputRef={ref}
            onAccept={(value: any) =>
              onChange({ target: { name: props.name, value } })
            }
            overwrite
          />
        );
      }
    );},[])
  

    return (
        <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <TextField
          
            label={label}
            {...field}
            name="newPhoneNumber"
            InputProps={{
              inputComponent: CostumMobileNumberMask as any,
            }}
            InputLabelProps={InputLabelProps}
            variant="outlined"
          />
          <FormHelperText error={!!error}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
      );
}
export default CostumMaskedInput;



