import React from 'react';
import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';

interface Props {
  label: string;
  name: string;
  register?: any; // your type for register 
  CheckboxProps?: CheckboxProps;
  control:any;
}

const MyCheckbox: React.FC<Props> = ({ label, name, control, CheckboxProps }) => {
  return (
    
    <Controller
        name={name}
        control={control}
        render={({ field: { value, ...rest }, fieldState:{error} }) => (
          <FormControl error={!!error ? true : false}>
          <FormControlLabel name={name} label={label} control={<Checkbox {...rest} />} />
          {!!error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
  

  );
};

export default MyCheckbox;
