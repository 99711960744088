import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export const GridStyled = styled(Grid)`
    width: 100%;

    .gray-btn {
        background: #f3f7ff;
        border-radius: 16px;
        padding: 10px 7px;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #193a8d;
        cursor: pointer;
    }

    .see-more {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        color: #0070f4;
        cursor: pointer;
    }
`;
export const BigBlockStyled = styled(Paper)`
    overflow-x: auto;
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    .bigBlock__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;
        padding: 0 16px;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0070f4;
        }
    }
`;

export const OffersList = styled("div")`
    display: grid;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    gap: 20px;

    @media (max-width: 1660px) {
        grid-template-columns: auto;
    }
`;

export const SingleService = styled(Paper)`
    padding: 16px;
    min-width: 268px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: #869ab8;
    }

    .service__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 29px;

        h5 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 123.5%;
            display: flex;
            align-items: center;
            letter-spacing: 0.25px;
            color: #193a8d;
        }

        .badge {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.16px;

            padding: 7px 10px;
            border-radius: 16px;
        }
        .badge-active {
            background: #edfff6;
            color: #21e080;
        }

        .badge-discount {
            font-weight: 700;

            color: #f4724a;
            background: #fff7f4;
        }
    }
`;

// second column small blocks

export const SmallBlockStyled = styled(Paper)`
    box-sizing: border-box;
    padding: 20px 16px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    .smallBlock__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0070f4;
        }
    }
`;

export const SingleNews = styled(Paper)`
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    .news__heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        margin-bottom: 8px;
        span {
            background-color: gray;
            color: white;
            padding: 10px;
            border-radius: 5px;
            text-align: center;
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #869ab8;
        margin-bottom: 28px;
    }
`;
