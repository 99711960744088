import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  Home,
  Login,
  SignUp,
  AboutUs,
  Prices,
  Offers,
  News,
  Forbidden,
  SingleNews,
  TermsAndConditions,
  PrivacyPolicy,
  MobileVerification,
  ResetPassword,
  PaymentReturn,
  ErrorPage
} from "Pages/GenericPages";

import {
  Dashboard,
  Garage,
  UpdateGarage,
  CarDetails,
  CallingServices,
  Guarantees,
  PaymentMethods,
  HelpCenter,
  Offers as UserOffers,
  PricingPlan,
  Loyality,
  Settings,
  UserProfile,
  EditExpenseModal,

} from "Pages/UserPages";

import Requirements from "Security/Requirements/Requirements";
import ProtectedRoute from "../Security/ProtectedRoute/ProtectedRoute";
import MainRouteSwitch from "../Security/MainRouteSwitch/MainRouteSwitch";
import LoggedOutRoute from "../Security/LoggedOutRoute/LoggedOutRoute";
import { MaintContainer, ProfileContainer, CarContainer } from "Containers";
import CostControlFormModal from "Pages/UserPages/Garage/CostControlFormModal";
import ScrollToTop from "Components/Generals/ScrollToTop/ScrollToTop";

const Routing = () => {
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route
          path="/"
          element={<MainRouteSwitch />}
        />
        <Route path="/payment-return-url" element={<PaymentReturn />} />
        <Route path="/home" element={<LoggedOutRoute component={Home} />} />
        <Route path="/login" element={<LoggedOutRoute component={Login} />} />
        <Route path="/resetPassword" element={<MaintContainer component={<LoggedOutRoute component={ResetPassword} />} />} />
        <Route
          path="/sign-up"
          element={<MaintContainer component={<LoggedOutRoute component={SignUp} />} />}
        />
        <Route
          path="/mobile-verification"
          element={<MaintContainer component={<LoggedOutRoute component={MobileVerification} />} />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/news">
          <Route index element={<MaintContainer component={<News />} />} />
          <Route path=":id" element={<MaintContainer component={<SingleNews />} />} />
        </Route>


        <Route path="/forbidden" element={<Forbidden />} />
        {/* user pages */}
        <Route path="/dashboard"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<Dashboard />} showRightSideBar={true} />}
            />
          }
        />
        <Route
          path="/user-offers"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<UserOffers />} />}
            />
          }
        />
        <Route
          path="/payment-methods"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<PaymentMethods />} />}
            />
          }
        />
        <Route
          path="/pricing-plan"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<PricingPlan />} />}
            />
          }
        />
        <Route
          path="/loyality"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<Loyality />} />}
            />
          }
        />
        <Route path="garage">
          <Route
            index
            element={
              <ProtectedRoute
                jsxElement={<ProfileContainer component={<Garage />} />}
              />
            }
          />
          <Route path="add-car" element={<ProtectedRoute jsxElement={<ProfileContainer component={<UpdateGarage />} />} />} >
            <Route path=":alreadyAddedCarId" element={<ProtectedRoute jsxElement={<ProfileContainer component={<UpdateGarage />} />} />} />
          </Route>
          <Route path="upgrade-car/:id" element={<ProtectedRoute jsxElement={<ProfileContainer component={<UpdateGarage />} />} />} />
          <Route path="car-details/:carId" element={<ProtectedRoute jsxElement={<CarContainer component={<CarDetails />} />} />} >
            <Route path="add-expense" element={<ProtectedRoute jsxElement={<CostControlFormModal />} />} />
            <Route path="edit-expense/:expanseId" element={<ProtectedRoute jsxElement={<EditExpenseModal />} />} />
          </Route>
        </Route>
        <Route
          path="/calling-services"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<CallingServices />} />}
            />
          }
        />
        <Route
          path="/guarantees"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<Guarantees />} />}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<Settings />} />}
            />
          }
        />
        <Route
          path="/help-center"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<HelpCenter />} />}
            />
          }
        />
        <Route
          path="/help-center"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<HelpCenter />} />}
            />
          }
        />
        <Route
          path="/user-profile"
          element={
            <ProtectedRoute
              jsxElement={<ProfileContainer component={<UserProfile />} />}
            />
          }
        />

        {/* error page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
