import { Box, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { AboutUsPhoto } from "Assets/images";
import { useTranslate } from "Hooks/useTranslate";

type FirstSectionProps = {};

const FirstSection: React.FC<FirstSectionProps> = () => {
  const { translate } = useTranslate();
  return (
    <Box>
      <Box sx={{ marginBottom: "47px" }}>
        <Line />
        <Stack
          sx={{
            padding: "96px 79px",
            backgroundColor: "#F8FCFF",
            "@media(max-width:700px)": { padding: "12px 34px" },
          }}
          direction={{ md: "row", xs: "column" }}
          spacing={5}
        >                                                                                                                                                                                                                                                                                                        
        
          <Article
            title={translate(aboutUsContent.title)}
            paragraph={translate(aboutUsContent.paragraph)}
          />
        </Stack>
        <Stack
          sx={{
            padding: "96px 79px",
            backgroundColor: "#F8FCFF",
            "@media(max-width:700px)": { padding: "12px 34px" },
          }}
          direction={{ md: "row", xs: "column" }}
          spacing={5}
        >
          <Article
            title={translate(mission.title)}
            paragraph={translate(mission.paragraph)}
          />
        </Stack>
      </Box>

      <ArticleWithPhotoStyled>
        <img src={AboutUsPhoto} alt="" />
        <div className="articleWrapper">
          <Article
            title={translate(goal.title)}
            paragraph={translate(goal.paragraph)}
          />
        </div>
      </ArticleWithPhotoStyled>
    </Box>
  );
};
export default FirstSection;

const Line = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          height: "23px",
          backgroundColor: "#193A8D",
          width: "70%",
          marginLeft: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0070f4",
            width: "30%",
            marginLeft: "auto",
            height: "100%",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

interface IArticleProps {
  title: string;
  paragraph: string;
}

const Article: FC<IArticleProps> = ({ title, paragraph }) => {
  return (
    <ArticleStyled>
      <h3>{title}</h3>
      <p>{paragraph}</p>
    </ArticleStyled>
  );
};

const ArticleStyled = styled.div`
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #0070f4;
    margin-bottom: 16px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #6d6e76;
  }

  @media (max-width: 700px) {
    h3 {
      font-size: 9px;
      line-height: 9px;
    }
    p {
      font-size: 10px;
      line-height: 16px;
    }
  }
`;

const ArticleWithPhotoStyled = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 300px;

  .articleWrapper {
    background-color: white;
    padding: 82px;
    position: absolute;
    right: 0;
    top: 130px;
    width: 70%;
  }

  @media (max-width: 1000px) {
    .articleWrapper {
      top: 50px;
    }
  }

  @media (max-width: 700px) {
    .articleWrapper {
      top: 100px;
    }
  }

  @media (max-width: 600px) {
    .articleWrapper {
      top: 30px;
    }
  }

  img {
    width: 60%;
  }

  @media (max-width: 992px) {
    .articleWrapper {
      padding: 24px;
    }
  }
`;
// content texts

const aboutUsContent: IArticleProps = {
  title: "About Us",
  paragraph:
    "AutoCircle is the perfect solution for car owners who want to save time and money while ensuring that their vehicle is well-maintained. Our platform offers a variety of services that are designed to make car ownership hassle-free and convenient. Our goal is to help you take care of your car smartly. With AutoCircle, you can access a wide range of resources that will help you keep your car in top condition. We provide advice on everything from regular maintenance tasks to more complex repairs, so you can feel confident that your car is in good hands. In addition to expert advice, AutoCircle also offers exclusive discounts on a variety of car-related products and services. Our partnerships with loyal companies that share our core value of customer care enable us to offer you the best possible deals on everything from oil changes to new tires. By using AutoCircle, you can also lower your car maintenance costs over time. Our platform is designed to help you track your car's maintenance history and schedule necessary services, so you can stay on top of important tasks without any added stress. Finally, AutoCircle is here to remind you of necessary services. With our platform, you'll never miss an oil change or tire rotation again. We'll send you reminders when it's time to perform routine maintenance, so you can stay on top of your car's needs and keep it running smoothly. In summary, AutoCircle is a consumer association that connects car owners with loyal companies whose core value is customer care. We help you take care of your car smartly by offering expert advice, exclusive discounts, lower maintenance costs, and reminders of necessary services. Join AutoCircle today and experience the benefits of hassle-free car ownership!",
};

const mission: IArticleProps = {
  title: "Mission",
  paragraph:
    "At our company, we are committed to simplifying car maintenance and making the lives of car owners more comfortable and stress-free. Our mission is to provide useful offers from trustworthy companies that help our members save time and money while ensuring that their vehicles are well-maintained. We understand that car maintenance can be a complicated and expensive process, which is why we have developed a technological solution that cuts our members' expenses by 15-20%. By leveraging the latest technology, we are able to streamline the maintenance process and offer our members exclusive discounts and deals that are not available elsewhere. Our platform is designed to make car maintenance easy and hassle-free. We provide our members with access to a wide range of resources, including expert advice, maintenance schedules, and reminders, so they can stay on top of their car's needs without any added stress. In addition to simplifying car maintenance, we are also dedicated to working with trustworthy companies that share our commitment to customer satisfaction. We carefully vet all of our partners to ensure that they meet our high standards for quality, reliability, and customer service. At the end of the day, our goal is to make car ownership more enjoyable and convenient for our members. By providing useful offers, cutting expenses, and leveraging technology to simplify the maintenance process, we believe that we can make a real difference in the lives of car owners everywhere.",
};

const goal: IArticleProps = {
  title: "Goal",
  paragraph:
    "In today's digital world, people are bombarded with countless advertisements, discounts, and offers on a daily basis. It can be overwhelming and time-consuming to sift through all of the options and make a decision. That's where we come in. Our goal is to simplify the process of finding useful offers by placing them all in one convenient platform. Imagine a space where every company unites around you and gives you only the most relevant and useful offers. With our membership, you will have access to a range of customized offers that are tailored specifically to your needs and preferences. By bringing together a wide range of companies and offers, we make it easy for our members to find what they need without wasting time or energy. Our platform is designed to be user-friendly and intuitive, allowing you to quickly and easily find the offers that are right for you. In addition to providing a convenient platform for finding offers, we also strive to offer personalized recommendations and advice. By understanding your unique needs and preferences, we can help you make informed decisions about which offers to take advantage of and which to pass on. Overall, our goal is to make it easier for people to find and take advantage of useful offers in a digital world that can be overwhelming and confusing. With our membership, you can streamline the process of finding offers and get access to customized deals that are tailored to your needs and preferences.",
};
