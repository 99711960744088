import styled from "@emotion/styled";

export const OrderedListStyled = styled.div`
    max-width: 1200px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    margin: auto;
    margin-bottom: 100px;

    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: #193a8d;
        margin-top: 90px;
        margin-bottom: 100px;
    }

    ol {
        list-style: auto;
    }

    li {
        margin: 20px 0px;
        line-height: 2em;
    }

    p {
        margin: 10px 0px;
        line-height: 2em;
    }
`;
