import { FC } from "react";
import { Link } from "react-router-dom";
import { Container, FooterLayout, Terms, Subscribe, FooterSection } from './styles';
import footerLogo from '../../Assets/images/Logo-New.svg';
import { Button } from "@mui/material";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import appleImage from 'Assets/images/Apple.svg';
import googleImage from 'Assets/images/Google.svg';
import { useTranslate } from "Hooks/useTranslate";


const Footer: FC = () => {
    const { translate } = useTranslate();
    return (<Container>
        <FooterLayout>


            <FooterSection>
                <h3> <div className="footer__logo">
                    <img src={footerLogo} style={{height: 40}} alt="" />
                </div></h3>
                <nav>
                    <Link to="/about-us">{translate("About Us")}</Link>
                    <Link to="/prices">{translate("Prices")}</Link>
                    <Link to=" " className="disabled">{translate("Offers")}</Link>
                    <Link to=" " className="disabled">{translate("News")}</Link>
                </nav>
            </FooterSection>
            <FooterSection>
                <h3>{translate("Contact")}</h3>
                <nav>
                   {/*  <Link to=" " className="disabled"><LocationOnIcon/> დავით აღმაშენებლის ხეივანი 13კმ</Link>
                    <Link to=" " className="disabled"><PhoneAndroidIcon/> 032 - 123 - 123</Link> */}
                    
                    {/* <Link to="/#"><LocationOnIcon/> დავით აღმაშენებლის ხეივანი 13კმ</Link>
                    <Link to="/#"><PhoneAndroidIcon/> 032 - 123 - 123</Link> */}
                    <Link to="/#"><MailIcon /> info@xcode.ge</Link>
                </nav>
            </FooterSection>

            <FooterSection>
                <h3>{translate("Download App")}</h3>
                <nav>
                    <Link to=" "><img src={googleImage} alt="" /> </Link>
                    <Link to=" "><img src={appleImage} alt="" /></Link>
                </nav>
            </FooterSection>

            {/* <Subscribe>
    
                <div className="footer__logo">
                    <img src={footerLogo} alt="" />
                </div>
                <p>Ut risus mattis interdum faucibus facilisi. Facilisi purus accumsan aliquam.</p>

                <form className="footer_subscribe-form">
                    <div className="footer__subscribe-input">
                        <input type="email" placeholder="Enter Your Email" />
                        <AlternateEmailIcon />
                    </div>
                    <Button variant="contained" type="submit">Subscribe</Button>
                </form>
    
                
                </Subscribe>
             */}

        </FooterLayout>
        <Terms>
            <ul>
                {/* <li><Link to="/terms-and-conditions">{translate('წესები და პირობები')}</Link></li>
                <li><Link to="/privacy-policy">{translate('კონფიდენციალურობის პოლიტიკა')}</Link></li> */}
                {/* <li className="disabled">Cookies</li>
                <li className="disabled">Refunds</li>
                <li className="disabled">License</li> */}
            </ul>
        </Terms>
    </Container>
    )
}

export default Footer;

