import React from 'react';
import styled from "@emotion/styled";
import { device } from 'Components/common/device';

function Container(props: any) {
    return (
        <Styles>
            {props.children}
        </Styles>
    )
}

export default Container;

const Styles = styled.div`
    width: 100%;
    background-color: rgb(255, 255, 255, 90%);
    padding: 60px;
    box-sizing: border-box;
    border-radius: 42px;
    overflow:auto;
    @media(max-width:800px){
        padding:30px;
        border-radius:30px;
    }

    @media(max-width:800px){
        padding:30px 10px;
        border-radius:30px;
    }


`