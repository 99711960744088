import { Box, Chip, Paper } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { OfferPhoto } from 'Assets/images';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import SingleOffer from 'Components/Generals/Offer/SingleOffer';
import OffersGrid from 'Components/Generals/Offer/OffersGrid';


type OffersListProps = {
    
};

const OffersList:React.FC<OffersListProps> = () => {
    
    return <OffersGrid/>
}
export default OffersList;



