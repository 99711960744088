import { styled } from "@mui/material";
import { device } from "Components/common/device";
import React from "react";

interface Headings {
  h5: string;
  h3: string;
}

const SectionHeading: React.FC<Headings> = ({ h5, h3 }) => {
  return (
    <Heading>
      <h5>{h5}</h5>
      <h3>{h3}</h3>
    </Heading>
  );
};

export default SectionHeading;

const Heading = styled("div")`
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 16px;
    color: #0070f4;
    font-family: "FreeSansBold";
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    max-width: 862px;
    margin: auto;
    text-align: center;
    font-family: "FreeSansBold";
    color: #191919;
    text-transform: capitalize;
  }
  @media ${device.mobile} {
    h3 {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;
