import React, { useState } from "react";
import SectionHeading from "../shared/SectionHeading";
import {
  Container,
  PlanContainer,
  Plans,
  PricingLayout,
  Price,
  Included,
  PlanBtn,
  Carousel,
  Slide,
  PopularBadge,
  SwitcherContaner,
} from "./styles";
import lariIcon from "../../../Assets/images/lari.svg";
import lariIconWhite from "../../../Assets/images/lari-white.svg";

import CheckIcon from "@mui/icons-material/Check";
import Slider from "react-slick";
import { Box } from "@mui/system";
import { Stack, Switch, SwitchProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "Hooks/useTranslate";

const Pricing: React.FC = () => {
  const { translate } = useTranslate();
  const [annual, setAnnual] = useState<boolean>(false);

  return (
    <Container>
      <PricingLayout>
        <SectionHeading
          h3={translate("დარეგისტრირდი უფასოდ")}
          h5={translate("ფასები")}
        />
        <p className="pricing__text">
          {translate("ან აირჩიე შენთვის სასურველი პაკეტი")}
        </p>
        <Stack
          sx={{
            margin: "auto",
            width: "fit-content",
            marginTop: "65px",
            gap: "36px",
            marginBottom: "65px",

            "@media(max-width:550px)": {
              gap: 0,
              marginBottom: "33px",
              marginTop: "33px",
            },
          }}
          direction="row"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: !annual ? "700" : "400",
              letterSpacing: "0.02em",
              linrHeight: "28px",
              color: "#161C2D",
              "@media(max-width:550px)": { fontSize: 12, lineHeight: "14px" },
            }}
          >
            {translate("თვიურად")}
          </Typography>
          <IOSSwitch
            sx={{ m: 1 }}
            value={!annual}
            onChange={() =>
              setAnnual((state) => {
                console.log(state);
                return !state;
              })
            }
          />
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: annual ? "700" : "400",
              letterSpacing: "0.02em",
              linrHeight: "28px",
              color: "#161C2D",
              "@media(max-width:550px)": { fontSize: 12, lineHeight: "14px" },
            }}
          >
            {translate("წლიურად")}
          </Typography>
        </Stack>
        <Plans>
          {plansData.map((plan) => (
            <Plan {...plan} annual={!annual} />
          ))}
        </Plans>
      </PricingLayout>
    </Container>
  );
};

export default Pricing;

interface IPlan {
  id: number;
  popular?: boolean;
  title: string;
  price: { annual: number; monthly: number };
  offers: IOffer[];
  annual?: boolean;
}

interface IOffer {
  id: number;
  title: string;
}

const Plan: React.FC<IPlan> = (props) => {
  const { translate } = useTranslate();
  const { popular, title, price, offers, annual } = props;
  const navigate = useNavigate();
  return (
    <PlanContainer className={!popular ? "" : "popular__plan"}>
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          backgroundCOlor: "red",
          width: "100%",
        }}
      >
        <PopularBadge>{translate("ყველაზე პოპულარული")}</PopularBadge>
      </Box>
      <h5> {translate(title)}</h5>
      <Price>
        <img src={!popular ? lariIcon : lariIconWhite} alt="" />{" "}
        <p className="price__monethly">
          {annual ? (
            <span>
              <span className="num">{price.monthly}</span> /
              {translate("თვიურად")}
            </span>
          ) : (
            <span>
              <span className="num">{price.annual}</span> /
              {translate("წლიურად")}
            </span>
          )}
        </p>
      </Price>
      <Included>
        <PlanCarousel offers={offers} />
      </Included>
      <PlanBtn
        variant="contained"
        className="learn__more-btn"
        onClick={() => navigate("/prices")}
      >
        {translate("გაიგე მეტი")}
      </PlanBtn>
    </PlanContainer>
  );
};

const PlanCarousel = ({ offers }: { offers: IOffer[] }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  const { translate } = useTranslate();

  return (
    <Carousel>
      <Slider {...settings}>
        {[1, 2, 3].map((el, index) => (
          <Slide key={index}>
            <ul key={index}>
              {offers.map((offer) => (
                <li key={offer.id} className="plan__carousel-li">
                  <CheckIcon />
                  {translate(offer.title)}
                </li>
              ))}
            </ul>
          </Slide>
        ))}
      </Slider>
    </Carousel>
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 85,
  height: 44,
  padding: 0,
  "@media(max-width:550px)": {
    transform: "scale(0.7)",
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 5,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(42px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#0A68FF",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 34,
    height: 34,
  },
  "& .MuiSwitch-track": {
    borderRadius: 42 / 2,
    backgroundColor: "#0A68FF",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//  offersdata

const plansData: IPlan[] = [
  {
    id: 1,
    title: "საბაზისო",
    price: { annual: 0, monthly: 0 },
    offers: [
      {
        id: 1,
        title: "ონლაინ გარაჟი",
      },
      {
        id: 2,
        title: "რეკომენდაციები",
      },
      {
        id: 3,
        title: "ხარჯების კონტროლი",
      },
      {
        id: 4,
        title: "ავტომატური შეხსენებები",
      },
    ],
  },
  {
    id: 2,
    popular: true,
    title: "სტანდარტული",
    price: { annual: 144, monthly: 12 },
    offers: [
      {
        id: 1,
        title: "ფასდაკლებები (15%+)",
      },
      {
        id: 2,
        title: "საჩუქრები",
      },
      {
        id: 3,
        title: "სხვადასხვა სერვისები",
      },
      {
        id: 4,
        title: "გარანტია ძრავის ზეთზე",
      },
    ],
  },
  {
    id: 3,
    title: "ექსკლუზივი",
    price: { annual: 228, monthly: 19 },
    offers: [
      {
        id: 1,
        title: "ევაკუატორის მომსახურება",
      },
      {
        id: 2,
        title: "ტექნიკური შემოწმება",
      },
      {
        id: 3,
        title: "ფასდაკლებები (20%+)",
      },
      {
        id: 4,
        title: "სავალი ნაწილების ხელობა",
      },
    ],
  },
];
