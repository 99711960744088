import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { device } from "Components/common/device";

export const Container = styled("div")`
  width: 100%;
  margin: auto;
  max-width: 1440px;
`;

export const PartnersLayout = styled("div")`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  color: white;
  padding: 0px 70px;
  box-sizing: border-box;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 16px;
    color: #0070f4;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    max-width: 862px;
    margin: auto;
    text-align: center;
    color: #161c2d;
  }
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 36px;
    padding: 10px;
  }
`;

export const PartnersBtn = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 29px;
  gap: 16px;
  margin: auto;
  font-family: inherit;
  box-shadow: none;
  svg {
    font-size: 20px;
  }
  @media ${device.mobile} {
    font-size: 16px !important;
    line-height: 20px;
    padding: 18px 24px;
  }
`;

export const PartnersCarouselContainer = styled("div")`
  margin: 64px 0;
  .partners__slider {
    max-width: 1300px;
  }
`;

export const PartnerLogoContainer = styled("div")`
outline: none;
.logo_images{
  transition: 0.8s;
}
.logo_images:hover{
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  transition: 0.8s;
}
`;
