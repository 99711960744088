import styled from "@emotion/styled";
import { device } from "Components/common/device";
import { Button } from "@mui/material";

export const Container = styled("div")`
  max-width: 1440px;
  margin: auto;
  padding-top: 20px;
`;

export const HeroLayout = styled("div")`
  box-sizing: border-box;
  padding: 50px 70px;
  display: flex;
  gap: 50px 32px;
  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @media ${device.laptop} {
    padding: 32px;
  }
  @media ${device.mobile} {
    gap: inherit;
    padding: 32px;
  }

`;

export const HeroLeft = styled("div")`
  max-width: 620px;
  width: 100%;
  margin-top: 20px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-family: "FreeSans";
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 40px;
    color: #191919;
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    font-family: "FreeSans";
    letter-spacing: 0.02em; 
    text-transform: capitalize;
    margin-bottom: 46px;
    color: #191919;
  }
  .gradienttext{
    color: transparent;
    --tw-gradient-to: #4FD1C5;
    --tw-gradient-from: #338CF5;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    -webkit-background-clip: text;
  }
  }
  @media (max-width: 1300px) {
    max-width: 710px;
  }
  @media ${device.laptop} {
    h1 {
      font-size: 56px !important;
      line-height: 68px !important;
    }
  }
  @media ${device.mobile} {
    h1 {
      font-size: 32px !important;
      line-height: 48px !important;
    }
    p {
      font-style: normal;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 20px !important; 
      letter-spacing: 0.02em !important;
      margin-bottom: 46px !important;
      color: #869ab8;
    }
  }
`;

export const DownloadBtns = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  margin-bottom: 40px;
  max-width: 532px;
  button {
    height: 62px;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    flex: 1;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: white;
    background: #193a8d;
    border-radius: 10px;
    padding: 18.947px 30.3152px;
    white-space: nowrap;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    &:nth-of-type(2) {
      svg {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 1300px) {
    max-width: 523px;
    /* margin: auto; */
  }
`;
export const Downloadtext = styled("span")`
  text-align: center;
  color: #191919;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const DownloadButtons = styled("div")`
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;

  .bordered-div {
    display: flex;
    margin-top: -2.8px;
    background-color: rgba(50, 111, 230, 0.1);
    border-radius: 18px;
    padding: 20px 26px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    box-sizing: border-box;
  }
  .form {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
  .form__email {
    padding: 10px 15px 10px 45px;
    border-radius: 10px;
    border: 1px solid #cad3db;
    font-size: 18px;
    color: #0f121f;
  }
  .form__email:focus {
    outline: 1px solid #191919;
  }
  .form__button {
    background: #191919;
    padding: 10px 30px 10px 30px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    color: white;
  }

  .distance_icon {
    margin-left: 150px;
  }
  p {
    text-align: center;
    color: #191919;
    font-weight: 700;
    font-size: 15px;
    line-height: 36px;
  }
  .appleButton {
    height: 48px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: white;
    background: #0070f4;
    border-radius: 12px;
    padding: 16px 23px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;

    &:nth-of-type(2) {
      svg {
        font-size: 18px;
      }
    }
  }
  .androidButton {
    height: 48px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    background: #191919;
    color: white;
    border-radius: 12px;
    padding: 16px 23px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    transition: 0.6s;

    &:nth-of-type(2) {
      svg {
        font-size: 18px;
      }
    }
  }
  .androidButton:hover{
    background: #333333;
  }
  @media (max-width: 1400px) {
    div {
      width: 100%;
    }
  }
  @media (max-width: 1300px) {
    .bordered-div {
      width: 100%;
    }
    
  }

  @media (max-width: 600px) {
    div {
      gap: 20px;
    }
    .distance_icon {
      display: none;
    }
    .bordered-div {
      width: 100%;
      flex-wrap: wrap;
      margin-top: 15px;
    }
    .bordered-div button {
      width: 100%;
    }
    .bordered-div .form input{
      width: 100%;
    }
  }

  @media (max-width: 525px) {
    .distance_icon {
      display: none;
    }
    div {
      margin-top: 15px;
      gap: 20px;
    }
    .bordered-div {
      width: 100%;
      flex-wrap: wrap;
    }
  }
`;

export const HeroRight = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  .rightImage {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 20px;
  }
  .circleimage {
    position: absolute;
    top: 100px;
    right: 100px;
  }

  @media (max-width: 1300px) {
    justify-content: center;
  }

  @media ${device.laptop} {
    justify-content: center;
  }
  @media ${device.mobile} {
    justify-content: center;
    & > div {
      overflow: unset;
      transform: scale(0.53);
    }
    .circleimage {
      display: none;
    }
  }
`;
export const SubmitFormMobileBtn = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-family: "FreeSans";
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 29px;
  gap: 16px;
  margin: auto;
  font-family: inherit;
  box-shadow: none;
  svg {
    font-size: 20px;
  }
  @media ${device.mobile} {
    font-size: 16px !important;
    line-height: 20px;
    padding: 18px 24px;
  }
`;

export const DialogTitle = styled("div")`
  font-style: normal;
  color: #0070f4;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.15px;
  line-height: 150%;
  padding: 0px 24px 10px 24px;
  margin-top: 58px;
`;

export const DialogContentText = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #0070f4;
  width: 80%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SubscribtionForm = styled("div")`
  button {
    border-radius: 10px;
    padding: 16px 40px;
    height: 56px;
    font-style: normal;
    box-sizing: border-box;
    outline: none;
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.16);
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    font-family: inherit;
    white-space: nowrap;
    width: 100%;
    &:hover {
      color: white;
    }
  }
`;
