import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import { Stack, Switch, SwitchProps, Typography } from '@mui/material';
import {
  PlanContainer,
  Price,
  Included,
  PlanBtn,
  Carousel,
  Slide,
  PopularBadge,
} from "./styles";
import Slider from "react-slick";
import { Box } from "@mui/system";
import { lariIcon, lariIconWhite } from 'Assets/images';
import { Link } from 'react-router-dom';
// plan 
interface IPlan {
  id: number;
  popular?: boolean;
  title: string;
  price: { annual: number; monthly: number };
  offers: IOffer[];
  annual?: boolean;
}

interface IOffer {
  id: number;
  title: string
}
const Plan: React.FC<IPlan> = (props) => {
  const { popular, title, price, offers, annual } = props;
  return (
    <PlanContainer>
      <Box sx={{
        height: '40px'
      }}>
        {(title === 'Standard') && <PopularBadge>Most Popular</PopularBadge>}
      </Box>


      <h5>{title}</h5>
      <Price>
        <img src={!popular ? lariIcon : lariIconWhite} alt="" />{" "}
        <p className="price__monethly">
          {annual ? (
            <div>
              <div className="num">{price.monthly}</div>
            </div>
          ) : (
            <div>
              <div className="num">{price.annual}</div>
            </div>
          )}
        </p>
      </Price>
      {/* <Included>
          <PlanCarousel offers={offers}/>
        </Included> */}
      <Link to="/garage/add-car">
        <PlanBtn variant="contained" className="learn__more-btn">
          {title === 'Basic' ? 'Try Free' : 'Buy'}
        </PlanBtn>
      </Link>
    </PlanContainer>
  );
};

export default Plan;

// plan carousel

const PlanCarousel = ({ offers }: { offers: IOffer[] }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          swipe: false,
          dots: false
        }
      }]
  };

  return (
    <Carousel>
      <Slider {...settings}>
        {[1, 2, 3].map((el, index) => (
          <Slide key={index}>
            <ul key={index}>
              {offers.map(offer => <li key={offer.id} className="plan__carousel-li">
                <CheckIcon /> {offer.title}
              </li>)}

            </ul>
          </Slide>
        ))}
      </Slider>
    </Carousel>
  );
};


export const plansData: IPlan[] = [
  {
    id: 1,
    title: "Basic",
    price: { annual: 0, monthly: 0 },
    offers: [
      {
        id: 1,
        title: "ონლაინ გარაჟი",
      },
      {
        id: 2,
        title: "რეკომენდაციები",
      },
      {
        id: 3,
        title: "ხარჯების კონტროლი",
      },
      {
        id: 4,
        title: "ავტომატური შეხსენებები",
      },
    ],
  },
  {
    id: 2,
    popular: false,
    title: "Standard",
    price: { annual: 144, monthly: 12 },
    offers: [
      {
        id: 1,
        title: "ფასდაკლებები (15%+)",
      },
      {
        id: 2,
        title: "საჩუქრები",
      },
      {
        id: 3,
        title: "სხვადასხვა სერვისები",
      },
      {
        id: 4,
        title: "გარანტია ძრავის ზეთზე",
      },
    ],
  },
  {
    id: 3,
    title: "Exclusive",
    price: { annual: 19 * 12, monthly: 19 },
    offers: [
      {
        id: 1,
        title: "ევაკუატორის მომსახურება",
      },
      {
        id: 2,
        title: "ტექნიკური შემოწმება",
      },
      {
        id: 3,
        title: "ფასდაკლებები (20%+)",
      },
      {
        id: 4,
        title: "სავალი ნაწილების ხელობა",
      },
    ],
  },
];
