import React from 'react'
import { ButtonStyled, ContainerStyled } from './styles'

const Join = () => {
  return (<ContainerStyled>
    <h4>Join our team to be a part of our story</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
    <ButtonStyled variant="contained">join now</ButtonStyled>
  </ContainerStyled>
  )
}

export default Join