import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from "Redux/store";

import styled from "@emotion/styled";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import welcomeCarIllustration from "Assets/images/welcome_car.png";
import expensesTimeColumn from "Assets/images/expenses_time_column.png";
import expensesIllustration from "Assets/images/welcome_expenses_illustration.png";
import expensesStatIllustration from "Assets/images/welcome_expenses_stat_illustration.png";



// icons
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import EastIcon from '@mui/icons-material/East';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import Box from '@mui/material/Box/Box';

function Welcome() {
    const navigate = useNavigate();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    return (
        <>
            <WelcomeWrapper>
                <Stack direction="row" spacing={2} className="hello" >
                    <Stack spacing={2} >
                        <h1>გამარჯობა <span className="name">{userInfo && userInfo.firstName}</span></h1>
                        <h3>კეთილი იყოს შენი მობრძანება</h3>
                        <div className='subtitle'>
                            აპლიკაციის სრული მომსახურებისთვის საჭიროა შეიძინო რომელიმე პაკეტი.<br />
                            <br />
                            ხარჯების კონროლი უფასოდაც შეგიძლია.<br />
                            პაკეტის შეძენა შეგიძლია გარაჟში მანქანის დამატებით.<br />
                            <br />
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <div>გზადაგზა დაგეხმარებით</div>
                                <FollowTheSignsIcon sx={{ color: "#0070f4" }} />
                            </Stack><br />
                        </div>
                        <div>
                            <Button onClick={() => navigate("/garage/add-car")} size='large' variant="contained" endIcon={<EastIcon />}>
                                პაკეტის შეძენა
                            </Button>
                        </div>
                    </Stack>
                    <div>
                        <Box sx={{'@media(max-width:860px)':{display:'none'}}}><img src={welcomeCarIllustration} alt="" /></Box>
                    </div>
                </Stack>
            </WelcomeWrapper>
            <ManageExpenses>
                <h3>აკონტროლე ხარჯები</h3>
                <Stack direction="row" spacing={0}>
                    <Box sx={{'@media(max-width:860px)':{display:'none'}}}>
                        <img src={expensesTimeColumn} alt="" />
                    </Box>
                    <div style={{ flex: 1 }}>
                        <Box className='info_box partners_info' sx={{'@media(max-width:860px)':{display:'none', padding:'20px 10px', '&::before':{display:'none'}, '.icon':{display:'none'}}}}>
                            
                            <div className='icon'>
                                <img src={expensesIllustration} alt="" />
                            </div>
                            <div>
                                <Stack className="time" direction="row" alignItems="center" spacing={1}>
                                    <AccessTimeIcon />
                                    <div>პარტნიორთან ვიზიდის დრო - ან შენ მიერ მითითებული დრო</div>
                                </Stack>
                                <div className='heading'>
                                    ჩვენი პარტნიორები დაგიმატებენ ხარჯებს და ასევე შენც შეგიძლია ჩანიშნო პირადად
                                </div>
                                <button onClick={() => navigate("/garage/add-car")}>
                                    <Stack className="p" direction="row" alignItems="center" spacing={1}>
                                        <div>ამისთვის საჭიროა მხოლოდ მანქანის დამატება</div>
                                        <EastIcon />
                                    </Stack>
                                </button>
                            </div>
                        </Box>
                        <Stack className='info_hint' direction="row" alignItems="center" spacing={2}>
                            <InsertCommentIcon />
                            <div>ჩანიშნე სასურველი მნიშვნელოვანი დეტალები 😎</div>
                        </Stack>
                        <Box className='info_box expenses_info' sx={{'@media(max-width:860px)':{display:'none', padding:'20px 10px', '&::before':{display:'none'}, '.icon':{display:'none'}}}}>
                            <div className='icon'>
                                <img src={expensesStatIllustration} alt="" />
                            </div>
                            <div style={{ flex: 1 }} >
                                <Stack direction="row" alignItems="space-between" spacing={1} sx={{'@media(max-width:860px)':{flexDirection:'column'}}}>
                                    <Stack justifyContent="center" spacing={1}>
                                        <div className='stat fuel'>საწვავი</div>
                                        <div className='stat chassis_parts'>სავალი ნაწილები</div>
                                        <div className='stat brakes'>ხუნდები</div>
                                        <div className='stat service'>სერვისი</div>
                                    </Stack>
                                    <Stack style={{ flex: 1 }} alignItems="flex-end" spacing={0}>
                                        <Stack className="time" direction="row" alignItems="center" spacing={1}>
                                            <AccessTimeIcon />
                                            <div>წლის მანძილზე</div>
                                        </Stack>
                                        <div className='heading'>
                                            გრაფიკული სტატისტიკა
                                        </div>
                                        <div className="p" style={{ maxWidth: "300px", textAlign: "right", color: "rgb(255 255 255 / 80%)" }}>
                                            გაჩვენებთ გრაფიკულ სტატისტიკას თუ რომელ კატეგორიაში გაქვთ ყველაზე მეტი დანახარჯი.
                                        </div>
                                    </Stack>
                                </Stack>
                            </div>
                        </Box>
                    </div>
                </Stack>
            </ManageExpenses>
        </>
    )
}

export default Welcome;

export const WelcomeWrapper = styled("div")`
    .hello {
        h1 {
            font-family: "FreeSansBold";
            font-size: 38px;
            color: #191919;
            font-weight: bold;
            line-height: 1.1;

            .name {
                color: #0070f4;
            }
        }
        h3 {
            font-family: "FreeSansBold";
            font-size: 24px;
            color: #191919;
            font-weight: bold;
            line-height: 1.2;
        }
        .subtitle {
            font-family: "FreeSansBold";
            font-size: 16px;
            color: #515660;
            line-height: 20px;
        }
    }
`;

export const ManageExpenses = styled("div")`
    margin-top: 50px;

    h3 {
        font-family: "FreeSansBold";
        font-size: 24px;
        color: #191919;
        font-weight: bold;
        margin-bottom: 40px;
    }

    .info_hint {
        width: 100%;
        height: 64px;
        margin: 14px 0px;
        padding: 0px 18px;
        box-sizing: border-box;
        background-color: #ecf6ff;
        border-radius: 12px;
        color: #0070f4;
        font-family: "FreeSansBold";

        svg {
            color: #1140e2;
        }
    }

    .info_box {
        width: 100%;
        min-height: 178px;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        box-sizing: border-box;
        padding-right: 20px;

        &:before {
            position: absolute;
            content: "";
            width: 8px;
            left: 10px;
            top: 14px;
            bottom: 14px;
            border-radius: 4px;
        }

        .icon {
            min-width: 230px;
            text-align: center;
        }

        .heading {
            font-size: 22px;
            font-family: "FreeSansBold";
            margin: 12px 0px;
        }

        .time {
            font-size: 14px;
            font-family: "FreeSansBold";
        }

        .p {
            font-size: 14px;
            font-family: "FreeSansBold";
        }
    }

    .partners_info {
        background-color: #f0eeff;
        &:before {
            background-color: #0070f4;
        }

        .time {
            color: #0070f4;
        }

        .heading {
            color: #0070f4;
            text-align: left;
        }

        .p {
            text-align: left;
        }
    }

    .expenses_info {
        background-color: #0070f4;
        &:before {
            background-color: #c9dbff;
        }

        .heading {
            color: white;
            text-align: right;
        }

        .time {
            color: white;
        }
    }

    .stat {
        display:flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        font-size: 16px;
        font-family: "FreeSansBold";
        color: white;

        &:before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 100%;
        }
        &.fuel {
            &:before {
                background-color: #c9dbff;
            }
        }
        &.chassis_parts {
            &:before {
                background-color: #fedb59;
            }
        }
        &.brakes {
            &:before {
                background-color: #e7e7e7;
            }
        }
        &.service {
            &:before {
                background-color: #8ec2fe;
            }
        }
    }
`