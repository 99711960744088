import { FC } from "react";
import Header from 'Components/Header/Header'
import Footer from 'Components/Footer/Footer'
import { AllPosts, FeaturedPost } from "Components/NewsComponents";
import styled from "@emotion/styled";


export const News: FC = () => {
    return (
        <>
            <Container >
                <FeaturedPost/> 
                <AllPosts/>
            </Container>
        </>
    )
}

export default News;


const Container = styled.div`

max-width:1440px;
margin:auto;
padding:77px 80px;
box-sizing:border-box;
display:grid;
grid-template-columns:2fr 1fr;
grid-gap:64px;

@media(max-width:1200px){
    grid-template-columns:1fr;
    padding:32px;
}
`