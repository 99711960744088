import React, { FC, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "Hooks/useTranslate";
import logo from "Assets/images/Logo-New.svg";

import {
  Container,
  HeaderLayout,
  HeaderLeftSide,
  NavStyled,
  Auth,
  Language,
  RightSide,
  DrawerContentStyled,
} from "./styles";
import { Box, Button, Drawer, Typography } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeIcon from "@mui/icons-material/Home";
import ErrorIcon from "@mui/icons-material/Error";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArticleIcon from "@mui/icons-material/Article";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SubscribtionModal from "../HomeComponents/Hero/SubscribtionModal";
import { BurgerIcon } from "Assets/images";

import { setLanguage } from "Redux/slices/i18nSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/store";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

const Header: FC = () => {
  const { translate } = useTranslate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <HeaderLayout>
        <HeaderLeftSide>
          <img
            className="burgerIcon"
            src={BurgerIcon}
            alt=""
            onClick={() => setOpenDrawer(true)}
          />
          <Box sx={{ width: "226px" }}>
            <Link to="/">
              <img src={logo} alt="AutoCircle" style={{height: 40}} />
            </Link>
          </Box>
          <Drawer open={openDrawer} onClose={(state) => setOpenDrawer(!state)}>
            <IconButton
              sx={{ position: "absolute", right: 10, top: 10 }}
              onClick={(state) => setOpenDrawer(!state)}
            >
              <CloseIcon />
            </IconButton>
            <DrawerContent />
          </Drawer>
        </HeaderLeftSide>
        <RightSide>
          <NavStyled>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/home"
            >
              {translate("Home")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/about-us"
            >
              {translate("About Us")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/prices"
            >
              {translate("Prices")}
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/offers"
            >
              {translate("Offers")}
            </NavLink>
            <NavLink
             // className={({ isActive }) => (isActive ? "active" : "")}
              to="/news"
              className="disabled" onClick={(e=>e.preventDefault())}
            >
              {translate("News")}
            </NavLink>
            <LanguageComponent />
          </NavStyled>
          <Auth>
            <Link to="/sign-up">
              <Button
                variant="contained"
                className="button_signup"
                sx={{ borderRadius: theme.button.borderRadius }}
              >
                {translate("Sign Up")}
              </Button>
            </Link>
            <Link to="/login">
              <Button
                variant="contained"
                className="button_signin"
                sx={{ borderRadius: theme.button.borderRadius }}
              >
                {translate("Log In")}
              </Button>
            </Link>
          {/*   <SubscribtionModal open={open} onHandleClose={handleClose} /> */}
          </Auth>
         
        </RightSide>
      </HeaderLayout>
    </Container>
  );
};

export default Header;

const LanguageComponent: React.FC = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (lang: string): void => {
    dispatch(setLanguage(lang));
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{minWidth:'0', padding:0}}>
        <Language>
          <LanguageIcon />
          <KeyboardArrowDownIcon />
        </Language>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={() => handleLanguageChange("GE")}>
          <ListItemIcon>
            <img
              width="32"
              src="https://www.worldometers.info/img/flags/small/tn_gg-flag.gif"
              alt=""
            />
          </ListItemIcon>
          <ListItemText>ქართული</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={() => handleLanguageChange("EN")}>
          <ListItemIcon>
            <img
              width="32"
              src="https://www.worldometers.info/img/flags/us-flag.gif"
              alt=""
            />
          </ListItemIcon>
          <ListItemText>English</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const DrawerContent: React.FC = () => {
  const { translate } = useTranslate();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DrawerContentStyled>
      <ul>
        <li>
          <NavLink to="/">
            <HomeIcon />
            {translate("Home")}
          </NavLink>
        </li>
        <li>
          <NavLink to="/about-us">
            <ErrorIcon />
            {translate("About Us")}
          </NavLink>
        </li>
        <li>
          <NavLink to="/prices">
            <MonetizationOnIcon />
            {translate("Prices")}
          </NavLink>
        </li>
        <li>
          <NavLink to="/offers">
            <LocalOfferIcon />
            {translate("Offers")}
          </NavLink>
        </li>
        <li>
          <NavLink to="/news" className="disabled" onClick={(e=>e.preventDefault())}>
            <ArticleIcon />
            {translate("News")}
          </NavLink>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/login">
            <LoginIcon />
            {translate("Log In")}
          </Link>
          {/* <Link to='#' onClick={handleClickOpen}>
            <LoginIcon />
            {translate("ავტორიზაცია")}
          </Link>
          <SubscribtionModal open={open} onHandleClose={handleClose} /> */}
        </li>
        <LanguageComponent />
      </ul>
    </DrawerContentStyled>
  );
};
