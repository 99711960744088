import { useTranslate } from "Hooks/useTranslate";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logOut } from "Redux/slices/authSlice";
import { RootState } from "Redux/store";

const MainRouteSwitch: FC = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const authState = useSelector((state: RootState) => state.auth);

    if (authState.userInfo.role != '' && authState.isLoggedIn == true) {
        if (authState.userInfo.role.toLowerCase() === 'admin') {
            dispatch(logOut());
            toast.error(translate("თქვენ მომხმარებელს არ აქვს ამ გვერდის ნახვის უფლება"));
            //console.log('userState.role.toLowerCase()', authState.userInfo.role.toLowerCase());
            // return <Navigate to="/admin" />
        };
        if (authState.userInfo.role.toLowerCase() === 'partner') {
            dispatch(logOut());
            toast.error(translate("თქვენ მომხმარებელს არ აქვს ამ გვერდის ნახვის უფლება"));
            //console.log('userState.role.toLowerCase()', authState.userInfo.role.toLowerCase());
            // return <Navigate to="/partner" />
        };
        if (authState.userInfo.role.toLowerCase() === 'user') {
            //console.log('userState.role.toLowerCase()', authState.userInfo.role.toLowerCase());
            return <Navigate to="/dashboard" />
        };
    }
    return <Navigate to="/home" />
}

export default MainRouteSwitch;