import React, { useEffect } from "react";
import { DialogTitle, DialogContentText, SubscribtionForm } from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  CostumPasswordField,
  CostumMobileNumberField,
} from "../Shared/CostumInputs";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { toast } from "react-toastify";

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { width } from "@mui/system";
import { useDispatch } from "react-redux";
import { logOut } from "Redux/slices/authSlice";

// ეს ფუნქციონალი დროებითია და მაგიტომ დავწერე ტიპი აქ ამოსაშლელია მერე
type SavePhoneNumber = {
  newPhoneNumber: string;
  password: string;
};

interface ISavePhoneNumberInput {
  newPhoneNumber: string;
  password: string;
}

const phoneRegex = RegExp(/^[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}$/);

export const InputSchema = yup
  .object({
    password: yup.string().required("გთხოვთს წორად ჩაწეროთ პაროლი"),
    newPhoneNumber: yup.string().required("გთხოვთ, მიუთითოთ ნომერი"),
  })
  .required();

const PhoneChangeModal: React.FC<{
  open: boolean;
  onHandleClose: () => void;
}> = ({ open, onHandleClose }) => {
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty },
  } = useForm<ISavePhoneNumberInput>({
    resolver: yupResolver(InputSchema),
  });

  const savePhoneNumber: SubmitHandler<ISavePhoneNumberInput> = (data) => {
    console.log(data);

    axiosPrivateInstance
      .post<SavePhoneNumber>("Account/ChangePhoneNumberAsync", {
        newPhoneNumber: data.newPhoneNumber.replace(/-/gi, ""),
        password: data.password,
      })
      .then((response) => {
        toast.success("თქვენ წარმატებით შეცვალეთ ნომერი");
        onHandleClose();
        dispatch(logOut());
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Dialog
      open={open}
      onClose={onHandleClose}
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={onHandleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>შეცვალე მობილურის ნომერი </DialogTitle>
      <DialogContent>
        <DialogContentText>
          გთხოვთ, შეიყვანოთ პაროლი და ახალი მობილურის ნომერი
        </DialogContentText>

        <form >
          <Stack sx={{ marginTop: "40px" }} spacing={4}>
            <CostumMobileNumberField
              name="newPhoneNumber"
              label={"მობილურის ნომერი"}
              control={control}
              InputLabelProps={{
                shrink: watch("newPhoneNumber") ? true : false,
              }}
            ></CostumMobileNumberField>

            <CostumPasswordField
              name="password"
              control={control}
              label={"პაროლი"}
              InputLabelProps={{
                shrink: watch("password") ? true : false,
              }}
            />

            <Button
              variant="contained"
              type="submit"
              sx={{ borderRadius: theme.button.borderRadius }}
              onClick={handleSubmit(savePhoneNumber)}
            >
              შეცვალე
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneChangeModal;
