import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface i18nState {
    language: string;
    loadingTranslations: boolean;
    errorLoadingTranslations: unknown;
}

const initialState: i18nState = {
    language: "EN",
    loadingTranslations: false,
    errorLoadingTranslations: null,
};

export const getTranslations = createAsyncThunk(
    "app/getTranslations",
    async () => {
        return axiosInstance
            .get<Models.TranslationModel[]>("Translation/GetAllAsync", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "bearerToken"
                    )}`,
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
        // return Promise.resolve(localTranslations);
    }
);

export const i18nSlice = createSlice({
    name: "lang",
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            localStorage.setItem("translationLanguage", action.payload);
            state.language = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTranslations.pending, (state) => {
            state.loadingTranslations = true;
            console.log("Updating Translations.");
        });
        builder.addCase(getTranslations.fulfilled, (state, action) => {
            localStorage.setItem(
                "translations",
                JSON.stringify(action.payload)
            );
            state.loadingTranslations = false;
            state.errorLoadingTranslations = "";
            console.log("Translations Up To Date.");
        });
        builder.addCase(getTranslations.rejected, (state, action) => {
            state.loadingTranslations = false;
            state.errorLoadingTranslations = action.error;
            console.log("Error Updating Translations.");
        });
    },
});

export const { setLanguage } = i18nSlice.actions;
export default i18nSlice.reducer;
