import { FC, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "Redux/store";
import styled from "@emotion/styled";

// styling
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import {
  SingleService,
  GridStyled,
  BigBlockStyled,
  OffersList,
  SmallBlockStyled,
  SingleNews,
} from "./styles";

import SingleOffer from "./SingleOffer";
import { Offer, PackageItem } from "./types";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Welcome from "./Welcome";
import GeorgianFlag from "Assets/images/georgianflag.png";
import Button from "@mui/material/Button";

const Dashboard: FC = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const isLoadingUser = useSelector(
    (state: RootState) => state.auth.loadingUser
  );
  const [showAllCurrentOffers, setShowAllCurrentOffers] = useState(false);

  return (
    <Grid item xs={12} md={8}>
      <Stack spacing={4}>
        {isLoadingUser == false ? (
          userInfo.packages.length > 0 ? (
            userInfo.packages.map((packageItem) => {
              return (
                <div style={{ marginBottom: "50px" }}>
                  <Stack direction="column" justifyContent="flex-start">
               
                      <Stack justifyContent={"space-between"} direction="row" sx={{width:'100%'}}>
                        <h3>{packageItem.title}</h3>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                setShowAllCurrentOffers((state) => !state)
                              }
                            >
                              {showAllCurrentOffers
                                ? "ნაკლების ნახვა"
                                : "ყველას ნახვა"}
                            </Button>
  
                      </Stack>
                      <CarPlate>
                        <div className="carPlateLeft">
                          <img className="flagDiv" src={GeorgianFlag} alt="flag" />
                          <div className="carPlateCountry">GE</div>
                        </div>
                        <div className="carPlateNumber">{packageItem.car.licensePlate.toUpperCase()}</div>
                      </CarPlate>
            

                  </Stack>
                  <OffersList>
                    {(function fun() {
                      if (!showAllCurrentOffers) {
                        return packageItem.offers.slice(0, 4);
                      } else {
                        return packageItem.offers;
                      }
                    })().map((offer: any) => {
                      return (
                        <SingleOffer
                          key={offer.offerId}
                          offer={offer}
                          packageItem={packageItem}
                        />
                      );
                    })}
                  </OffersList>
                </div>
              );
            })
          ) : (
            <Welcome />
          )
        ) : (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
        {userInfo.extraOffers.length > 0 && <>
          <Stack direction="row" justifyContent="space-between">
            <h3>დამატებითი შეთავაზებები</h3>
            <span className="gray-btn">ყველას ნახვა</span>
          </Stack>
          <OffersList>
            {userInfo.extraOffers.map((offer: any) => {
              return <SingleOffer key={offer.offerId} offer={offer} />;
            })}
          </OffersList>
        </>}
      </Stack>
    </Grid>
  );
};

export default Dashboard;



const CarPlate = styled.div`
  padding-right: 50px;
  margin: 15px 0px;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 2px 1px 4px rgb(82 130 255 / 15%);
  width:fit-content;
  .carPlateLeft {
    background-color: #191919;
    border-radius: 8px 0px 0px 8px;
    padding: 7px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .flagDiv {
    width: 15px;
    height: 10px;
    margin-top: 3px;
    border-radius: 2px;
  }
  .carPlateCountry {
    color: white;
    font-weight: 700;
    font-size: 12px;
  }
  .carPlateNumber {
    color: #191919;
    font-size: 20px;
    padding-left: 30px;
    font-family: "FreeSansBold";
  }
`