import { Box, Chip, Paper } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { DiscountChip, PackageChip } from "../chips";
import { useTranslate } from "Hooks/useTranslate";

type SingleOfferProps = {
  offer: any;
}

const SingleOffer: React.FC<SingleOfferProps> = ({ offer }) => {
  const { translate } = useTranslate();
  return (
    <Card>
      <div style={{ minWidth: "100px" }}>
        <img src={offer.imageUrl} alt={offer.title} />
      </div>
      <Stack spacing={1} sx={{ flex: 1 }}>
        <Typography
          component="h5"
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#000000",
          }}
        >
          {translate(offer.title)}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#6F6F7A",
          }}
        >
          {translate(offer.description)}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#6F6F7A",
          }}
        >
          {translate(offer.location)}
        </Typography>
      </Stack>
    </Card>
  );
};

export default SingleOffer;

const Card = styled(Paper)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;

  img {
    height: 80px;
  }
`;
