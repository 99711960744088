import * as React from 'react';
import styled from '@emotion/styled';
import StarIcon from '@mui/icons-material/Star';

export interface IPackageChipProps {
    name:string
}

 const PackageChip:React.FC<IPackageChipProps> = ({name}) => {
  return (
    <PackageChipStyled>
        <StarIcon/>
      {name}
    </PackageChipStyled>
  );
}
export default PackageChip;

const PackageChipStyled = styled.div`

height: 26px;
border-radius: 100px;
font-weight: 400;
font-size: 12px;
line-height: 9px;
color:white;
display:flex;
align-items: center;
justify-content: center;
color: #193A8D;
gap:7px;
border:1px solid #193A8D;
padding:0 10px;
svg {
    font-size:14px;
}
`