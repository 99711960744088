import React, { FC, useState, useEffect, useCallback } from "react";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import * as Models from "Models";
import { CarItem } from "./styles";
import GarageCarItemIcon from "Assets/images/CarTopViewHorizontal.png";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import GeorgianFlag from "Assets/images/georgianflag.png";

const SelectedCarForPayment: FC<{ carId: string; setActivateNextButton: (e: boolean) => void }> = ({ carId, setActivateNextButton }) => {
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const [car, setCar] = useState<any>();

  console.log(car);

  useEffect(() => {
    axiosPrivateInstance
      .get(`Garage/GetCarByIdAsync/${carId}`)
      .then((response) => {
        setCar(response.data);
        setActivateNextButton(true);
      })
      .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის წამოღებისას"));
  }, []);

  return (
    <div style={{ padding: "50px 0px" }}>
      {car ? (
        <CarItem key={car.uniqueId}>
          <div className="flexer">
            <div className="imgDiv">
              <img src={GarageCarItemIcon} alt={`${car.make} ${car.model}`} />
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <div className="carPlate">
                <div className="carPlateLeft">
                  <img className="flagDiv" src={GeorgianFlag} alt="flag" />
                  <div className="carPlateCountry">GE</div>
                </div>
                <div className="carPlateNumber">{car.licensePlate}</div>
              </div>
              <div>მწარმოებელი: {car.make}</div>
              <div style={{ padding: "15px 0px" }}>მოდელი: {car.model}</div>
            </div>
          </div>
        </CarItem>
      ) : (
        <>
          <Box sx={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        </>
      )}
    </div>
  );
};

export default SelectedCarForPayment;
