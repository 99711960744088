import { Box, Button } from "@mui/material";
import { axiosInstance } from "Axios/axios";
import { BoxIcon } from "Components/HomeComponents/WeOffer/styles";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Models from 'Models';

interface ISendSms {
    initialMinute?: number;
    initialSeconds?: number;
    phoneNumber: string;
  }
  
  const SendSms: FC<ISendSms> = (props) => {
    const { initialMinute = 2, initialSeconds = 0, phoneNumber } = props;
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const [buttonText, setButtonText] = useState("Get Code");
  
    const restartTimer = () => {
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
      setButtonText("Get New Code");
    };
  
    const [loading, setLoading] = useState(false);
  
    const handleSendSms = async () => {
      setLoading(true);
      await axiosInstance
        .get<Models.SignUpResModel>(`SmsService/SendOtp/${phoneNumber.replace(/-/gi, "")}`)
        .then((res) => {
          toast.success("სმს გაიგზავნა თქვენს ნომერზე");
          restartTimer();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    useEffect(() => {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    });
  
    return (
      <>
        <Box
          sx={{
            margin: "5px 0",
            cursor: minutes !== 0 || seconds !== 0 ? "not-allowed" : "pointer",
            width: "fit-content",
          }}
        >
          <Button
            variant="contained"
            sx={{ position: "relative", overflow: "hidden" }}
            onClick={handleSendSms}
            disableElevation={true}
            disabled={minutes !== 0 || seconds !== 0 || loading}
          >
            {loading ? <>Sending...</> : buttonText}
  
            <Box
              sx={{
                transition: 0.5,
                opacity: 0.5,
                width: `${
                  ((minutes * 60 + seconds) /
                    (initialMinute * 60 + initialSeconds)) *
                  100
                }%`,
                backgroundColor: "rgba(128,128,128,0.75)",
                position: "absolute",
                height: "100%",
                left: 0,
                top: 0,
                zIndex: 2312313,
              }}
            ></Box>
          </Button>
        </Box>
        {/* {minutes === 0 && seconds === 0 ? null : (
          <h1>
            {" "}
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </h1>
        )} */}
      </>
    );
  };

  
  export default SendSms;