import React from 'react'
import { Container } from './styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ChangePassword, UserEdit } from 'Components/ProfileComponents/SettingsComponents';
import { UserDelete } from 'Components/ProfileComponents/SettingsComponents';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import styled from '@emotion/styled';
import ScrollContainer from 'react-indiana-drag-scroll';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Settings: React.FC = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (<>
    <h3>პარამეტრები</h3>
    <br /><br />
    <Box sx={{ width: '100%' }}>
   
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <ScrollContainer>
        <Box sx={{width:'max-content'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <TabsStyled icon={<PersonIcon />} iconPosition="start" label="ინფორმაცია" {...a11yProps(0)} />
          <TabsStyled icon={<HttpsIcon />} iconPosition="start" label="პაროლი" {...a11yProps(1)} />
          <TabsStyled icon={<PersonOffIcon />} iconPosition="start" label="ანგარიშის წაშლა" {...a11yProps(2)} />
          {/* <Tab label="Item Four" {...a11yProps(3)} /> */}
        </Tabs>
        </Box>
        </ScrollContainer>
      </Box>
      
      <TabPanel value={value} index={0}>
        <UserEdit />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePassword />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserDelete />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        Item Four
      </TabPanel> */}
    </Box>
  </>
  )
}

export default Settings;

const TabsStyled = styled(Tab)` 
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.4px;
text-transform: uppercase;
color: rgba(0, 0, 0, 0.6);
min-height:20px;
  &.Mui-selected {
    color:#0070f4;
  }
`