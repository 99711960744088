import React, { FC, useState, useEffect, useCallback } from 'react'
import * as Models from "Models"
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

import Slider from "react-slick";
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';

import { SelectPackageContainer } from './styles';

type allPackagesWithSlicedOffers = {
    uniqueId: string,
    title: string,
    monthlyPrice: string;
    yearlyPrice: string;
    slicedOffers: { uniqueId: string, title: string }[][]
}


const SelectPackage: FC<{ onPackageIdSelect: (packageId: string) => void, selectedPackageId: string, onYearlyPayChange: (isYearlyPaySelected: boolean) => void, isYearlyPaySelected: boolean }> = ({ onPackageIdSelect, selectedPackageId, onYearlyPayChange, isYearlyPaySelected }) => {
    const axiosPrivateInstance = useAxiosPrivateInstance();

    const [allPackagesWithOffers, setAllPackagesWithOffers] = useState<Models.GetAllPackagesWithOffersResponse[]>();

    const [allPackagesWithSlicedOffers, setAllPackagesWithSlicedOffers] = useState<allPackagesWithSlicedOffers[]>();

    const handlePayTypeChange = () => {
        onYearlyPayChange(!isYearlyPaySelected);
    }

    const handlePackageSelect = (uniqueId: string) => {
        onPackageIdSelect(uniqueId);
    }

    const GetAllPackagesWithOffers = useCallback(async () => {
        axiosPrivateInstance.get<Models.GetAllPackagesWithOffersResponse[]>("Package/GetAllPackagesWithOffersAsync")
            .then((response) => setAllPackagesWithOffers(response.data))
            .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის მწარმოებლების წამოღებისას"))
    }, [axiosPrivateInstance, setAllPackagesWithOffers]);

    useEffect(() => {
        GetAllPackagesWithOffers();
    }, [])


    const sliceOffersForSlider = () => {
        const allPackagesWithSlicedOffers: allPackagesWithSlicedOffers[] = [] as allPackagesWithSlicedOffers[];

        if (allPackagesWithOffers) {
            for (let i = 0; i < allPackagesWithOffers.length; i++) {

                let newOffersSlice: { uniqueId: string, title: string }[][] = [];
                for (let j = 0; j < allPackagesWithOffers[i].offers.length; j += 4) {
                    newOffersSlice.push(allPackagesWithOffers[i].offers.slice(j, j + 4));
                }

                allPackagesWithSlicedOffers.push(
                    {
                        uniqueId: allPackagesWithOffers[i].uniqueId,
                        title: allPackagesWithOffers[i].title,
                        monthlyPrice: allPackagesWithOffers[i].monthlyPrice,
                        yearlyPrice: allPackagesWithOffers[i].yearlyPrice,
                        slicedOffers: newOffersSlice
                    }
                )
            }
        }
        return allPackagesWithSlicedOffers;
    }

    useEffect(() => {
        console.log(sliceOffersForSlider());
        setAllPackagesWithSlicedOffers(sliceOffersForSlider());
    }, [allPackagesWithOffers])

    useEffect(() => {
        console.log("allPackagesWithSlicedOffers", allPackagesWithSlicedOffers);
    }, [allPackagesWithSlicedOffers])

    return (
        <SelectPackageContainer>
            <div className="titleWithPayType">
                <div className="title">აირჩიეთ სასურველი პაკეტი</div>
                <div className="payType">
                    <div className="payTypeSwitch">
                        <div>თვიურად გადახდა</div>
                        <div><IOSSwitch size="medium" checked={isYearlyPaySelected} onChange={handlePayTypeChange} sx={{ m: 1 }} /></div>
                        <div>წლიურად გადახდა</div>
                    </div>
                    <div className="payTypeInfo">
                    </div>
                </div>
            </div>
            {!isYearlyPaySelected ?
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="info">
                        <AlertTitle>გაითვალისწინეთ</AlertTitle>
                        თქვენ არჩეული გაქვთ თანხის თვიურად გადახდა.<br />
                        თვიურად გადახდის შემთხვევაში თქვენი ბარათი შეინახება ჩვენ სისტემაში.<br />
                        ბართის წაშლა შესაძლებელია ნებისმიერ მომენტში, მაგრამ მანქანაზე მიბმული პაკეტები გაუქმდება.
                    </Alert>
                </Stack> : null
            }
            <div className="packageItems">
                {allPackagesWithSlicedOffers && allPackagesWithSlicedOffers.map((singlePackage) => {
                    return (
                        <div onClick={() => handlePackageSelect(singlePackage.uniqueId)} className={`packageItem ${singlePackage.uniqueId == selectedPackageId ? "active" : ""}`} key={singlePackage.uniqueId}>
                            <div className="packageItemHeader">
                                <div className="packageItemTitle">{singlePackage.title}</div>
                                <div className="packageItemPrice">₾ {isYearlyPaySelected ? singlePackage.yearlyPrice : singlePackage.monthlyPrice}</div>
                            </div>
                            <Slider {...SliderSettings}>
                                {singlePackage.slicedOffers.map((fourOffersArraySingleElement, index) => {
                                    return (
                                        <ul key={index}>
                                            {
                                                fourOffersArraySingleElement.map((offer) => {
                                                    return (
                                                        <li key={offer.uniqueId}>
                                                            <CheckIcon />
                                                            <div>{offer.title}</div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                })}
                            </Slider>
                        </div>
                    )
                })}
            </div>
        </SelectPackageContainer>
    );
}

const SliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    pauseOnHover: true,
    cssEase: "ease-out",
};

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 85,
    height: 44,
    padding: 0,
    transform: "scale(0.6)",
    "@media(max-width:550px)": {
        transform: "scale(0.6)",
    },
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 5,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(42px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#0A68FF",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 34,
        height: 34,
    },
    "& .MuiSwitch-track": {
        borderRadius: 42 / 2,
        backgroundColor: "#0A68FF",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));



export default SelectPackage;