import { FC } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { Container, Box } from "./styles";

import { Hero, Partners, WeOffer, Pricing, Faq, Cta } from "Components/HomeComponents";
import OurGoal from "Components/HomeComponents/OurGoal/OurGoal";

export const Home: FC = () => {
  return (
    <>
      <Container>
        <Header />
        <Hero />
        <Box/>
      </Container>
      <Partners />
      <WeOffer />
{/*       <OurGoal /> */}
      <Pricing />
      {/*       <Faq /> */}
      <Cta />
      <Footer />
    </>
  );
};

export default Home;
