import React from "react";
import SectionHeading from "../shared/SectionHeading";
import Slider from "react-slick";
import { Container, OfferBox, OfferBoxes, OffersLayout, BoxIcon, WeOfferCarouselContainer, WeOfferLogoContainer, Dot, Background } from "./styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useTranslate } from "Hooks/useTranslate";
import translationsSlice from "Redux/slices/translationsSlice";
import iconOffer1 from "Assets/images/offerIcon1.png";
import iconOffer2 from "Assets/images/offerIcon2.png";
import iconOffer3 from "Assets/images/offerIcon3.png";
import imageOffer1 from "Assets/images/bmw2.jpg";
import imageOffer2 from "Assets/images/offerIcon2.png";
import imageOffer3 from "Assets/images/offerIcon3.png";
import LogoNew from "Assets/images/Logo-New.svg"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const WeOffer: React.FC = () => {
  const { translate } = useTranslate();
  const ourOffers = [
    {
      h5: translate("Main advantages"),
      h3: translate("Why should you choose us?"),
    },
  ];
  return (
    <Container>
      <OffersLayout>
        <div className="offershead">
          <div>
            <div style={{ display: "flex" }}>
              <Dot />
              <h5 style={{ color: "#0070f4", fontSize: 16, fontWeight: 800, paddingLeft: 7 }}>{translate("Main advantages")}</h5>
              
            </div>
            <div style={{ paddingTop: 20 }}>
              <h3>
                Choose AutoCircle to <br /> reduce  your expenses.
              </h3>
            </div>
          </div>
          <div>
            <div className="offersline"></div>
          </div>
          <div>
            <p>
              AutoCircle is the perfect solution for car owners who want to save time and money <br />  while ensuring that their vehicle is well-maintained. Our platform offers a variety <br /> of services that are designed to make car ownership hassle-free and convenient.
            </p>
          </div>
        </div>
        <WeOfferCarousel />
      </OffersLayout>
      <Background />
    </Container>
  );
};

export default WeOffer;
const WeOfferCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    pauseOnHover: true,
  };
  const { translate } = useTranslate();
  const ourOffers = [
    {
      h3: "Discounts",
      h5: "Discounts and free services",
      image: imageOffer1,
      icon: LogoNew,
      id: 1,
    },
    {
      h3: "Recommendations",
      h5: "Autocircle Recommendations and guarantees",
      image: imageOffer1,
      icon: LogoNew,
      id: 2,
    },
    {
      h3: "Reduced Cost",
      h5: "15-20% reduced cost",
      image: imageOffer1,
      icon: LogoNew,
      id: 3,
    },
    {
      h3: "Vehicle Maintenance",
      h5: "Simplified vehicle maintenance and cost control",
      image: imageOffer1,
      icon: LogoNew,
      id: 4,
    },
    {
      h3: "Partners",
      h5: "Customer-focused partners",
      image: imageOffer1,
      icon: LogoNew,
      id: 5,
    },
  ];
  return (
    <WeOfferCarouselContainer>
      <Slider className="partners__slider" {...settings}>
        {ourOffers.map((el) => (
          <OfferBoxes>
            <OfferBox>
              <div className="OfferBoxImage">
                <img src={el.icon} alt="icon" />
              </div>
              <h3>{el.h3}</h3>
              <h5>{el.h5}</h5>
            </OfferBox>
          </OfferBoxes>
        ))}
      </Slider>
    </WeOfferCarouselContainer>
  );
};
