import { FC } from "react";
import styled from "@emotion/styled";
import CommuteIcon from '@mui/icons-material/Commute';

type OfferCount = {
  isUsageLimited: Boolean;
  useCount: number;
};
const UseCount: FC<OfferCount> = ({ isUsageLimited, useCount }) => {
  return (
    <UseCountStyled>
      <div className="title">გამოყენება</div>
      <div className="discountInfo">
        <CommuteIcon sx={{ color: "#3b8d19" }} />
        <span>
          {isUsageLimited === true ? `${useCount} ჯერ` : "ულიმიტო"}
        </span>
      </div>
    </UseCountStyled>
  );
};
export default UseCount;

const UseCountStyled = styled.div`
  .title {
    font-weight: bold;
    color: #808080;
    font-size: 12px;
    text-align: center;
  }
  .discountInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 6px;

    span {
      font-weight: bold;
      font-size: 16px;
      margin-left: 4px;
      color: #3b8d19;
    }
  }
`;