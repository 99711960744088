import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CreatePlanRow from "./PlansRow";
import { dataForDesktopPrices } from "./datas";
import { Box, Typography } from "@mui/material";
import * as Models from "Models";
import ReplayIcon from "@mui/icons-material/Replay";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ScrollContainer from "react-indiana-drag-scroll";

type PricesDesktopProps = {
  loading: boolean;
  data: Models.PackageModel[];
};

const PricesDesktop: React.FC<PricesDesktopProps> = ({ loading, data }) => {
  const transformData = () => {
    let transformedData = data
      .map((el) => el.offers)
      .flat()
      .filter((item, i, ar) => ar.indexOf(item) === i);
    const res = getDistinctById(transformedData);
    return res;
  };

  console.log(transformData());

  return (
    <ScrollContainer>
      <TableContainer component={Paper} sx={{ border: "none", boxShadow: "none", cursor: "grab" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <CreatePlanRow />
            {createRows(transformData(), data)}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollContainer>
  );
};
export default PricesDesktop;

function parseOffer(packetId: string, offer: any, packets: Models.PackageModel[]) {
  const findOffer = packets.find((packet) => packet.uniqueId == packetId)?.offers.find((item) => item.id === offer.id) as Models.PackageOfferModel;

  if (offer?.packageIds?.includes(packetId)) {
    // if(findOffer.isUsageLimited){
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
        <DiscountPercent percent={findOffer.discountPercent} />
        <Qty count={findOffer.useCount} />
        <CheckIcon sx={{ color: "#0070f4" }} />
      </Box>
    );
    // if(offer.discountPercent==0 || findOffer.discountPercent == 100){
    //   return  <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}><Qty count={findOffer.useCount}/>
    //   <CheckIcon sx={{ color: "#0070f4" }} />
    //   </Box>
    // }
    // return <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
    //   {findOffer.discountPercent ? <DiscountPercent percent={findOffer.discountPercent}/> : ''}
    //   <Qty count={findOffer.useCount}/>
    //   <CheckIcon sx={{ color: "#0070f4" }} />
    // </Box>
    //   return <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
    //       {(0 < findOffer.discountPercent && findOffer.discountPercent < 100) ? <DiscountPercent percent={findOffer.discountPercent}/> : <Typography sx={{color:"#0070f4", fontWeight:700, fontSize:'13px'}}>უფასოდ</Typography>}
    //       {(findOffer.useCount ? <Qty count={findOffer.useCount}/> : <Typography sx={{color:"#0070f4", fontWeight:700, fontSize:'13px'}}>ულიმიტოდ</Typography>)}
    //       <CheckIcon sx={{ color: "#0070f4" }} />
    //   </Box>
    // } else {
    //   return <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
    //       {(0 < findOffer.discountPercent && findOffer.discountPercent < 100) ? <DiscountPercent percent={findOffer.discountPercent}/> : <><Typography sx={{color:"#0070f4", fontWeight:700, fontSize:'13px'}}>უფასოდ</Typography><Typography sx={{color:"#0070f4", fontWeight:700, fontSize:'13px'}}>და</Typography></>}
    //       {(findOffer.useCount ? <Qty count={findOffer.useCount}/> : <Typography sx={{color:"#0070f4", fontWeight:700, fontSize:'13px'}}>ულიმიტოდ</Typography>)}
    //       <CheckIcon sx={{ color: "#0070f4" }} />
    //   </Box>
    // }
  } else {
    return <CloseIcon sx={{ color: "#F4724A" }} />;
  }
}

const createRows = (arr: IOffer[], packets: Models.PackageModel[]) => {
  console.log("filtered", arr);
  return arr.map((offer) => (
    <StyledTableRow key={offer.id}>
      <StyledTableCell component="th" scope="row" className="name" style={{ alignItems: "center", display: "flex" }}>
        <img src={offer.imageUrl} style={{ width: 35, paddingRight: 15, }} alt="" />
        {offer.title}
      </StyledTableCell>
      {packets
        ?.map((packet) => ({ id: packet.uniqueId }))
        .map((el) => (
          <StyledTableCell align="center">{parseOffer(el.id as string, offer, packets)}</StyledTableCell>
        ))}
    </StyledTableRow>
  ));
};

type PlanOffersProps = {};

interface IOffer {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  location: string;
  isFree: boolean;
  isAssignedToPackage: boolean;
  isApplicableToAssignToPackage: boolean;
  discountPercent: number;
  packageIds: string[];
}

function getDistinctById(arr: any): IOffer[] {
  const result: any = [];
  arr.map((el: any) => {
    const idies = result.map((el: any) => el.id);
    if (!idies.includes(el.id)) {
      result.push(el);
    }
  });
  return result;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.name": {
    width: "370px",
    border: "none",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
  },
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    // textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
    // textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F8FBFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//
interface IQty {
  count: number;
}

const Qty: FC<IQty> = ({ count }) => {
  return (
    <Box sx={{ color: "green", textAlign: "center" }}>
      <ReplayIcon />
      {count == 0 || count == 100 ? (
        <Typography sx={{ fontSize: 12 }}>ულიმიტოდ</Typography>
      ) : (
        <Typography sx={{ fontSize: 12 }}>{count} ჯერ</Typography>
      )}
    </Box>
  );
};

interface IDiscountPercent {
  percent: number;
}

const DiscountPercent: FC<IDiscountPercent> = ({ percent }) => {
  return (
    <Box sx={{ color: "#f9c23c", textAlign: "center" }}>
      <AutoAwesomeIcon />
      {percent == 0 || percent == 100 ? (
        <Typography sx={{ fontSize: 12 }}>უფასოდ</Typography>
      ) : (
        <Typography sx={{ fontSize: 12 }}>- {percent} %</Typography>
      )}
    </Box>
  );
};
