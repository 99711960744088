import React, { FC, useState, useEffect, useCallback } from 'react';
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { getCarById, clearCarDetails } from 'Redux/slices/carDetailsSlice';

import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import * as Models from "Models";
import { Stack } from "@mui/system";
import Button from "@mui/material/Button";

import UpdateIcon from '@mui/icons-material/Update';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import GarageCarItemIcon from "Assets/images/CarTopView.png";
import GeorgianFlag from "Assets/images/georgianflag.png";
import { Box } from '@mui/material';


const CarDetails: FC = () => {
  const { carId } = useParams();
  const dispatch = useDispatch<any>();
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const navigate = useNavigate();
  const carDetailState = useSelector((state: RootState) => state.car.carDetails);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);


  useEffect(() => {
    dispatch(getCarById(carId!));
    return () => {
      // Clean up car details redux state on component unmount
      dispatch(clearCarDetails);
    };
  }, [dispatch])

  const RemoveCarById = async () => {
    axiosPrivateInstance
      .get(`Garage/RemoveCarById/${carId}`)
      .then((response) => {
        console.log(response.data);
        navigate("/garage");
      })
      .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის წაშლისას"));
  };

  return (<>
    <h3>ავტომობილის მონაცემები</h3>
    <Car>
      <img src={GarageCarItemIcon} alt="" />
      <div className='carInfo'>
        <Stack direction="row" justifyContent="space-between" sx={{ '@media(max-width:600px)': { flexDirection: 'column' } }}>
          <div>
            <Box sx={{ width: 'fit-content' }}>
              <CarPlate>
                <div className="carPlateLeft">
                  <img className="flagDiv" src={GeorgianFlag} alt="flag" />
                  <div className="carPlateCountry">GE</div>
                </div>
                <div className="carPlateNumber">{carDetailState.licensePlate.toUpperCase()}</div>
              </CarPlate>
            </Box>
            <Box sx={{}}>
              <br />
              <div>მწარმოებელი: {carDetailState.make}</div>
              <br />
              <div>მოდელი: {carDetailState.model}</div>
            </Box>
          </div>
          <div>
            <Stack sx={{ height: "100%", '@media(max-width:600px)': { margin:'10px 0', gap:'10px', '& div a':{width:'100%', display:'inline-block'} }}} justifyContent="space-between">
              <div>
                <Link to={`/garage/add-car/${carDetailState.uniqueId}`} className="update-packet-link">
                  <Button
                    variant="contained"
                    startIcon={<UpdateIcon style={{ fontSize: "26px" }} />}
                    size="large"
                    sx={{width:'100%'}}
                  >
                    პაკეტის განახლება
                  </Button>
                </Link>
              </div>
              <div>
                <Button
                  fullWidth
                  onClick={RemoveCarById}
                  variant="outlined"
                  size="large"
                  startIcon={<DeleteForeverIcon />}
                >
                  მანქანის წაშლა
                </Button>
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </Car>
    <Outlet />
  </>
  );
};

export default CarDetails;

export const Car = styled.div`
  .carInfo {
    background: #fff;
    box-shadow: 0px 8px 21px rgb(82 130 255 / 15%);
    border-radius: 10px;
    padding: 16px;
  }
  img {
    max-width: 940px;
    width: 100%;
  }
`

const CarPlate = styled.div`
  padding-right: 50px;
  margin-bottom: 15px;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 8px 21px rgb(82 130 255 / 15%);

  .carPlateLeft {
    background-color: #191919;
    border-radius: 8px 0px 0px 8px;
    padding: 7px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .flagDiv {
    width: 15px;
    height: 10px;
    margin-top: 3px;
    border-radius: 2px;
  }
  .carPlateCountry {
    color: white;
    font-weight: 700;
    font-size: 12px;
  }
  .carPlateNumber {
    color: #191919;
    font-size: 20px;
    padding-left: 30px;
    font-family: "FreeSansBold";
  }
`