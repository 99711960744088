import { Box, Chip, Paper } from "@mui/material";
import { FC } from "react";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Offer, PackageItem } from "./types";

import { DiscountChip, PackageChip } from "Components/Generals/chips";
import UseCount from "./UseCount";

const SingleOffer: FC<{ offer: Offer; packageItem?: any }> = ({
  offer,
  packageItem,
}) => {
  return (
    <Card className={( offer.isUsageLimited == true && offer.useCount == 0 ) || ( offer.isUsageLimited == false && offer.useCount > 0 ) ? "disabled" : ""}>
      <img src={offer.imageUrl} alt="" />
      <Stack spacing={1} sx={{ flex: 1, width:'100%'  }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ width: "100%" }}
          spacing={2}
          
        >
          <DiscountChip persent={offer.discountPercent} />
          <UseCount
            isUsageLimited={offer.isUsageLimited}
            useCount={offer.useCount}
          />
        </Stack>
        <Typography
          component="h5"
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#000000",
          }}
        >
          {offer.title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#6F6F7A",
          }}
        >
          {offer.description}
        </Typography>
      </Stack>
    </Card>
  );
};

export default SingleOffer;

const Card = styled(Paper)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  flex: 1;
  &.disabled {
    opacity: 0.5;
  }

  img {
    width: 95px;
    height: 95px;
  }

  @media(max-width:500px){
    flex-direction:column;
  }
`;
