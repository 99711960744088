import styled from "@emotion/styled";
import { device } from "Components/common/device";
import { Button } from "@mui/material";

export const Container = styled("div")`
  max-width: 1440px;
  margin: auto;

`;

export const PricingLayout = styled("div")`

  margin-bottom: 10px;
  box-sizing: border-box;
  display:flex;  
  justify-content: space-between;

  
  p.pricing__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #869ab8;
    max-width: 520px;
    margin: auto;
    text-align: center;
  }
  @media ${device.laptop} {
    padding: 0 32px;
  }
`;

export const SwitcherContaner = styled.div`

`

export const Plans = styled.div`
  display: flex;
  justify-content: flex-end;;
  gap: 10px;
`;

export const PlanContainer = styled.div`
  max-width: 250px;
  margin:auto;
  /* width: 182px; */
  text-align: left;
  box-shadow: 0px 2px 12px rgba(20, 20, 43, 0.08);
  border-radius: 15px;
  border: 1px solid #eff0f7;
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  position:relative;
  .plan__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
    color: #869ab8;
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14.8547px;
    line-height: 16px;
    color: #161c2d;
    margin-bottom: 15px;
  }

  @media ${device.mobile} {
    padding: 15px 15px 20px 15px;
    margin:10px;
  }
  &.popular__plan{
    background-color:#0070f4;
    .plan__subtitle{
      color: #EFF0F7;
    } 
    h5, h6 {
      color: #FFFFFF;
    }


    .plan__text{
      color: #D9DBE9;
    }

    .plan__carousel-li {
      color: #D9DBE9;
      svg{
        background-color:white;
        color:#0070f4;
      }
    }

    .price__monethly span .num{
      color:white
    }

    .learn__more-btn{
      color:#0070f4;
      background-color:white;
    }
  }
`;


export const PopularBadge = styled.div`
  padding:8px;
  background: #EEF4FF;
  border-radius: 6.30934px;
  font-style: normal;
  font-weight: 400;
  font-size: 8.83308px;
  line-height: 9px;
  color: #0070f4;
  margin-bottom:15px;
  width:fit-content;
`

export const Price = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  gap:10px;
  width:200px;
  
    img {
        height:18px;
    }
  & .price__monethly .num {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #161c2d;
  }

  .price__monethly {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #d9dbe9;
    div {
        display:flex;
        flex-wrap:nowrap;
        align-items: center;
        gap:5px;
        white-space: nowrap;
    }

  }
`;

export const Included = styled.div`
  margin-bottom: 11px;
  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 12px;
  }
`;

export const PlanBtn = styled(Button)`
  font-family: inherit;
  padding: 13.5px;
  width: 100%;
  border-radius: 10px;
  box-shadow:none;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  @media ${device.mobile} {
    padding: 12px 15px;
    font-size: 11.6057px;
    line-height: 17px;
  }
`;

export const Carousel = styled("div")`
 
  padding-bottom: 40px;
  width: 100%;

  .slick-dots li button:before{
    font-size:6px;
  }
`;

export const Slide = styled("div")`
  box-sizing: border-box;
  padding: 2px;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }
  ul li {
    display: flex;
    align-items: center;
    gap: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #161c2d;
    svg {
      background: #0070f4;
      padding: 1px;
      border-radius: 50%;
      color: white;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      
    }
  }
`;
