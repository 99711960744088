import React, { FC, useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";

type ExpenseItemMobileProps = {
    categoryId: number;
}

const ExpenseItemMobile: FC<ExpenseItemMobileProps> = ({ categoryId }) => {

    const expenses = useSelector((state: RootState) => state.car.carDetails.expenses).filter(car => car.categoryId == categoryId);

    return (
        <Timeline position="alternate">
            {expenses && expenses.length > 0 ?
                expenses.map(
                    (expense) => {
                        return (
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: "auto 0" }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <div>{expense.date}</div>
                                    <div style={{ fontWeight: "bold" }}>{expense.amount} ₾</div>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color="primary" variant="outlined">
                                        {/* <img
                                                width="40"
                                                src={expense.partnerId.length > 0 ? expense.partnerAvatar : ""}
                                                alt=""
                                            /> */}
                                        <AccountCircleIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: "12px", px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        {expense.company}
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {expense.data.map(
                                            (expenseData) => <div>{`${expenseData.key}: ${expenseData.value}`}</div>
                                        )
                                        }
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    }
                )
                :
                <div style={{
                    width: "200px",
                    margin: "0 auto",
                    textAlign: "center",
                    fontWeight: "bold",
                    lineHeight:'22px'
                }}>
                    მოცემულ კატეგორიაში თქვენ არ გაქვთ ხარჯები
                </div>
            }
        </Timeline>
    );
}

export default ExpenseItemMobile;