import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

export const GarageContent = styled.div`
    width: 100%;

    h4 {
        font-family: "FreeSansBold";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 18px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #191919;
        margin-bottom: 44px;
    }

    .headerTitleAddNewCar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 44px;
        flex-wrap: wrap;
        gap: 20px 10px;
        h4 {
            margin-bottom: 0px;
        }

        a {
            text-decoration: none;
        }
    }
    .addCarButton {
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        background-color: #191919;
        color: white;
    }
    .addCarButton:hover {
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        background-color: #0070f4;
        color: white;
    }
`;

export const Container = styled(Paper)`
    box-shadow: none;
    border-radius: 24px;
    width: 100%;
    box-sizing: border-box;

    .carsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 20px;
        align-items: flex-start;
    }
`;

export const CarItem = styled.div`
    width: 388px;
    background: #fff;
    box-shadow: 0px 8px 21px rgba(82, 130, 255, 0.15);
    border-radius: 10px;
    padding: 16px;
    box-sizing:border-box;

    @media (max-width:600px){
        width:100%;
    }

    &.disabled {
        background: #dbe1ed;
    }

    a,
    .flexer {
        text-decoration: none;
        font-family: "FreeSans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #191919;
        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .flexer {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .imgDiv {
        width: 90px;
        height: 180px;
    }

    img {
        width: inherit;
        height: inherit;
        object-fit: contain;
        @media (max-width: 600px) {
            flex-direction: column;
            transform: rotate(90deg);
        }
    }
    .carPlate {
        margin-bottom: 15px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px 8px 21px rgba(82, 130, 255, 0.15);
    }
    .flagDiv {
        width: 15px;
        height: 10px;
        margin-top: 3px;
        border-radius: 2px;
    }
    .carPlateLeft {
        background-color: #191919;
        border-radius: 8px 0px 0px 8px;
        padding: 7px;
        align-items: center;
        height: 30px;
        justify-content: space-between;
    }
    .carPlateNumber {
        color: #191919;
        font-size: 20px;
        font-family: "FreeSansBold";
    }
    .carPlateLeft,
    .carPlateRight {
        width: 17px;
    }
    .carPlateCountry {
        color: white;
        font-weight: 700;
        font-size: 12px;
    }

    .packageChip {
        width: 70%;
        height: 25px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 9px;
        color: white;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        color: #191919;
        gap: 7px;
        border: 1px solid #191919;
        padding: 5px 20px;
        font-family: "FreeSansBold";
    }
    .buttonBuy {
        background-color: #0070f4;
        color: white;
        padding: 10px 15px;
        border-radius: 8px;
        font-size: 14px;
        font-family: "FreeSansBold";
    }
`;

export const SelectPackageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 0px;

    .titleWithPayType {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 24px;

        .title {
            display: flex;
            flex-direction: column;
            flex: 1;
            font-family: "FreeSans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            font-weight: bold;
        }

        .payType {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;

            .payTypeSwitch {
                display: flex;
                flex-direction: row;
                align-items: center;
                div{
                    text-align: center;
                }
            }

            .payTypeInfo {
                width: 100%;
                padding-top: 20px;
            }

            .MuiSwitch-root {
                margin: -8px 0px;
            }
        }

        @media (max-width:600px){

                flex-direction:column;
                gap:30px;
            
        }


    }

    .packageItems {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-top: 30px;
        gap:20px;
        @media (max-width:900px){
            flex-direction:column;
            align-items: center
        }
    }

    .packageItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 198px;
        height: 222px;
        background: #ffffff;
        border: 0.458528px solid #eff0f7;
        box-shadow: 0px 0.917056px 5.50234px rgba(20, 20, 43, 0.08);
        border-radius: 12px;
        padding: 30px 18px;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
            background: #0070f4;
            border: 0.458528px solid #eff0f7;

            .packageItemHeader {
                .packageItemTitle {
                    color: white;
                }
                .packageItemPrice {
                    color: white;
                }
            }

            li {
                color: white;
                svg {
                    background: white;
                    color: #0070f4;
                }
            }
        }

        .packageItemHeader {
            .packageItemTitle {
                font-family: "Helvetica";
                font-style: normal;
                font-weight: 700;
                font-size: 14.6729px;
                line-height: 16px;
                color: #161c2d;
                padding-bottom: 12px;
            }
            .packageItemPrice {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 24.7605px;
                line-height: 30px;
                color: #161c2d;
                padding-bottom: 12px;
            }
        }

        ul {
            box-sizing: border-box;
            padding: 2px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 9px;
        }

        li {
            display: flex;
            align-items: center;
            gap: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #161c2d;

            svg {
                background: #0070f4;
                padding: 1px;
                border-radius: 50%;
                color: white;
                width: 12px;
                height: 12px;
                box-sizing: border-box;
            }

            div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
`;

export const GridStyled = styled.div`
    width: 100%;
    .gray-btn {
        background: #f3f7ff;
        border-radius: 16px;
        padding: 10px 7px;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #193a8d;
        cursor: pointer;
    }
`;
export const BigBlockStyled = styled(Paper)`
    overflow-x: auto;
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    .bigBlock__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;
        padding: 0 16px;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0070f4;
        }
    }
    div {
        display: flex;

        flex-direction: row;
    }
`;
export const CarData = styled(Paper)`
    width: 388px;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 24px 20px;
    box-shadow: 0px 4px 16px rgba(50, 111, 230, 0.15);
    border-radius: 16px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #193a8d;
    color: white;

    /* * {
        margin: 4px 0px;
    } */

    div {
        color: inherit;
        font-weight: bold;
    }
`;

export const ExpenseCategories = styled.div``;
