import styled from "@emotion/styled";
import { device } from "Components/common/device";

export const Container = styled("div")`
  background-color: #eef4ff;
`;

export const Faqlayout = styled("div")`
  max-width: 1440px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 64px 70px;

  .faq__heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #0070f4;
    text-align: center;
    margin-bottom: 24px;
  }

  .faq__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #869ab8;
    max-width: 520px;
    margin: auto;
    margin-bottom: 48px;
  }

  @media ${device.laptop} {
    padding: 32px;
  }
`;
export const AccordionWrapper = styled("div")`
  max-width: 786px;
  margin: auto;
  width:100%;
  .faq__question {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #161c2d;
    margin-right: 10px;
  }

  .faq__answer {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #6f6f7a;
  }
  .MuiPaper-root {
    border-radius: 8px !important;
  }
  .MuiAccordionSummary-root {
    margin: 0 !important;
  }
  .MuiAccordionSummary-gutters {
    padding: 0 24px;
  }

  .MuiAccordionSummary-content .Mui-expanded {
    margin: 18px 0;
  }

  .MuiAccordionDetails-root {
    padding: 4px 24px 18px 24px;
  }
`;
