import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  /* flex-wrap: wrap; */
  padding: 24px 70px;
  box-sizing: border-box;
  @media (max-width: 1100px) {
    justify-content: center;
    padding: 24px 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: #10002e;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;

    background: #ffffff;
    border: 1px solid #d5d4dc;
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.2px;
    color: #8b849b;
  }

  .error-msg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

export const LeftContainer = styled.div`
  align-items: center;
  justify-content: center;

  img {
    max-width: 650px;
    border-radius: 20px;
    margin: 0 auto;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;
export const RightContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-right: 100px;

  h2 {
    font-style: normal;
    font-size: 3.25rem;
    line-height: 1.25;
    font-weight: 900;
    letter-spacing: -0.02em;
    text-align: center;
    text-transform: capitalize;
    color: #191919;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  form {
    max-width: 25rem;
    padding: 20px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;

    .forget-password {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #0070f4;
      text-decoration: none;
    }
    .submit-btn {
      padding: 19.5px 24px;
      background: #0070f4;
      border-radius: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
      }
      &:active {
        opacity: 0.9;
      }
    }
    .register-btn {
      border-radius: 8px;
      width: 100%;
      height: 56px;
      color: white;
      background: #191919;
      font-size: medium;
      border-radius: 10px;
      padding: 20px 30px 20px 30px;
      text-transform: none;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      outline: none;
      cursor: pointer;
      box-sizing: border-box;
    }

    .signUp {
      font-style: normal;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #869ab8;
      a {
        color: #0070f4;
      }
    }
  }

  @media (max-width: 1300px) {
    padding-right: 0px;
  }
`;
