const size = {
    mobile: '500px',
    laptop: '1024px',
    desktop: '2560px'
  }

  export const device = {
    mobile: `(max-width: ${size.mobile})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(max-width: ${size.desktop})`
  };