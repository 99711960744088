import React, { FC, useRef, useEffect, useState } from 'react'
import styled from "@emotion/styled";
import ExpenseItem from './ExpenseItem';
import { motion } from "framer-motion"

type ExpenseCategoryItemProps = {
    expenceCategory: any;
    reverse: boolean;
    color: string;
    nextColor: string;
    firstChild: boolean;
    lastChild?: boolean;
}

type ExpensesStyledProps = {
    reverse: boolean;
    spaceFillerHeight: number;
}

type ExpenseCategoryStyledProps = {
    lineWidth: number;
    categoryIconWidth: number;
    reverse: boolean;
    color: string;
    nextColor: string;
    firstChild: boolean;
    lastChild?: boolean;
}

const ExpenseCategoryItem: FC<ExpenseCategoryItemProps> = ({ expenceCategory, reverse, color, nextColor, firstChild, lastChild }) => {
    const [spaceFillerHeight, setSpaceFillerHeight] = useState(0);
    const lineWidth = 180;
    const categoryIconWidth = 80;
    const expenseCategoryWidth = lineWidth * 2 + categoryIconWidth;

    return (
        <Expenses reverse={reverse} spaceFillerHeight={spaceFillerHeight}>
            <div className='expensesAndCategory'>
                <ExpenseItem
                    categoryId={expenceCategory.id}
                    reverse={reverse}
                    expenseCategoryWidth={expenseCategoryWidth}
                    setSpaceFillerHeight={setSpaceFillerHeight}
                />
                <ExpenseCategory
                    lineWidth={lineWidth}
                    categoryIconWidth={categoryIconWidth}
                    reverse={reverse}
                    color={color}
                    nextColor={nextColor}
                    firstChild={firstChild}
                    lastChild={lastChild}
                >
                    <div className='upperLine'></div>
                    <div>
                        <div className="title">{expenceCategory.title}</div>
                        <div className='imgContent'>
                            <div className='leftLine'></div>
                            <div className='img'>
                                <div className='bg'>
                                    <img src={expenceCategory.imageUrl} alt={expenceCategory.title} />
                                </div>
                            </div>
                            <div className='rightLine'></div>
                        </div>
                    </div>
                    <div className='downLine'></div>
                </ExpenseCategory>
            </div>
            <div className='spaceFiller'></div>
        </Expenses>
    )
}

export default ExpenseCategoryItem;

const Expenses = styled.div<ExpensesStyledProps>`
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "column" : "column-reverse")};
    flex: 0;

    .expensesAndCategory {
        display: flex;
        flex-direction: ${({ reverse }) => (reverse ? "column" : "column-reverse")};
    }

    .spaceFiller {
        height:  ${({ spaceFillerHeight }) => spaceFillerHeight}px;
        display: flex;
        flex-direction: ${({ reverse }) => (reverse ? "column" : "column-reverse")};
    }
`;

const ExpenseCategory = styled.div<ExpenseCategoryStyledProps>`
    display: flex;
    flex-direction: column;
    height: 416px;
    align-items: center;
    position: relative;

    .title {
        position: absolute;
        ${({ reverse }) => (reverse ? "top: 20px;" : "bottom: 20px;")}
        right: 50%;
        left: 50%;
        margin-left: 20px;
        width: 150px;
        font-size: 18px;
        font-weight: bold;
        z-index: 2;
        border-bottom: 4px solid ${({ color }) => color};
        box-sizing: border-box;
        padding: 0px 4px 10px;
        text-align: left;
    }

    .upperLine, .downLine {
        position: relative;
        width: 4px;
        height: 100%;
    }

    .upperLine{
        background-color: ${({ color, reverse }) => reverse ? color : "transparent"};

        &:before {
            position: absolute;
            background-color: ${({ color, reverse }) => reverse ? color : "transparent"};
            content: "";
            width: 12px;
            height: 12px;
            top: 0;
            right: 50%;
            left: 50%;
            margin-left: -6px;
            border-radius: 100%;
        }
    }
    .downLine {
        background-color: ${({ color, reverse }) => !reverse ? color : "transparent"};

        &:after {
            position: absolute;
            background-color: ${({ color, reverse }) => !reverse ? color : "transparent"};
            content: "";
            width: 12px;
            height: 12px;
            bottom: 0;
            right: 50%;
            left: 50%;
            margin-left: -6px;
            border-radius: 100%;
        }
    }

    .imgContent {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .leftLine, .rightLine {
        display: ${({ reverse }) => (reverse ? "block" : "none")};
        width: ${({ lineWidth }) => lineWidth}px;
        height: 4px;
    }

    .leftLine {
        ${({ firstChild, color }) => (
        firstChild ?
            `background-image: linear-gradient(to right, transparent 60%, ${color} 90%)`
            :
            `background-color: ${color}`
    )};
    }

    .rightLine {
        background-color: ${({ nextColor, lastChild }) => lastChild ? "transparent" : nextColor};
    }

    .img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ categoryIconWidth }) => categoryIconWidth}px;
        height: ${({ categoryIconWidth }) => categoryIconWidth}px;

        .bg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            height: 70%;
            box-sizing: border-box;
            border-radius: 100%;
            background-color: ${({ color }) => color};
            background-image: linear-gradient(to bottom, rgb(255 255 255 / 20%), transparent);
            border-radius: 100%;

            img {
                width: 60%;
                max-height: 60%;
            }
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 3;
            top: -4px;
            left: -4px;
            right: -4px;
            bottom: -4px;
            border-radius: 100%;
            transform: rotate(${({ reverse }) => (reverse ? "-45deg" : "-135deg")});
            border-style: solid;
            border-width: 4px;
            border-color: #fff0 ${({ color }) => color} ${({ color }) => color} ${({ color }) => color};
        }
    }
`;