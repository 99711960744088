import { FC } from "react";
import Header from 'Components/Header/Header'
import Footer from 'Components/Footer/Footer'

export const Forbidden: FC = () => {
    return (
        <>
            <Header />
            <div className="container">
                You have no permission to view this page
            </div>
            <Footer />
        </>
    )
}

export default Forbidden;