import { FC } from "react";
import * as Models from "Models";
import * as Schemas from "Schemas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, LeftContainer, RightContainer } from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "Hooks/useTranslate";
import { useSignUp } from "Hooks/useSignUp";
import { Box, Button, FormControl, useTheme } from "@mui/material";
import CostumTextInput from "Components/Generals/costumInputs/CostumTextInput";
import CostumCheckbox from "Components/Generals/costumInputs/CostumCheckbox";
import SendSms from "./SendSms";
import CostumMaskedInput from "Components/Generals/costumInputs/CostumMaskedInput";
import HeroImage from "Assets/images/carserviceimage.jpg";

export const SignUp: FC = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { loading, serverErrors, signUpAsync } = useSignUp();
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Models.SignUpModel>({
    resolver: yupResolver(Schemas.SignUpSchema),
  });

  const onSubmit = async (data: Models.SignUpModel) => {
    const signupdata = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/-/gi, ""),
      role: "user",
    };
    signUpAsync(signupdata);
    console.log(signupdata);
    // navigate('/mobile-verification')
  };
  console.log(serverErrors, 1);

  return (
    <>
      <Container>
        <RightContainer>
          <h2>Create Account. We Exist To <br />Make Life Easier.</h2>
          {serverErrors.length !== 0 && (
            <ul className="server__errors">
              {serverErrors?.map((er, ind) => (
                <li key={ind}>{er}</li>
              ))}
            </ul>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <CostumTextInput label={translate("სახელი")} name={"firstName"} control={control} />
            <CostumTextInput label={translate("გვარი")} name={"lastName"} control={control} />
            <CostumMaskedInput label={translate("მობილურის ნომერი")} name={"phoneNumber"} control={control} mask={"[5]00-00-00-00"} />
            <CostumMaskedInput label={translate("პირადი ნომერი")} name={"personalNumber"} control={control} mask={"00000000000"} />
            <CostumTextInput label={translate("პაროლი")} name={"password"} control={control} type={"password"} />
            <CostumTextInput label={translate("გაიმეორეთ პაროლი")} name={"confirmPsw"} control={control} type={"password"} />
            <FormControl sx={{ position: "relative" }}>
              <CostumMaskedInput label={translate("sms კოდი")} name={"otp"} control={control} mask={"000000"} />
              <Box sx={{ width: "fit-content", position: "absolute", right: 8, top: 4 }}>
                <SendSms phoneNumber={watch("phoneNumber")} />
              </Box>
            </FormControl>
            <CostumCheckbox label={translate("ვეთანხმები წესებს და პირობებს")} name={"terms"} control={control} />

            <Button
              disableElevation={true}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              sx={{ borderRadius: theme.button.borderRadius, padding: "16px" }}
              disabled={!!loading}
            >
              {translate("რეგისტრაცია")}
            </Button>

            <div className="signUp">
              <span>{translate("Already Have an account??")} </span>
              <Link to="/login">{translate("Login Now")}</Link>
            </div>
          </form>
        </RightContainer>
      </Container>
    </>
  );
};

export default SignUp;
