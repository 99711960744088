import React, { FC, useState, useEffect } from 'react'
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";
import * as Models from "Models";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask"
import { Box } from '@mui/material';

type CarMakeModel = {
    id: number,
    title: string,
}

type CarMake = {
    id: number,
    title: string,
    models: CarMakeModel[],
};

type AddCarRequest = {
    makeId: number,
    modelId: number,
    licensePlate: string,
};


const AddNewCar: FC<{ onNewlyAddedCar: (response: Models.AddCarResponse) => void }> = ({ onNewlyAddedCar }) => {
    const axiosPrivateInstance = useAxiosPrivateInstance();

    const [carMakes, setCarMakes] = useState<CarMake[]>();

    const [selectedCarMakeModels, setSelectedCarMakeModels] = useState<CarMake[]>();
    const [disableModelsSelect, setDisableModelsSelect] = useState<boolean>(true);

    const GetCarsMakeList = async () => {
        axiosPrivateInstance.get("Dealership/GetMakeListAsync")
            .then((response) => setCarMakes(response.data))
            .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის მწარმოებლების წამოღებისას"))
    }

    const AddCarAsync = async ({ makeId, modelId, licensePlate }: AddCarRequest) => {
        axiosPrivateInstance.post<any>("Garage/AddCarAsync", {
            makeId,
            modelId,
            licensePlate,
            vin: "",
        })
            .then((response) => {
                toast.success("მანქანა წარმატებით დაემატა");
                onNewlyAddedCar(response.data);
            })
            .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის დამატებისას"))
    }

    const handleFormSubmit: SubmitHandler<AddCarRequest> = (data) => {
        console.log(data);
        AddCarAsync(data);
    }

    useEffect(() => {
        GetCarsMakeList();
    }, [])

    useEffect(() => {
        console.log(carMakes);
        console.log(disableModelsSelect);
    }, [carMakes])

    const licensePlateRegex = RegExp(
        /^[a-zA-Z]{2}-[0-9]{3}-[a-zA-Z]{2}$/
    );

    const InputSchema = yup.object({
        licensePlate: yup.string().required("გთხოვთ, მიუთითოთ სახელმწიფო ნომერი").matches(licensePlateRegex, "არასწორი სახელმწიფო ნომერი"),
    });

    const { register, handleSubmit, watch, reset, formState: { errors }, control, setValue } = useForm({
        defaultValues: {
            makeId: 0,
            modelId: 0,
            licensePlate: "",
        },
        resolver: yupResolver(InputSchema),
    });
    const selectedMakeId = watch("makeId");

    console.log(errors);

    // const GetModelListByMakeId = async (makeId: number) => {
    //   axiosPrivateInstance.get(`Dealership/GetModelListByMakeId/${makeId}`)
    //     .then((response) => setCarMakes(response.data))
    //     .catch((error) => toast.error("დაფიქსირდა პრობლემა მანქანის მწარმოებლების წამოღებისას"))
    // }

    useEffect(() => {
        console.log(selectedMakeId);
        if (selectedMakeId && selectedMakeId == 0) {
            setDisableModelsSelect(true);
            reset();
        }
        if (selectedMakeId && selectedMakeId != 0 && carMakes && carMakes != null && carMakes.filter((carMake) => carMake.id == selectedMakeId)[0].models.length == 0) {
            setDisableModelsSelect(true);
        } else {
            setDisableModelsSelect(false);
        }
        return;
    }, [selectedMakeId, carMakes, setDisableModelsSelect, reset, setValue])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} style={{ padding: "50px 0px", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "20px", gap: "12px", '@media(max-width:600px)':{flexDirection:'column'} }}>
                <Controller
                    control={control}
                    name="makeId"
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth error={errors.modelId ? true : false}>
                                <InputLabel id="demo-simple-select-label">მანქანის მწარმოებელი</InputLabel>
                                <Select
                                    label="მანქანის მწარმოებელი"
                                    {...field}
                                    MenuProps={{ style: { maxHeight: "300px" } }}
                                >
                                    <MenuItem value="0">
                                        <em>არცერთი</em>
                                    </MenuItem>
                                    {carMakes && carMakes.map((carMake) => {
                                        return (
                                            <MenuItem key={carMake.id} value={carMake.id}>{carMake.title}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>)
                    }} />
                <Controller
                    control={control}
                    name="modelId"
                    render={({ field }) => {
                        return (
                            <FormControl fullWidth error={errors.modelId ? true : false} disabled={watch("makeId") == 0 ? true : false}>
                                <InputLabel id="demo-simple-select-label">მოდელი</InputLabel>
                                <Select
                                    label="მოდელი"
                                    {...field}
                                >
                                    {watch("makeId") == 0 ? null :
                                        !disableModelsSelect && carMakes && carMakes.filter((carMake) => carMake.id == selectedMakeId)[0].models.map((selectedCarMakeModel: CarMakeModel) => {
                                            return (
                                                <MenuItem key={selectedCarMakeModel.id} value={selectedCarMakeModel.id}>{selectedCarMakeModel.title}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }} />
                <Controller
                    control={control}
                    name="licensePlate"
                    render={({ field }) => {
                        return (
                            <InputMask
                                {...field}
                                mask="aa-999-aa"
                                alwaysShowMask={true}>
                                <TextField
                                    fullWidth
                                    label="სახელმწიფო ნომერი"
                                    variant="outlined"
                                    error={errors.licensePlate ? true : false}
                                    {...(errors.licensePlate ? { helperText: "მანქანის ნომერის ფორმატი უნდა იყოს AA-999-AA" } : {})} />
                            </InputMask>
                        )
                    }} />
            </Box>
            <div>
                <Button type="submit" variant="contained" size="large">მანქანის დამატება</Button>
            </div>
        </form>
    );
}

export default AddNewCar;