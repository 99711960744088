import { AuthState } from "../../Redux/slices/authSlice";
import Requirements from "./Requirements";
import Roles from "./Roles";

const CheckRequirements = (
    AuthState: AuthState,
    requirements: Requirements[]
) => {
    let isAllow = true;

    for (let requirement of requirements) {
        // REMINDER: Enums are LOWERCASE!
        if (requirement === Requirements.MustBeAdmin) {
            isAllow = AuthState.userInfo.role.toLowerCase() === Roles.Admin;
            if (!isAllow) {
                break;
            }
        } else if (requirement === Requirements.MustBePartner) {
            isAllow = AuthState.userInfo.role.toLowerCase() === Roles.Partner;
            if (!isAllow) {
                break;
            }
        } else if (requirement === Requirements.MustBeBlogger) {
            isAllow = AuthState.userInfo.role.toLowerCase() === Roles.Blogger;
            if (!isAllow) {
                break;
            }
        } else if (requirement === Requirements.MustBeUser) {
            isAllow = AuthState.userInfo.role.toLowerCase() === Roles.User;
            if (!isAllow) {
                break;
            }
        }
    }

    return isAllow;
};

export default CheckRequirements;
