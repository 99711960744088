import React, { useEffect, useState } from 'react';
import SingleOffer from './SingleOffer';
import { Box, Chip, Paper } from '@mui/material';

import styled from '@emotion/styled';
import { OfferPhoto } from 'Assets/images';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import Grid from '@mui/material/Grid';
import { axiosInstance } from 'Axios/axios';

type OffersGridProps = {

};

const OffersGrid: React.FC<OffersGridProps> = () => {
        const [offers, setOffers] = useState<any>();
        const GetOffers = () => {
                axiosInstance.get<any>("Public/Offers/GetOffersAsync")
                        .then((response) => {
                                setOffers(response.data);
                        })
                        .catch(error => {
                                console.log(error);
                        })
        }

        useEffect(() => {
                GetOffers();
        }, [])

        return <Grid container sx={{ marginTop: '33px' }} spacing={2}>
                {
                        offers && offers.map((offer: any) => <Grid item xs={12} md={6} xl={4} lg={6}><SingleOffer offer={offer} /></Grid>)
                }
        </Grid>
}
export default OffersGrid;