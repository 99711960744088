import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "Redux/store";
import CheckRequirements from "../Requirements/CheckRequirements";
import Requirements from "../Requirements/Requirements";

const ProtectedRoute: FC<{ component?: FC, jsxElement?: JSX.Element, requirements?: Requirements[] }> = (props) => {

    const authState = useSelector((state: RootState) => state.auth);
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    if (isLoggedIn) {
        if (props.requirements) {
            if (!CheckRequirements(authState, props.requirements)) {
                return <Navigate to="/forbidden" />
            }
        }

        if (props.component) { return <props.component /> }
        if (props.jsxElement) { return <>{props.jsxElement}</> }
    }

    return <Navigate to="/" />
}

export default ProtectedRoute;