import { Box } from '@mui/system';
import { Background, Container, Sidebar, RightSidebar } from 'Components/UserPages'
import React, { FC } from 'react'
import styled from "@emotion/styled";

interface IProps {
  component: JSX.Element;
  showRightSideBar?: boolean;
}

export const ProfileContainer: FC<IProps> = ({ component, showRightSideBar }) => {
  return (
    <Background>
      <Wrapper>
        <Sidebar />
        <Container>
          {component}
        </Container>
        {showRightSideBar && <RightSidebar />}
      </Wrapper>
    </Background>
  )
}

export default ProfileContainer;

const Wrapper = styled.div`
    max-width: 1600px;
    background-color: rgb(255, 255, 255, 50%);
    display: flex;
    flex-direction: row;
    padding: 30px 20px;
    margin: 0px auto;
    box-sizing:border-box;
    border-radius: 42px;
    
    @media(max-width:800px){
      border-radius:0;
      padding:20px 16px;
    }
`