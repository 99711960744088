import React, { FC, useEffect, useState } from "react";
import { Container, SingleCardStyled, AddCard } from "./styles";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import VisaIcon from "Assets/images/visa_icon.png";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { Button, Paper, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { RootState } from "Redux/store";
import { deleteCreditCard, getCreditCards } from "Redux/slices/creditCardSlice";

interface ICard {
  expiracy: string;
  label: null | string;
  number: string;
  uniqueId: string;
}

function formCardNumbers(value: string) {
  const matches = value.match(/.{1,4}/g);
  if (!matches) {
    return value;
  }
  return matches.join(" ").trim();
}

function formExpNumbers(value: string) {
  const matches = value.match(/.{1,2}/g);
  if (!matches) {
    return value;
  }
  return matches.join("/").trim();
}

const PaymentMethods: FC = () => {
  const dispatch = useDispatch<any>();
  const creditCards = useSelector(
    (state: RootState) => state.creditCard.allCreditCards
  );
  const [iFrameUrl, setIframeUrl] = useState("");

  useEffect(() => {
    dispatch(getCreditCards());
  }, []);

  return (
    <>
      {!!iFrameUrl ? (
        <iframe
          src={iFrameUrl}
          width="900"
          height="1000"
          title="Example Website"
        ></iframe>
      ) : (
        <>
          <h3>მიბმული ბარათები</h3>
          <br /><br />
          <Grid container spacing={2}>
            {creditCards?.map((item) => (
              <Grid item xs={12} md={6} lg={4} key={item.uniqueId}>
                <SingleCard {...item} />
              </Grid>
            ))}
            <Grid item xs={12} md={6} lg={4}>
              <AddCardComponent addIframe={setIframeUrl} />
            </Grid>
          </Grid>
          <br /><br />
          <h3>ტრანზაქციები</h3>
          <br /><br />
          ტრანზაქციების ისტორია ვერ მოიძებნა
        </>
      )}
    </>
  );
};

export default PaymentMethods;

const SingleCard: React.FC<ICard> = (props) => {
  const [openEditing, setOpenEditing] = useState(false);
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [label, setLabel] = useState(props.label);

  return (
    <SingleCardStyled>
      <Typography
        sx={{
          textAlign: "right",
          width: "100%",
          color: "white",
          userSelect: "none",
        }}
      >
        {label}
      </Typography>
      <div className="cardButtons">
        <EditIcon color="secondary" onClick={() => setOpenEditing(true)} />
        <DeleteIcon color="error" onClick={() => setOpenDeleteCard(true)} />
      </div>
      {openEditing && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: "white",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditCardLabel
            label={label}
            uniqueId={props.uniqueId}
            cancelEditing={setOpenEditing}
            setLabel={setLabel}
          />
        </Box>
      )}
      {openDeleteCard && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: "white",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeleteCard
            uniqueId={props.uniqueId}
            cancelDeleting={setOpenDeleteCard}
          />
        </Box>
      )}
      <span className="cardNumber">{formCardNumbers(props.number)}</span>
      <div className="cardExpirationDateAndType">
        <span>{formExpNumbers(props.expiracy)}</span>
        <img src={VisaIcon} alt="Master Card" />
      </div>
    </SingleCardStyled>
  );
};

const AddCardComponent: FC<{ addIframe: (e: string) => void }> = ({
  addIframe,
}) => {
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const [loading, setLoading] = useState(false);

  const addCardAsync = async () => {
    setLoading(true);
    await axiosPrivateInstance
      .post("Payment/CreatePaymentUrlAsync", {
        packageId: "",
        carId: "",
        saveCard: true,
      })
      .then((res) => addIframe(res.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <AddCard onClick={() => addCardAsync()}>
      <Box
        sx={{
          width: "50px",
          height: "50px",
          margin: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <AddCircleIcon sx={{ fontSize: "50px", cursor: "pointer" }} />
        )}
      </Box>
      <div>ბარათის დამატება</div>
    </AddCard>
  );
};

interface FormData {
  label: string;
}

const EditCardLabel: FC<{
  label?: string | null;
  uniqueId?: string;
  cancelEditing: (e: boolean) => void;
  setLabel: (e: string) => void;
}> = ({ label, uniqueId, cancelEditing, setLabel }) => {
  const [loading, setLoading] = useState(false);

  const axiosInstance = useAxiosPrivateInstance();
  const handleEdit = async (label: string) => {
    setLoading(true);
    await axiosInstance
      .post("Account/EditCreditCardByIdAsync", { uniqueId, label })
      .then((res) => {
        setLabel(res.data.label);
        cancelEditing(false);
        toast.success("ბარათის სახელი შეცვლილია");
      })
      .catch((err) => toast.error(err.toString()))
      .finally(() => setLoading(false));
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { label: label ? label : "" } });

  const onSubmit = handleSubmit((data) => {
    handleEdit(data.label);
    console.log(data);
  });

  const handleCancel = () => {
    reset({ label: "" });
    cancelEditing(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="label"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            placeholder="ბარათის სახელი"
            label="სახელი"
          />
        )}
      />
      <div>
        <Stack direction="row" spacing={2} justifyContent="center" mt={1}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={!!loading || watch("label") === label}
          >
            შენახვა
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            color="error"
            disabled={!!loading}
          >
            გასვლა
          </Button>
        </Stack>
      </div>
    </form>
  );
};

const DeleteCard: FC<{
  uniqueId: string;
  cancelDeleting: (e: boolean) => void;
}> = ({ uniqueId, cancelDeleting }) => {
  const [loading, setLoading] = useState(false);
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const dispatch = useDispatch<any>();

  const sendRequest = async (uniqueId?: string) => {
    setLoading(true);
    axiosPrivateInstance
      .delete(`Account/DeleteCreditCardAsync?creditCardId=${uniqueId}`)
      .then((response) => {
        toast.success("ბარათი წარმატებით წაიშალა");
        dispatch(deleteCreditCard(uniqueId));
      })
      .catch((error) => {
        toast.error("დაფიქსირდა შეცდომა");
      });
  };

  const handleClose = () => {
    cancelDeleting(false);
  };

  const deleteCardHandler = () => {
    sendRequest(uniqueId);
  };

  return (
    <div>
      <p>ნამდვილად გსურთ ბარათის წაშლა</p>
      <Stack direction="row" spacing={2} justifyContent="center" mt={1}>
        <Button variant="contained" color="success" onClick={deleteCardHandler}>
          დიახ
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          color="error"
          disabled={loading}
        >
          არა
        </Button>
      </Stack>
    </div>
  );
};
