import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from 'Axios/axios';
import * as Models from './Models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { logIn, logOut, getUser } from 'Redux/slices/authSlice';
import { getTranslations, setLanguage } from 'Redux/slices/i18nSlice';

import Routing from 'Routing';

import './Assets/css/reset.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CircularProgress from '@mui/material/CircularProgress';


function App(): JSX.Element | null {
  const dispatch: any = useDispatch();
  const loadingUser = useSelector((state: RootState) => state.auth.loadingUser);
  const loadingTranslations = useSelector((state: RootState) => state.lang.loadingTranslations);
  const [isAppReadyToLoad, setIsAppReadyToLoad] = useState(false);

  const getTranslationLastUpdateDate = async () => {
    return await axiosInstance.get<string>("Translation/GetLastUpdateDateAsync")
      .then((response) => {
        return response.data;
      })
      .catch(error => {
        return error;
      })
  }

  useEffect(() => {
    if (localStorage.getItem('translationLanguage')) {
      dispatch(setLanguage(localStorage.getItem('translationLanguage')));
    }

    const getTranslationLUD = async () => {
      let translationLastUpdateDate = await getTranslationLastUpdateDate();
      console.log("LocalStorage Translation Last Update Date", translationLastUpdateDate);

      if (localStorage.getItem('translationLastUpdateDate')) {
        if (localStorage.getItem('translationLastUpdateDate') === translationLastUpdateDate) {
          console.log("Translations are up to date.")
          return;
        }
      }

      localStorage.setItem("translationLastUpdateDate", translationLastUpdateDate);
      dispatch(getTranslations());
    }
    getTranslationLUD();

    if (localStorage.getItem('refreshToken')) {
      axiosInstance.post<Models.LoginResponse>("Account/RefreshToken", {
        refreshToken: localStorage.getItem('refreshToken'),
      })
        .then((response) => {
          dispatch(logIn(response.data));
          dispatch(getUser());
          setIsAppReadyToLoad(true);
        })
        .catch(error => {
          if (error.response.data && error.response.data.indexOf("Microsoft.IdentityModel.Tokens.SecurityTokenExpiredException") != -1) {
            /*   console.log("Refresh Token is Expired", error); */
            dispatch(logOut());
          } else {
            console.log(error);
          }
          setIsAppReadyToLoad(true);
        })
    } else {
      setIsAppReadyToLoad(true);
    }
  }, [dispatch])

  return isAppReadyToLoad === true && loadingUser === false && loadingTranslations == false ?
    <Routing />
    :
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh",
    }}>
      <CircularProgress />
    </div>
}

export default App;