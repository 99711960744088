import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { device } from "Components/common/device";
import { Button } from "@mui/material";

export const Container = styled("div")`
  width: 100%;
  margin: auto;
  overflow:hidden;
  position: relative;
`;
export const Background = styled("div")`
  width: 100%;
  background: #191919;
  border-radius: 4px;
  height: 35%;
  bottom: 0px;
  z-index: -1;
  position: absolute;
`;

export const OffersLayout = styled("div")`
  max-width: 1298px;
  margin: auto;
  padding: 40px 40px;
  
  .offershead h3{
    color: #000E29;
    font-size: 55px;
  }
  .offershead p{
    color: #747474;
    font-size: 15px;
    line-height: 2;
  }

  .offershead{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .offersline{
    width: 4px;
    background-color: #0070f4;
    height: 90px;
  }

  @media ${device.laptop} {
    padding: 24px 32px;
    .offershead{
      display: block;
      justify-content: space-between;
      align-items: center;
    }
    .offersline{
      display: none;
    }
    .offershead h3{
      color: #000E29;
      font-size: 30px;
    }
    .offershead p{
      padding-top: 20px;
    }
    
  }
  
`;

export const Dot = styled("div")`
width: 12px;
height: 12px;
border-radius: 100px;
background: #0070f4;
`;

export const OfferBoxes = styled("div")`
  padding-top: 30px;
`;

export const OfferBox = styled(Paper)`
  gap: 64px;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 400px;
  background-color: #FFFFFF;
  box-shadow: 1px -1px 20px -9px rgb(224,224,224);
  border-radius: 24px;
  padding: 32px;
  text-align: center;
  h5 {
    font-style: normal;
    font-weight: 400;
    font-family: "FreeSans";
    font-size: 16px;
    line-height: 27px;
    color: #747474;
    margin-bottom: 8px;
    padding-top: 20px;
  }
  h3{
    font-style: normal;
    font-weight: 600;
    font-family: "FreeSans";
    font-size: 28px;
    line-height: 30px;
    color: #000E29;
  }
  @media ${device.mobile} {
    width: 308px;
    height: 67%;
    h5 {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .OfferBoxImage{
    display: flex;
    padding: 20px;
    justify-content: center;
  }
  .OfferBoxImage img{
    width: 50px;
  }

`;

export const BoxIcon = styled(CheckIcon)`
  color: #0070f4;
  margin-bottom: 8px;
  font-size: 42px;
`;

export const WeOfferLayout = styled("div")`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  color: white;
  padding: 50px 70px;
  box-sizing: border-box;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 16px;
    color: #0070f4;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    max-width: 862px;
    margin: auto;
    text-align: center;
    color: #161c2d;
  }
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 36px;
    padding: 32px;
  }
`;

export const WeOfferBtn = styled(Button)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 29px;
  gap: 16px;
  margin: auto;
  font-family: inherit;
  box-shadow: none;
  svg {
    font-size: 20px;
  }
  @media ${device.mobile} {
    font-size: 16px !important;
    line-height: 20px;
    padding: 18px 24px;
  }
`;

export const WeOfferCarouselContainer = styled("div")`
  margin: 64px 0 64px 0;
  .partners__slider {
    width: 1300px;
  }
`;

export const WeOfferLogoContainer = styled("div")``;
