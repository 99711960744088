import styled from "@emotion/styled"

export const AllPostsLayout = styled.div`

`

export const HeadingStyled = styled.div`

display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:32px;
h3{
    font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 48px;
letter-spacing: -2px;
color: #232536;
}

a{
    text-decoration:none;
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #592EA9;
}
`

export const SinglePostStyled = styled.div`
box-sizing:border-box;
padding:26px 32px;
cursor:pointer;
&:hover{
    background: #EEF4FF;
}
    .post__author{
        font-size:14px;
     line-height:20px;
     margin-bottom:16px;
     margin-bottom:8px;
     .highlited{
         color:#0070f4
     }
    }

    h5{
        font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
color: #232536;
    }
`

