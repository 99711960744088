import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";
import { useDispatch } from "react-redux";
interface ICard {
  expiracy: string;
  label: null | string;
  number: string;
  uniqueId: string;
}
export interface CreditCardState {
  loading: boolean;
  allCreditCards: ICard[];
  error: unknown;
}

const initialState: CreditCardState = {
  loading: false,
  allCreditCards: [],
  error: null,
};

export const getCreditCards = createAsyncThunk(
  "Payment/getCreditCardAsync",
  async () => {
    return axiosInstance
      .get("Account/GetUserCreditCardsAsync", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
        },
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
);

export const creditCardSlice = createSlice({
  name: "creditCard",
  initialState,
  reducers: {
    deleteCreditCard: (state, action) => {
      const updatedCreditCards = state.allCreditCards.filter(
        (el) => el.uniqueId !== action.payload
      );

      state.allCreditCards = updatedCreditCards;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCreditCards.fulfilled, (state, action) => {
      state.loading = false;
      state.allCreditCards = action.payload;
      state.error = "";
    });
    builder.addCase(getCreditCards.rejected, (state, action) => {
      state.loading = false;
      state.allCreditCards = [];
      state.error = action.error!.message!;
    });
  },
});

export const { deleteCreditCard } = creditCardSlice.actions;
export default creditCardSlice.reducer;
