import React, { useEffect } from "react";
import {
  DialogTitle,
  DialogContentText,
  SubscribtionForm,
} from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Button, TextField, FormControl, InputLabel, Input, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { axiosInstance } from 'Axios/axios';
import { toast } from "react-toastify";

import InputMask from "react-input-mask"

import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// ეს ფუნქციონალი დროებითია და მაგიტომ დავწერე ტიპი აქ ამოსაშლელია მერე
type SavePhoneNumber = {
  phoneNumber: string,
  isPartner: boolean,
  name: string,
}

interface ISavePhoneNumberInput {
  phoneNumber: string,
  name: string,
}

const phoneRegex = RegExp(
  /^[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}$/
);

export const InputSchema = yup.object({
  phoneNumber: yup.string().required("გთხოვთ, მიუთითოთ ნომერი").matches(phoneRegex, "არასწორი მობილურის ნომერი"),
});

const SubscribtionModal: React.FC<{
  open: boolean;
  onHandleClose: () => void;
}> = ({ open, onHandleClose }) => {

  const theme = useTheme();

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ISavePhoneNumberInput>({
    resolver: yupResolver(InputSchema),
  });

  const savePhoneNumber: SubmitHandler<ISavePhoneNumberInput> = (data) => {
  
    axiosInstance.post<SavePhoneNumber>("Public/SavePhoneAsync", {
      phoneNumber: data.phoneNumber.replace(/-/gi, ""),
      isPartner: false,
    })
      .then((response) => {
        toast.success("თქვენ შეუერთდით მოლოდინის სიას");
        onHandleClose();
        console.log(response);
      })
      .catch(error => {
        toast.error(error.message);
        console.log(error);
      })
  }

  useEffect(() => {
    console.log(errors)
  }, [errors])

  return (
    <Dialog
      open={open}
      onClose={onHandleClose}
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <IconButton
        aria-label="close"
        onClick={onHandleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>შეუერთდი მოლოდინის სიას </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ჩვენი აპლიკაცია მალე ხელმისაწვდომი იქნება AppStore-სა და Google
          Play-ზე.
          <br /> გთხოვთ, შეიყვანოთ თქვენი მობილურის ნომერი, რათა შეგატყობინოთ,
          როდის გამოქვეყნდება
        </DialogContentText>
        <SubscribtionForm>
          <form onSubmit={handleSubmit(savePhoneNumber)}>
            <div style={{ padding: "40px 0px", display: "flex", flexDirection: "column" }}>
              <FormControl variant="standard">
                <InputLabel error={errors.phoneNumber ? true : false} htmlFor="phoneNumber">
                  {errors.phoneNumber ? errors.phoneNumber.message : "მობილურის ნომერი"}
                </InputLabel>
                <Controller
                  name="phoneNumber"
                  render={({ field }) => {
                    return (
                      <InputMask
                        {...field}
                        mask="599-99-99-99">
                        <Input error={errors.phoneNumber ? true : false} />
                      </InputMask>
                    )
                  }}
                  control={control} />
                <FormHelperText id="component-helper-text">
                  +995 საქართველო
                </FormHelperText>
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{ borderRadius: theme.button.borderRadius }}
              >
                შემატყობინეთ
              </Button>
            </div>
          </form>
        </SubscribtionForm>
      </DialogContent>
    </Dialog >
  );
};

export default SubscribtionModal;
