import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';


interface Props {
  label: string;
  name: string;
  control: any;
  type?: string;
  [key: string]: any;

}

const CostumTextInput: React.FC<Props> = ({ label, name, control, type, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField 
        onChange={onChange} 
        label={label} 
        error={!!error}
        helperText={error?.message} 
        type={type ? type : 'text'}
        value={value}
        {...rest} />
      )}
    />
  );
};

export default CostumTextInput;