import React from 'react'
import { Container, SingleOffer, OfferBody } from './styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Stack, Avatar } from '@mui/material';
import { OffersList } from 'Components/ProfileComponents/OfffersComponents';

const UserOffers: React.FC = () => {
  return (
    <Container>
      <div className="container__heading">
        <h4>მიმდინარე შეთავაზებები</h4>
      </div>
     
     <OffersList/>

    </Container>
  )
}

export default UserOffers