import React from "react";
import {
  PartnersLayout,
  Container,
  PartnersBtn,
  PartnersCarouselContainer,
  PartnerLogoContainer,
} from "./styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SectionHeading from "../shared/SectionHeading";

import partnerLogo from "../../../Assets/images/partnerLogo.svg";
import Slider from "react-slick";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useTranslate } from "Hooks/useTranslate";
import SubscribtionModalPartner from "./SubscribtionModalPartner";
import PartnerOne from "../../../Assets/images/partner1.png";
import PartnerTwo from "../../../Assets/images/partner2.png";
import PartnerThree from "../../../Assets/images/partner3.png";
import PartnerFour from "../../../Assets/images/partner4.png";
import PartnerFive from "../../../Assets/images/partner5.png";
import PartnerSix from "../../../Assets/images/partner6.png";

const data = [
  {
    id: 1,
    logo: PartnerOne,
  },
  {
    id: 2,
    logo: PartnerTwo,
  },
  {
    id: 3,
    logo: PartnerThree,
  },
  {
    id: 4,
    logo: PartnerFour,
  },
  {
    id: 5,
    logo: PartnerFive,
  },
  {
    id: 6,
    logo: PartnerSix,
  },
]


const Partners: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { translate } = useTranslate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
    <PartnersLayout>
     {/*  <SectionHeading
        h5={translate("პარტნიორები")}
        h3="Exclusive Partners"
      /> */}
      <PartnersCarousel />
      {/* <Box
        sx={{
          height: "60px",
        }}
      ></Box>
      <PartnersBtn
        variant="contained"
        onClick={handleClickOpen}
        endIcon={<ArrowForwardIosIcon />}
        sx={{ borderRadius: theme.button.borderRadius }}
      >
        {translate("გახდი ჩვენი პარტნიორი")}
      </PartnersBtn> */}
     {/*  <SubscribtionModalPartner open={open} onHandleClose={handleClose} /> */}
    </PartnersLayout>
  </Container>
  );
};

export default Partners;

const ImageContainer = styled("div")({
  display: "flex !important",
  justifyContent: "center",
  width: "293px",
  height: "100px",
  lineHeight: "100px",

  "& a": {
    display: "inline-block",
    height: "100px",
    lineHeight: "100px",
  },

  "& img": {
    display: "inline-block",
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
  },
});

const PartnersCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 2000,
    swipeToSlide: false,
    autoplaySpeed: 500,
    slidesToShow: 6,
    pauseOnHover: false,
    slidesToScroll: 1,
    responsive: [
       {
         breakpoint: 1400,
         settings: {
             slidesToShow: 5,
         }
     },
         {
             breakpoint: 991,
             settings: {
                 slidesToShow: 3,
             }
         },
         {
             breakpoint: 700,
             settings: {
                 slidesToShow: 2,
             }
         },
         {
             breakpoint: 500,
             settings: {
                 slidesToShow: 2,
             }
         },
     ]
  };

  return (
    <PartnersCarouselContainer>
      <Slider className="partners__slider" {...settings}>
        {data.map((item) => (
          <PartnerLogoContainer key={item.id}>
            <img src={item.logo}  className="logo_images" alt="brand" />
          </PartnerLogoContainer>
        ))}
      </Slider>
    </PartnersCarouselContainer>
  );
};
