import React, { FC, useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "Redux/store";
import { Box, Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { useDeleteExpense } from "./hooks";
import * as Models from 'Models';

type ExpenseItemProps = {
    categoryId: number;
    reverse: boolean;
    expenseCategoryWidth: number;
    setSpaceFillerHeight?: (height: number) => void
}

const ExpenseItem: FC<ExpenseItemProps> = ({ categoryId, reverse, expenseCategoryWidth, setSpaceFillerHeight }) => {
    const expenseItemRef: any = useRef(null);
    const [expenseItemHeight, setExpenseItemHeight] = useState(0);

    const expenses = useSelector((state: RootState) => state.car.carDetails.expenses).filter(car => car.categoryId == categoryId);

    useEffect(() => {
        setExpenseItemHeight(expenseItemRef.current.clientHeight);
        setSpaceFillerHeight && setSpaceFillerHeight(expenseItemRef.current.clientHeight)
    })



    return (
        <div style={{
            position: "relative",
            height: `${expenseItemHeight}px`,
        }}>
            <div style={!reverse ? {
                width: `${expenseCategoryWidth}px`,
                position: "absolute",
                left: "50%",
                right: "50%",
                marginLeft: `-${expenseCategoryWidth / 2}px`
            } : {}
            }>
                <Timeline ref={expenseItemRef} position="alternate">
                    {expenses && expenses.length > 0 ?
                        expenses.map(
                            (expense) => {
                                return (
                                    <SingleExpense expense={expense}/>
                                );
                            }
                        )
                        :
                        <div style={{
                            width: "200px",
                            margin: "0 auto",
                            textAlign: "center",
                            fontWeight: "bold",
                            paddingTop: !reverse ? "20px" : "0px",
                            paddingBottom: reverse ? "20px" : "0px",
                        }}>
                            მოცემულ კატეგორიაში თქვენ არ გაქვთ ხარჯები
                        </div>
                    }
                </Timeline>
            </div>
        </div>
    );
}

export default ExpenseItem;

interface ISingleExpense{
    expense:Models.ExpenseModel
}

const SingleExpense:FC<ISingleExpense> = ({expense}) => {
    // delete
    const [expenseDeleting, deleteExpenseAsync] = useDeleteExpense();
    const handleDelete = (id:string) => {
      deleteExpenseAsync(id)
    }
    //  edit
    const navigate = useNavigate();
    const handleEdit = () => {
        navigate(`edit-expense/${expense.uniqueId}`)
    }

    return <TimelineItem>
                                        <TimelineOppositeContent
                                            sx={{ m: "auto 0" }}
                                            align="right"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            <div>{expense.date}</div>
                                            <div style={{ fontWeight: "bold" }}>{expense.amount} ₾</div>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color="primary" variant="outlined" sx={{position:'relative'}}>
                                                {/* <img
                                                width="40"
                                                src={expense.partnerId.length > 0 ? expense.partnerAvatar : ""}
                                                alt=""
                                            /> */}
                                                <AccountCircleIcon />
                                               {!expense.partnerId && <Box sx={{ position: "absolute", display:'flex', right:-14, top:28, zIndex:123 }}>
                                                    <IconButton aria-label="delete" onClick={()=>handleDelete(expense.uniqueId)} disabled={!!expenseDeleting} >
                                                       {!expenseDeleting ? <DeleteIcon sx={{fontSize:15}}/> :
                                                        <CircularProgress size={"15px"}/>}
                                                    </IconButton>
                                                    <IconButton aria-label="edit"  onClick={handleEdit}>
                                                        <EditIcon sx={{fontSize:15}}/>
                                                    </IconButton>
                                                </Box>}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                                            <Typography variant="h6" component="span">
                                                {expense.company}
                                            </Typography>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {expense.data.map(
                                                    (expenseData) => <div>{`${expenseData.key}: ${
                                                        function renderValue(){
                                                            switch(expenseData.value){
                                                                case 'true':
                                                                    return 'კი'
                                                                case 'false':
                                                                    return 'არა'    
                                                                default:
                                                                    return  expenseData.value
                                                            }
                                                           
                                                        }()
                                                    }`}</div>
                                                )
                                                }
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>

}