import * as yup from "yup";

const  phoneRegEx = /^5\d{2}-\d{2}-\d{2}-\d{2}$/;
export const LoginSchema = yup
.object({
  phoneNumber: yup.string().matches(phoneRegEx, 'Phone number is not valid').required(),
  password: yup.string().required(),

})
.required();


export const SignUpSchema = yup
.object({
  firstName: yup.string().required('მიუთითეთ სახელი'),
  lastName: yup.string().required('მიუთითეთ გვარი'),
  personalNumber: yup.string().required('მიუთითეთ პირადი ნომერი').min(11,'გთხოვთ მიუთითოთ სწორი ფორმატით'),
  phoneNumber: yup.string().matches(phoneRegEx, 'მიუთითეთ სწორი ფორმატით').required('მიუთითეთ ტელეფონის ნომერი'),
  otp:yup.string().required('მიუთითეთ SMS კოდი').min(5,'გთხოვთ მიუთითოთ სწორი ფორმატით'),
  password: yup.string().required('მიუთითეთ პაროლი'),
  confirmPsw: yup.string().required().oneOf([yup.ref('password')], 'პაროლი არ ემთხვევა'),
  terms:yup.boolean().required().oneOf([true], 'დაეთანხმეთ საიტის წესებს')
})
.required();