import React from "react";
import { Container, CtaLayout, Subscribe } from "./styles";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslate } from "Hooks/useTranslate";
import { useNavigate } from "react-router-dom";

const Cta: React.FC = () => {
  const theme = useTheme();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  return (
    <Container>
      <CtaLayout>
        <h3>Join AutoCircle</h3>
        <p>
          {translate(
            "და მიიღე უამრავი ფასდაკლება და შეთავაზება ჩვენს პარტნიორ კომპანიებში"
          )}
        </p>

        <Subscribe>
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: theme.button.borderRadius }}
              onClick={()=>navigate('/sign-up')}
            >
              {translate("დარეგისტრირდი")}
            </Button>
        </Subscribe>
      </CtaLayout>
    </Container>
  );
};

export default Cta;
