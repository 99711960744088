import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as Models from "Models";
import { axiosInstance } from "Axios/axios";

export interface CarDetailsState {
    carDetails: Models.GetCarByIdResponse;
    expenseCategories: Models.ExpenseCategoriesResponse[];
    loadingCarDetails: boolean;
    errorLoadingCarDetails: unknown;
    loadingExpenseCategories: boolean;
    errorloadingExpenseCategories: unknown;
}

const initialState: CarDetailsState = {
    carDetails: {
        uniqueId: "",
        make: "",
        makeImageUrl: "",
        model: "",
        licensePlate: "",
        modelImageUrl: "",
        vin: "",
        dateOfProduction: "",
        expenses: [],
    },
    expenseCategories: [],
    loadingCarDetails: false,
    errorLoadingCarDetails: "",
    loadingExpenseCategories: false,
    errorloadingExpenseCategories: "",
};

export const getCarById = createAsyncThunk("user/getCarByIdAsync", async (carId: string) => {
    return axiosInstance
        .get(`Garage/GetCarByIdAsync/${carId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
            },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
});

export const getExpenseCategories = createAsyncThunk("expense/GetCategoriesAsync", async () => {
    return axiosInstance
        .get("Expense/GetCategoriesAsync", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("bearerToken")}`,
            },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
});

export const carDetailsSlice = createSlice({
    name: "car",
    initialState,
    reducers: {
        clearCarDetails: (state) => {
            state = initialState;
        },
        deleteExpense: (state, action: PayloadAction<{ expenseId: string }>) => {
            state.carDetails.expenses = state.carDetails.expenses.filter((expanse) => expanse.uniqueId !== action.payload.expenseId);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCarById.pending, (state) => {
            state.loadingCarDetails = true;
        });
        builder.addCase(getCarById.fulfilled, (state, action) => {
            state.loadingCarDetails = false;
            state.carDetails = action.payload;
        });
        builder.addCase(getCarById.rejected, (state, action) => {
            state.loadingCarDetails = false;
            state.errorLoadingCarDetails = action.error.toString();
        });
        builder.addCase(getExpenseCategories.pending, (state) => {
            state.loadingExpenseCategories = true;
        });
        builder.addCase(getExpenseCategories.fulfilled, (state, action) => {
            state.loadingExpenseCategories = false;
            state.expenseCategories = action.payload;
        });
        builder.addCase(getExpenseCategories.rejected, (state, action) => {
            state.loadingExpenseCategories = false;
            state.errorloadingExpenseCategories = action.error.toString();
        });
    },
});

export const { clearCarDetails, deleteExpense } = carDetailsSlice.actions;
export default carDetailsSlice.reducer;
