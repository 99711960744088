import styled from "@emotion/styled";

export const ContainerStyled = styled.div`
  max-width: 890px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
`;

export const AuthStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #592ea9;
    opacity: 0.87;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #6d6e76;
  }
`;

export const PostHeadingStyled = styled.div`
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
    color: #232536;
    margin-bottom: 32px;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #232536;
  }
  margin-bottom: 56px;
`;

export const PostImageStyled = styled.div`
  margin-bottom: 56px;
  img {
    width: 100%;
  }
`;

export const PostBlocksStyled = styled.div`
display:flex;
flex-direction:column;
gap: 48px;

margin-bottom:170px;
`

export const PostBlockStyled = styled.div`
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -2px;
    color: #232536;
    margin-bottom: 16px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #6d6e76;
  }
`;
