import { FC, useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { PricesDesktop, PricesMobile } from "Components/PricesComponents";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { Box, Button } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { axiosInstance } from "Axios/axios";
import { toast } from "react-toastify";
import * as Models from "Models";
import { useTranslate } from "Hooks/useTranslate";

export const Prices: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:600px)");
  const { translate } = useTranslate();
  const [textareaValue, setTextareaValue] = useState("");

  const adviceFeedback = (e: any) => {
    e.preventDefault();
    console.log(textareaValue);
    axiosInstance
      .post("Public/SaveAdviceFeedbackAsync", {
        adviceText: textareaValue,
      })
      .then((response) => {
        toast.success("თქვენი რჩევა წარმატებით დაფიქსირდა");
        console.log(response);
        setTextareaValue("");
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const [packagesWithOffers, setPackagesWithOffers] = useState<
    Models.PackageModel[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get("Public/Packages/GetPackagesAsync")
      .then((res) => setPackagesWithOffers(res.data))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Header />
      <Container>
        {matches ? (
          <Box
            sx={{
              boxSizing: "border-box",
              padding: "14px",
              boxShadow: "0px 5.41361px 21.6544px rgba(50, 111, 230, 0.15);",
              borderRadius: "32.4817px;",
            }}
          >
            <PricesDesktop data={packagesWithOffers} loading={loading} />
          </Box>
        ) : (
          <PricesMobile data={packagesWithOffers} loading={loading} />
        )}
      </Container>
      {/* <Container>
        <Advice>
          <h1>{translate("რჩევის დატოვება")}</h1>
          <AdviceForm onSubmit={adviceFeedback}>
            <Textarea
              onChange={(e) => setTextareaValue(e.target.value)}
              placeholder={translate("კომენტარი")}
              value={textareaValue}
            />
            <div>
              <Button
                variant="contained"
                type="submit"
                sx={{ borderRadius: theme.button.borderRadius }}
              >
                {translate("გაგზავნა")}
              </Button>
            </div>
          </AdviceForm>
        </Advice>
      </Container> */}
      <Footer />
    </>
  );
};

export default Prices;

const Advice = styled.div`
  width: 100%;
  max-width: 552px;
  margin: 0 auto;

  h1 {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #0070f4;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    width: 320px;
    max-width: unset;
  }
`;

const AdviceForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 196px;
    height: 56px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;

    button {
      margin-top: 20px;
    }
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 320px;
  height: 142px;
  background: #fbfdfe;
  border: 1px solid #d5d4dc;
  border-radius: 4px;
  resize: none;
  padding: 16px;
  box-sizing: border-box;
  outline: none;
`;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 77px 80px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 32px;
  }

  @media (max-width: 600px) {
    padding: 32px 0;
  }
`;
