import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux'
import { store } from 'Redux/store';

import './Assets/fonts/Helvetica/Helvetica.ttf';
import './Assets/fonts/Helvetica/Helvetica-Light.ttf';
import './Assets/fonts/Helvetica/Helvetica-Oblique.ttf';
import './Assets/fonts/Helvetica/Helvetica-Bold.ttf';
import './Assets/fonts/Helvetica/Helvetica-BoldOblique.ttf';
import "./Assets/css/style.css"

import 'react-toastify/dist/ReactToastify.css';
import AppThemeProvider from 'themes/AppThemeProvideder';

const rootElement = document!.getElementById("root")!;

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Provider store={store}>
        <App />
        <ToastContainer position='bottom-right' />
      </Provider>
    </AppThemeProvider>
  </React.StrictMode>
);