import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import React, { FC, useRef, useState } from "react";
import Slider from "react-slick";
import Plan, { plansData } from "./Plan";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IOSSwitch } from "./PlansRow";
import * as Models from "Models";
import ReplayIcon from "@mui/icons-material/Replay";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

type PricesMobileProps = {
  loading: boolean;
  data: Models.PackageModel[];
};

interface IOffer {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  location: string;
  isFree: boolean;
  isAssignedToPackage: boolean;
  isApplicableToAssignToPackage: boolean;
  discountPercent: number;
  packageIds: string[];
}

const PricesMobile: React.FC<PricesMobileProps> = ({ loading, data }) => {
  const [activePackageIndex, setActivePackageIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    centerMode: true,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setActivePackageIndex(newIndex);
      console.log(newIndex);
    },
  };

  const [annual, setAnnual] = useState<boolean>(false);

  function getDistinctById(arr: any): IOffer[] {
    const result: any = [];
    arr.map((el: any) => {
      const idies = result.map((el: any) => el.id);
      if (!idies.includes(el.id)) {
        result.push(el);
      }
    });
    return result;
  }

  const transformData = () => {
    let transformedData = data
      .map((el) => el.offers)
      .flat()
      .filter((item, i, ar) => ar.indexOf(item) === i);
    const res = getDistinctById(transformedData);
    return res;
  };
  console.log(transformData());
  return (
    <Box sx={{ maxWidth: "360px", margin: "auto" }}>
      <Typography
        sx={{
          boxSizing: "border-box",
          padding: "0 32px 40px",
          fontSize: "24px",
          lineHeight: "28px",
          color: "#0070f4",
          fontWeight: 700,
          width: "100%",
        }}
      >
        ფასები
      </Typography>
      <Box sx={{ marginBottom: "20px" }}>
        <Stack
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            // height:'fit-content',
            alignItems: "center",
            width: "280px",
          }}
        >
          <Typography sx={{ fontSize: 15, lineHeight: "23px", display: "block" }}>თვე</Typography>
          <IOSSwitch
            sx={{ m: 1 }}
            defaultChecked
            value={annual}
            onChange={() =>
              setAnnual((state) => {
                console.log(state);
                return !state;
              })
            }
          />
          <Typography sx={{ fontSize: 15, lineHeight: "23px", display: "block" }}>წელი</Typography>
        </Stack>
      </Box>

      <Box sx={{}}>
        <Slider {...settings}>
          {plansData.map((el, index) => (
            <Plan {...el} key={index} annual={annual} />
          ))}
        </Slider>
      </Box>
      <Stack sx={{ padding: "36px", marginTop: "32px", "@media(max-width:600px)": { padding: "0px" } }} spacing={3}>
        {transformData().map((offer) => (
          <Stack key={offer.id} direction="row" alignItems={"center"} spacing={2}>
            <img src={offer.imageUrl} style={{ width: 35, paddingRight: 10, }} alt="" />
            <Typography sx={{ flex: 1, fontSize: "12px" }}>{offer.title}</Typography>
            <Stack>{parseOffer(data[activePackageIndex].uniqueId, offer, data)}</Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
export default PricesMobile;

function parseOffer(packetId: string, offer: any, packets: Models.PackageModel[]) {
  const findOffer = packets.find((packet) => packet.uniqueId == packetId)?.offers.find((item) => item.id === offer.id) as Models.PackageOfferModel;

  if (offer?.packageIds?.includes(packetId)) {
    // if(findOffer.isUsageLimited){
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
        <DiscountPercent percent={findOffer.discountPercent} />
        <Qty count={findOffer.useCount} />
        <CheckIcon sx={{ color: "#0070f4" }} />
      </Box>
    );
  } else {
    return <CloseIcon sx={{ color: "#F4724A" }} />;
  }
}

//
interface IQty {
  count: number;
}

const Qty: FC<IQty> = ({ count }) => {
  return (
    <Box sx={{ color: "green", textAlign: "center" }}>
      <ReplayIcon />
      {count == 0 || count == 100 ? (
        <Typography sx={{ fontSize: 12 }}>ულიმიტოდ</Typography>
      ) : (
        <Typography sx={{ fontSize: 12 }}>{count} ჯერ</Typography>
      )}
    </Box>
  );
};

interface IDiscountPercent {
  percent: number;
}

const DiscountPercent: FC<IDiscountPercent> = ({ percent }) => {
  return (
    <Box sx={{ color: "#f9c23c", textAlign: "center" }}>
      <AutoAwesomeIcon />
      {percent == 0 || percent == 100 ? (
        <Typography sx={{ fontSize: 12 }}>უფასოდ</Typography>
      ) : (
        <Typography sx={{ fontSize: 12 }}>- {percent} %</Typography>
      )}
    </Box>
  );
};
