import styled from "@emotion/styled";
import { device } from "Components/common/device";

export const Container = styled("div")`
  background: #191919;
  border-radius: 4px;
`;

export const CtaLayout = styled("div")`
  max-width: 1440px;
  margin: auto;
  color: white;
  box-sizing: border-box;
  padding: 80px 32px;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 18px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #eef4ff;
    margin: 0 auto 32px auto;
    max-width: 600x;
    text-align: center;
  }
  @media ${device.mobile} {
    padding: 55px 32px;
    h3 {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

export const Subscribe = styled("section")`
  max-width: 530px;
  flex: 1;
  margin: auto;
  display:flex;
  justify-content:center;

  .footer_subscribe-form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    @media (max-width: 525px) {
      flex-wrap: wrap;
      .footer__subscribe-input {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
  }

  .footer__subscribe-input {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid gray;
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    height: 56px;

    svg {
      opacity: 0.5;
      color: black;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      box-sizing: border-box;

      font-family:inherit;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  button {
    border-radius: 10px;
    padding: 16px 24px;
    height: 56px;
    font-style: normal;
    box-sizing: border-box;
    outline: none;
    box-shadow: none;


    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-transform: capitalize;
    font-family: inherit;
    white-space: nowrap;
    min-width: auto;
    &:hover {
      color: white;
  
    }
  }
`;
