import React, { FC } from 'react';
import styled from "@emotion/styled";

const Background = (props: any) => {
  return (
    <BackgroundImage>
      {props.children}
    </BackgroundImage>
  )
}

export default Background;

export const BackgroundImage = styled.div`
  padding: 80px 20px;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #e5edf1;

  @media (max-width:800px){
    padding:0
  }
`;