import { useState } from 'react';
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import { toast } from "react-toastify";

interface IExpanse{
    id?:string;
    partnerId?: string;
    userId?: string;
    carId?: string;
    categoryId: number;
    amount: number;
    date?:string;
    company?:string;
    data: {
        type: number;
        key: string;
        value: string | boolean
      }[]

  }
export const useAddExpense = (): [boolean, (data: IExpanse) => Promise<void>] => {
    const [loading, setLoading] = useState(false);

    const axiosPrivateInstance = useAxiosPrivateInstance();

    const addExpenseAsync = async (data: IExpanse): Promise<void> => {
        setLoading(true);
        const updatedDetails = data.data.map(el=>({...el, value:String(el.value)}))
        let newData = Object.assign({}, {...data, amount:Number(data.amount), partnerId:'', data:updatedDetails })
        try {
            await axiosPrivateInstance.post('Expense/AddAsync', newData);
            toast.success('Successfully added');
        } catch (error) {
            toast.error(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }   

    return [loading, addExpenseAsync];
}


export const useEditExpense = (): [boolean, (data: IExpanse) => Promise<void>] => {
    const [loading, setLoading] = useState(false);

    const axiosPrivateInstance = useAxiosPrivateInstance();

    const editExpenseAsync = async (data: IExpanse): Promise<void> => {
        setLoading(true);
        const updatedDetails = data.data.map(el=>({...el, value:String(el.value)}))
        let newData = Object.assign({}, {...data, amount:Number(data.amount), partnerId:'', data:updatedDetails })
        try {
            await axiosPrivateInstance.post('Expense/EditByIdAsync', newData);
            toast.success('Successfully edited');
        } catch (error) {
            toast.error(JSON.stringify(error));
        } finally {
            setLoading(false);
        }
    }   

    return [loading, editExpenseAsync];
}