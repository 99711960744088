import styled from "@emotion/styled";

export const FeaturedPostLayout = styled.div`
    

`

export const HeadingStyled = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
text-transform: uppercase;
color: #0070f4;

margin-bottom:40px;

` 

export const ContentStyled = styled.div`
    box-sizing:border-box;
    padding:32px;
    border: 1px solid #6D6E7650;

    img{
        width:100%;
    }

   .featuredPost__author{
     font-size:14px;
     line-height:20px;
     margin-bottom:16px;
     margin-top:32px;
     .highlited{
         color:#0070f4
     }
 }
 h4{
     font-style: normal;
 font-weight: 700;
 font-size: 36px;
 line-height: 48px;
 letter-spacing: -2px;
 color: #232536;
 margin-bottom:16px;
 max-width:669px;
   }
  p{
 font-style: normal;
 font-weight: 400;
 font-size: 16px;
 line-height: 28px;
 color: #6D6E76;
 margin-bottom:32px;
 max-width:600px;
 }


  button{
     font-style: normal;
 font-weight: 700;
 font-size: 18px;
 line-height: 24px;
 color: #FFFFFF;
 
 width:fit-content;
 padding:16px 48px;
 box-shadow:none;
 border-radius:8px;

 }
`

