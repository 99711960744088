import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
    button:{
      borderRadius:string
    };

  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    button:{
      borderRadius:string
    },
  }
  
}



const theme = createTheme({

  palette:{
    primary:{
      main:'#0070f4'
    }
  },

  button:{
    borderRadius:'10px'
  },

  

});

interface IProps{
  children:React.ReactNode
}

const AppThemeProvider:React.FC<IProps> =({children}) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;