import React from "react";
import { Container, DownloadBtns, HeroLayout, HeroLeft, HeroRight, DownloadButtons, Downloadtext } from "./styles";
import HeroGirl from "../../../Assets/images/hero-illustration.svg";
import AppleIcon from "@mui/icons-material/Apple";
import { FaGooglePlay, FaCheck } from "react-icons/fa";
import SubscribtionModal from "./SubscribtionModal";
import DownloadIconBlue from "../../../Assets/images/Donwload_icon_blue.svg";
import Checkicon from "Assets/images/check.svg";
import DownloadIconDark from "../../../Assets/images/Download_icon_dark.svg";
import DistanceIcon from "../../../Assets/images/distance_icon.svg";
import MailIcon from "@mui/icons-material/Mail";
import HeroImage from "Assets/images/bmw.jpg";
import circle2 from "Assets/images/circle-2.png";
import Wheel from "../MainSlider/Wheel";
import { Box } from "@mui/material";
import HeadlineCurve from "Assets/images/headline-curve.svg";
import { useTranslate } from "Hooks/useTranslate";

const Hero: React.FC = () => {
  const { translate } = useTranslate();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <HeroLayout>
        <HeroLeft>
          <h1>
            <span className="gradienttext">The smart way</span> to reduce your expenses
            {/* <div style={{position: "absolute", left: 2, top: 30 }}><img src={HeadlineCurve} style={{width: 210,}} alt="Headline curve" /></div> */}
          </h1>

          <p>
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Helps You With Cleverly Maintaining Your Car")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Gives You Consultations")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("In Unforseen Cirumstances Comes And Helps You Out")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Reminds You Of Needed Services")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Counts Your Expenses")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Gives You Exclusive Deals")},<br />
            <FaCheck color="#0070f4" style={{ paddingRight: 18 }} /> {translate("Offers You Free Services")}
          </p>

          <DownloadButtons>
            <div className="bordered-div">
              <button className="appleButton" /* onClick={handleClickOpen} */>
                <AppleIcon sx={{ fontSize: "27px" }} /> {translate("Apple - App Store")}
              </button>
              <button className="androidButton" /* onClick={handleClickOpen} */>
                <FaGooglePlay /> Android - Play Store
              </button>
            </div>
            <img className="distance_icon" src={DistanceIcon} alt="DownloadImage" />
            <div className="bordered-div">
              <div className="form">
                <input type="email" className="form__email" placeholder="Enter your email address" />
                {/*  <img src={MailIcon} style={{height: 25, position: "absolute", top: 10, left: 15}} alt="mailicon"/> */}
                <MailIcon style={{ position: "absolute", top: 10, left: 15 }} />
                <button className="form__button">Subscribe</button>
              </div>
            </div>
          </DownloadButtons>
          {/* <DownloadBtns>
              <button onClick={handleClickOpen}>
                <AppleIcon sx={{ fontSize: "27px" }} /> {translate("Download for IOS")}
              </button>
              <button onClick={handleClickOpen}>
                <FaGooglePlay /> Download for Android
              </button>
          </DownloadBtns> */}
          <SubscribtionModal open={open} onHandleClose={handleClose} />
        </HeroLeft>
        <HeroRight>
          {/* <Wheel /> */}
          {/*  <img className="circleimage" src={circle2} alt="CircleImage" /> */}
          <img className="rightImage" src={HeroImage} alt="HeroImage" />
        </HeroRight>
      </HeroLayout>
    </Container>
  );
};

export default Hero;
