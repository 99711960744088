import { FC } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import OffersGrid from "Components/Generals/Offer/OffersGrid";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslate } from "Hooks/useTranslate";

export const Offers: FC = () => {
  const { translate } = useTranslate();
  return (
    <>
      <Header />
      <Container>
        <h4>{translate("მიმდინარე შეთავაზებები")}</h4>
        <Box>
          <OffersGrid />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Offers;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 77px 80px;
  box-sizing: border-box;

  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    color: #0070f4;
    margin-bottom: 60px;
  }

  @media (max-width: 1200px) {
    padding: 32px;
  }
`;
