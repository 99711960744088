import styled from "@emotion/styled";
import { device } from "Components/common/device";

export const Container = styled("div")`
  max-width: 1440px;
  width: 100%;
  margin: auto;

  .disabled{
    opacity:0.5
  }
`;

export const FooterLayout = styled("footer")`
  box-sizing: border-box;
  padding: 80px 70px 80px 70px;
  display: flex;
  gap: 48px 20px;
  @media ${device.laptop} {
    flex-wrap: wrap;
    padding: 64px 32px 48px 32px;
    /* section:nth-of-type(3) {
      order: -1;
      flex: 1 100%;
    } */
  }
`;

export const FooterSection = styled("section")`
  flex: 1;
  min-width: 200px;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
    color: #161c2d;
  }
  nav {
    display: flex;
    flex-direction: column;
    gap: 24px;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #0070f4;
      text-decoration: none;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
`;

export const Subscribe = styled("section")`
  flex: 1;
  flex-basis: 100px;
  .footer__logo {
    margin-bottom: 24px;
  }
  p {
    max-width: 419px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
    color: #6f6f7a;
  }
  .footer_subscribe-form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 525px) {
      flex-wrap: wrap;
      .footer__subscribe-input {
        width: 100%;
      }
      button {
        width: 100%;
        box-shadow: none;
      }
    }
  }

  .footer__subscribe-input {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid gray;
    box-sizing: border-box;
    svg {
      opacity: 0.5;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
    }
  }

  button {
    border-radius: 10px;
    padding: 12px 16px;
    box-sizing: border-box;
    box-shadow: none;
  }
`;

export const Terms = styled("div")`
  width: 100%;
  padding: 0px 70px 40px 70px;
  box-sizing: border-box;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    li {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
    }
    a{
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
      text-decoration:none;
    }
  }

  @media ${device.laptop} {
    padding: 0px 32px 32px 32px;
    ul {
      justify-content: flex-start;
    }
  }

  @media ${device.mobile} {
    padding: 0px 32px 32px 32px;
    ul {
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

