import React, { FC, ReactNode } from 'react'
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import Collapse from '@mui/material/Collapse';

import logo from "Assets/images/Logo-New.svg";
import navPackageUpgrade from "Assets/images/nav_package_upgrade.png";

import EastIcon from '@mui/icons-material/East';

// material icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LogoutIcon from '@mui/icons-material/Logout';

import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { logOut } from 'Redux/slices/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { LogoCut } from 'Assets/images';

type SidebarPropsTypes = {
  hidePackageUpgrade?: boolean;
  collapseAdditionalMenu?: boolean;
}

export const Sidebar: FC<SidebarPropsTypes> = ({ hidePackageUpgrade, collapseAdditionalMenu }) => {
  const [open, setOpen] = React.useState(!collapseAdditionalMenu);

  const handleClick = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logOut())
    navigate('/')
  }

  return (
    <ContentWrapper>
      <SidebarBody>
        <div>
          <Nav>
            <Link to="/dashboard" className='logo'><img src={logo} style={{width: 50}} alt="autocircle" /></Link>
            <Link to="/dashboard" className='logo-mobile'><img src={LogoCut} alt="autocircle" /></Link>
            <NavItem to="/dashboard"><DashboardIcon /><span>მთავარი</span></NavItem>
            <NavItem to="/garage"><DirectionsCarIcon /><span>გარაჟი</span></NavItem>
            {/* <NavItem to="/user-offers"><LocalOfferIcon /><span>შეთავაზებები</span></NavItem> */}
            <NavItem to="/pricing-plan"><MonetizationOnIcon /><span>ფასები</span></NavItem>
            <NavItem to="/loyality"><VolunteerActivismIcon /><span>ლოიალური კლუბი</span></NavItem>
            <NavItem to="/calling-services"><BuildCircleIcon /><span>გამოძახების სერვისები</span></NavItem>
            <NavItem to="/guarantees"><VerifiedUserIcon /><span>გარანტიები</span></NavItem>
          </Nav>

          <Nav className='additionalNav'>
            <button className="collapseMenu" onClick={handleClick}>
              <div className='nav_title'>დამატებითი მენიუ</div>
              {open ? <ExpandLess className="collapse-arrow" /> : <ExpandMore className="collapse-arrow" />}
            </button>
            <Collapse style={{ width: "100%" }} in={open} timeout="auto" unmountOnExit>
              <NavItem to="/payment-methods"><CreditCardIcon /><span>გადახდის მეთოდები</span></NavItem>
              <NavItem to="/settings"><SettingsIcon /><span>პარამეტრები</span></NavItem>
              <NavItem to="/help-center"><HelpIcon /><span>დახმარების ცენტრი</span></NavItem>
              <SidebarBtn onClick={handleLogout}><LogoutIcon /><span>გამოსვლა</span></SidebarBtn>
            </Collapse>
          </Nav>
        </div>
      </SidebarBody>
      {!hidePackageUpgrade && <div>
        <UpgradePackage>
          <div className="hint">
            პაკეტის განახლება შეგიძლია ნებისმიერ დროს
          </div>
          <Link to="/garage" className='garage_link'>
            <div>შეიძინე პაკეტი</div>
            <EastIcon />
          </Link>
        </UpgradePackage>
      </div>}
    </ContentWrapper>
  )
}

export default Sidebar;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 20px;
  box-sizing: border-box;

  @media(max-width:1200px){
    width:auto;
    padding-right: 14px;
    
  }
`

export const Logo = styled.div`
  /* margin-bottom: 60px;
  padding: 0 20px; */
  box-sizing: border-box;
  display:flex;
  justify-content: center;
  /* width:100%; */
 
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.additionalNav{
    margin-top: 50px;

    .collapseMenu {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .nav_title {
    padding-left: 18px;
    color: rgb(43 57 91 / 50%);
    font-size: 14px;
    font-family: "FreeSansBold";
    padding-bottom: 10px;
  }
  @media(max-width:1200px){
    align-items: center;
   .nav_title{
    display:none;
   }
   .collapse-arrow{
    display:none
   }
}
`;
export const NavItem = styled(NavLink)`
  width: 100%;
  min-width: 204px;
  box-sizing: border-box;
  padding: 14px 20px;
  border-radius: 12px;
  font-family: "FreeSansBold";
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 19px;
  color: #191919;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &.active {
    background-color: #0070f4;
    color: white;

    &:hover {
      background-color: #0070f4;
      color: white;
    }
  }
  &:hover {
    background-color: rgb(43 57 91 / 5%);
  }
  @media(max-width:1200px){
    width: fit-content;
  min-width: 0px;
  padding:11px 11px;
  span{
    display:none;
  }
  }
`;

export const SidebarBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 500px;
  gap: 30px;
  width: 100%;

  .logo {
    padding: 44px 10px;
  }
  .logo-mobile{
    display:none;
    margin-bottom:20px;
    opacity:0.7;
  }
  @media(max-width:1200px){
    width:fit-content;
    .logo{
      display:none;
    }
    .logo-mobile{
    display:block;
  }
}
`;

export const SidebarBtn = styled.button`
  width: 100%;
  min-width: 204px;
  box-sizing: border-box;
  padding: 14px 20px;
  border-radius: 12px;
  font-family: "FreeSansBold";
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 19px;
  color: #191919;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &.active {
    background-color: #0070f4;
    color: white;

    &:hover {
      background-color: #0070f4;
      color: white;
    }
  }
  &:hover {
    background-color: rgb(43 57 91 / 5%);
  }
  @media(max-width:1200px){
    min-width:0;
    padding: 11px;
    span{
      display:none;
      
    }
  }
`;

export const UpgradePackage = styled.div`
  width: 240px;
  height: 304px;
  background-image: url(${navPackageUpgrade});
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 178px 3px 0px 17px;
  text-align: center;
  position: relative;
  margin-top: 40px;

  @media(max-width:1200px){
    display:none;
  }

  .hint {
    color: #787c84;
    font-family: "FreeSansBold";
    font-size: 14px;
    padding-bottom: 10px;
  }
  a {
    color: #0070f4;
    text-decoration: none;
    font-family: "FreeSansBold";
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #191919;
    }

    div {
      padding-right: 8px;
    }
  }
`;

