import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ContainerStyled = styled.div`
    box-sizing:border-box;
    padding:80px 40px 250px 40px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    
    h4{
        font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 48px;
text-align: center;
letter-spacing: -2px;
color: #232536;
max-width:500px;
margin-bottom:16px;
    }

    p{
        font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
text-align: center;
color: #6D6E76;
max-width:500px;
margin-bottom:32px;
    }
`

export const ButtonStyled = styled(Button)`
box-sizing:border-box;
padding:16px 48px;
box-shadow:none;

font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #FFFFFF;

`