import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { CostumPasswordField } from "../Shared/CostumInputs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { logOut } from "Redux/slices/authSlice";
import styled from "@emotion/styled";
import useAxiosPrivateInstance from "Hooks/useAxiosPrivateInstance";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import * as yup from "yup";

type UserDeleteProps = {};

interface DUser {
  currentPassword: string;
}
interface IChangePsw {
  currentPassword: string;
}

const UserDelete: React.FC<UserDeleteProps> = () => {
  const [isConfirmed, setIsConfirmed] = useState<Boolean>(false);
  const axiosPrivateInstance = useAxiosPrivateInstance();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const dispatch = useDispatch();

  const onSubmit = async (data: DUser) => {
    setIsConfirmed(true);
    console.log(data);

    axiosPrivateInstance
      .delete(`Account/DeleteAsync?password=${data.currentPassword}`)
      .then((res) => {
        toast.success("ანგარიში წარმატებით წაიშალა");
        dispatch(logOut());
      })

      .catch((error) => {
        toast.error("პაროლი არასწორია");
        console.log("დაფიქსირდა შეცდომა", error);
      });
  };

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm<IChangePsw>({
    defaultValues: {
      currentPassword: "",
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text>
          <h3>თუ ნამდვილად გსურთ ანგარიშის წაშლა შეიყვანეთ თქვენი პაროლი</h3>
        </Text>
        <Stack
          sx={{
            maxWidth: "300px",
            margin: "40px auto",
            flexDirection: "column",
          }}
          spacing={4}
        >
          <CostumPasswordField
            name="currentPassword"
            control={control}
            label={"პაროლი"}
            InputLabelProps={{
              shrink: watch("currentPassword") ? true : false,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={watch("currentPassword").length === 0}
            color="error"
          >
            ანგარიში
          </Button>
        </Stack>
      </form>
    </div>
  );
};
export default UserDelete;

interface ICostumTextField {
  name: string;
  label: string;
  control: any;
}

const Text = styled.div`
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 60px;
    max-width: 862px;
    margin: auto;
    text-align: center;
    color: #d32f2f;
    text-transform: capitalize;
    letter-spacing: 0.02em;
  }
`;
