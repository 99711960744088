import { Typography } from '@mui/material'
import { Services } from 'Components/ProfileComponents/CalllingServicesComponents'
import React from 'react'
import { Container } from './styles'


const CallingServices: React.FC = () => {
  return (
    <>
      <h3>გამოძახების სერვისები</h3>
      <br /><br />
      <Services />
    </>
  )
}

export default CallingServices